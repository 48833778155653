import React from "react";
import { useSelector } from "react-redux";
import GameItem from "../mui/game_tem/GameItem";

export default function BonusGames() {
  const games = useSelector((state) => state.games.bonusGames);

  return (
    <div className="games__games">
      {games.map((el) => (
        <GameItem
          key={el.game_id}
          currentGame={el}
          url="/bonus_game/"
          name={el.name}
          image={el.image}
        />
      ))}
    </div>
  );
}
