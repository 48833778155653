import React from "react";
import "./banner-content.scss";
import Back from "../mui/back/Back";
import BannerItem from "../landing/banners/banner_item/BannerItem";

export default function BannerContent({ banner }) {
  return (
    <section className="banner-page__content">
      <Back to={"/"} />
      <BannerItem {...banner} />

      <h2>{banner?.name}</h2>
      {banner?.bannerText && (
        <div
          className="banner__full-text"
          dangerouslySetInnerHTML={{ __html: banner?.bannerText }}
        />
      )}
    </section>
  );
}
