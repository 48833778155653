import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import MobileBalance from "./MobileBalance";

import "./balance.scss";
import ShowContainer from "../../../mui/ShowContainer";
import { errorToast } from "../../../mui/Toaster";
import useBalanceUpdate from "../../../../hooks/useBalanceUpdate";
import { setBonusWagering } from "../../../../store/siteSlice";

export const ENUM_VIEW = {
  mobile: "mobile",
  desctop: "desctop"
};

export default function Balance({ propsView, showWager }) {
  const { mobileHeader } = useDeviceSize();
  const { t } = useTranslation();
  const { TITLE_TO_KEY, IMG_TO_KEY } = useBalanceUpdate();
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.user.balance);
  const currency = useSelector((state) => state.user.currency);
  const currencyInfo = useSelector((state) => state.user.currencyInfo);

  const keyBalance = useMemo(() => ["main", "payout", "bonus"], [balance]);
  const decimal = useMemo(() => currencyInfo?.decimal, [currencyInfo]);
  const view = useMemo(
    () => propsView || (mobileHeader ? ENUM_VIEW.mobile : ENUM_VIEW.desctop),
    [propsView, mobileHeader]
  );

  const wagerByKey = {
    main: <span className="wager"> {t("Wager: ") + balance?.depositWager || 0}</span>,
    payout: <span className="wager"></span>,
    bonus: <span className="wager">{t("Wager: ") + balance?.bonusWager || 0}</span>
  };

  const onClick = (balanceName) => {
    const bonusWager = balance?.bonusWager;
    const depositWager = balance?.depositWager;
    if (balanceName === "bonus" && bonusWager) {
      if (balance.bonus) return dispatch(setBonusWagering(true));

      return errorToast(`${t("To wager the bonus, bet the amount")} ${bonusWager} ${currency}`);
    }

    if (depositWager) {
      return errorToast(
        `${t("To win back your deposit, bet the amount")} ${depositWager} ${currency}`
      );
    }
  };

  return (
    <ShowContainer condition={Object.keys(balance || {}).length}>
      <div className="balance">
        {view === ENUM_VIEW.mobile ? (
          <MobileBalance decimal={decimal} onBalanceClick={onClick} keyBalance={keyBalance} />
        ) : (
          keyBalance?.map((el) => (
            <button className="balance__item" key={el} onClick={() => onClick(el)}>
              <label>{TITLE_TO_KEY[el]}</label>
              <ShowContainer condition={showWager}>
                <span>{wagerByKey[el]}</span>
              </ShowContainer>
              <div className="balance__item__main">
                <span className="balance__item__count">{balance?.[el]?.toFixed(decimal)}</span>
                <span className="balance__item__img">{IMG_TO_KEY?.[el]}</span>
              </div>
            </button>
          ))
        )}
      </div>
    </ShowContainer>
  );
}
