import { combineReducers, configureStore } from "@reduxjs/toolkit";
import commonSlice from "./commonSlice";
// import { useDispatch } from "react-redux";
import gamesSlice from "./gamesSlice";
import siteSlice from "./siteSlice";
import userSlice from "./userSlice";
import deviceSize from "./deviceSizeSlice";

const rootReduces = combineReducers({
  common: commonSlice,
  user: userSlice,
  games: gamesSlice,
  site: siteSlice,
  deviceSize: deviceSize
});

export const store = configureStore({ reducer: rootReduces });
// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
