import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/landing/header/Header";

import BannerContent from "../components/banner/BannerContent";
import { useSelector } from "react-redux";

export default function Banner() {
  const { id } = useParams();
  const banners = useSelector((state) => state.common.banners);

  const banner = banners.find((el) => el.relativeUrl == id);

  return (
    <div className="banner-page">
      <Header />
      <BannerContent banner={banner} />
    </div>
  );
}
