import React from "react";
import DefaultModal from "../DefaultModal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/custom_button/CusButton";
import "./bonus-wager.scss";
import { API_USER } from "../../../api/user";
import { DEBUG } from "../../../store/enums";
import { setBalance, setCurrencies, setCurrency } from "../../../store/userSlice";
import { successToast } from "../../mui/Toaster";

export default function BonusWager({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.user.balance);
  const currency = useSelector((state) => state.user.currency);

  const onClearBonus = () => {
    API_USER.balance
      .clearBonus()
      .then((e) => {
        if (DEBUG) console.log("res clear bonus", e);
        const data = e.data;

        dispatch(setBalance(data.balances));
        dispatch(setCurrencies(data.currencies));
        dispatch(setCurrency(data.currency));

        successToast("success");

        closeModal();
      })
      .catch((e) => {
        if (DEBUG) console.log("catch  clear bonus ", e);
      });
  };

  return (
    <DefaultModal isOpen={isOpen} closeModal={closeModal} classModal="bonus-wager-modal">
      <h2>
        {t("Bonus balance")}: {balance?.bonus} {currency}
      </h2>
      <p>
        {t("To wager the bonus, bet the amount")}:{" "}
        <span className="highlight">
          {balance?.bonusWager} {currency}
        </span>{" "}
      </p>
      <CusButton text={"Reset bonuses"} onClick={onClearBonus} />
    </DefaultModal>
  );
}
