import React from "react";
import "./default-modal.scss";
import Modal from "react-modal";
import CloseModal from "../mui/close_modal/CloseModal";
import ShowContainer from "../mui/ShowContainer";

export default function DefaultModal({ isOpen, closeModal, classModal = "", children, hideClose }) {
  return (
    <Modal
      ariaHideApp={false}
      className={`default-modal ${classModal}`}
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldFocusAfterRender={false}>
      <ShowContainer condition={!hideClose}>
        <CloseModal close={closeModal} />
      </ShowContainer>
      {children}
    </Modal>
  );
}
