import axios from "axios";
import { PREFIX } from "./main";
import { checkUrlBotTg, ENUM_MADE_ON } from "../store/enums";
import { getLang } from "../utils/utils";

const info = async (data) => {
  const madeOn = checkUrlBotTg() ? ENUM_MADE_ON.tgBot : ENUM_MADE_ON.site;
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/casino_info`,
    data: { madeOn, lang, ...data }
  });
  return response;
};

const getGameUrl = async (data) => {
  const madeOn = checkUrlBotTg() ? ENUM_MADE_ON.tgBot : ENUM_MADE_ON.site;
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/start_game`,
    data: { ...data, madeOn, lang }
  });
  return response;
};

const getBonusGameUrl = async (data) => {
  const madeOn = checkUrlBotTg() ? ENUM_MADE_ON.tgBot : ENUM_MADE_ON.site;
  const lang = getLang();

  let response = await axios({
    method: "POST",
    url: `${PREFIX}/api/gamers/start_bonus`,
    data: { ...data, madeOn, lang }
  });
  return response;
};

export const API_CASINO = {
  info,
  getGameUrl,
  getBonusGameUrl
};
