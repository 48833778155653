import { useEventListener } from "usehooks-ts";
import { API_USER } from "../../api/user";
import { DEBUG } from "../../store/enums";
import { addFavoriteGames, removeFavoriteGames } from "../../store/gamesSlice";
import { useDispatch, useSelector } from "react-redux";

export default function useFixCaruselDeskListener() {
  const dispatch = useDispatch();
  const gamesById = useSelector((state) => state.games.gamesById);

  useEventListener("favorites-click", (e) => {
    const elem = e.detail.elem;

    const id = elem.id;
    const currentGame = gamesById[id];
    if (!currentGame) return;

    API_USER.toggleFavorites(id)
      .then((e) => {
        if (DEBUG) console.log("favorites res", e);
        const isFavorites = e.data.favorites;
        isFavorites
          ? dispatch(addFavoriteGames(currentGame))
          : dispatch(removeFavoriteGames(currentGame));
      })
      .catch((e) => {
        if (DEBUG) console.log("catch", e);
      });
  });
}
