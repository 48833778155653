import React from "react";
import { Link } from "react-router-dom";
import "./back.scss";
import { useTranslation } from "react-i18next";
import iconBack from "./back.png";
import ShowContainer from "../ShowContainer";
import TitleUserColor from "../TitleUserColor";

export default function Back({ title, to }) {
  const { t } = useTranslation();

  return (
    <span className="back-row">
      <Link to={to} className="arrow-back">
        <img src={iconBack} />
        {!title && <span className="arrow-back__text">{t("Back")}</span>}
      </Link>

      <ShowContainer condition={title}>
        <TitleUserColor level={2} title={t(title)} />
      </ShowContainer>
    </span>
  );
}
