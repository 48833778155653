import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from "./locales/en/en.json";
import RU from "./locales/ru/ru.json";
import UZ from "./locales/uz/uz.json";
import { getLang } from "./utils/utils";

// the translations
const resources = {
  en: { translation: EN },
  ru: { translation: RU },
  uz: { translation: UZ },
  Castellano: { translation: RU },
  Italiano: { translation: RU },
  Deutsch: { translation: RU }
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: getLang(),
  // debug: false,
  // keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
