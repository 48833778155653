export const testCasinoInfo = {
  casinoId: 5,
  casinoName: "Vulpin Casino",
  logoUrl: "https://fiat1.securebet.xyz/bot/logo/5-logo_vulpin.png",
  logoColor: "rgb(255, 193, 32)",
  buttonColor: "rgb(255, 193, 32)",
  headerColor: "#FFFFFF",
  channelUrl: "https://t.me/VulpinCasinoChannel",
  reviewUrl: "",
  groupId: 21,
  telegramWidget: true,
  langList: "ru,en,uz",
  langDefault: "ru",
  supportChat: "VulpinBotSupport2",
  botName: "VulpinCasinoBot",
  htmlHead: "",
  htmlEndBody: "",
  excludeFromDemo: ["PGSoft", "Qilin"],
  gameCategories: [
    {
      id: 26,
      name: "ХИТЫ",
      providerId: 0,
      providerName: "",
      games: [
        {
          game_id: 550,
          name: "*NSYNC Pop",
          uuid: "42ea161e623b51185127b832d6fd596beeb4cce4",
          image: "https://r.gamesstatic.com/games/42ea161e623b51185127b832d6fd596beeb4cce4.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 613,
          name: "1 2 3 BOOM!",
          uuid: "e0cfc16ad6584f8c847301f03bf52cd6",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/e0cfc16ad6584f8c847301f03bf52cd6.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 133,
          name: "10 Burning Heart",
          uuid: "c171398de47a084ac7a84615d5f6280b388189aa",
          image: "https://r.gamesstatic.com/games/c171398de47a084ac7a84615d5f6280b388189aa.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 131,
          name: "100 Burning Hot",
          uuid: "6456aeb10010daba0f5fc19230427654006dbe73",
          image: "https://r.gamesstatic.com/games/6456aeb10010daba0f5fc19230427654006dbe73.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 298,
          name: "100 Dice",
          uuid: "8c2ff5c68566bc1b4a9f6a6289fe9f7977afa3cb",
          image: "https://r.gamesstatic.com/games/8c2ff5c68566bc1b4a9f6a6289fe9f7977afa3cb.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 299,
          name: "100 Super Dice",
          uuid: "f57e0be51fbdd89680a1cf69a9138e67aec5c9ad",
          image: "https://r.gamesstatic.com/games/f57e0be51fbdd89680a1cf69a9138e67aec5c9ad.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 132,
          name: "100 Super Hot",
          uuid: "cc86e61fea81e2749221491fa0b833b1db5c6f56",
          image: "https://r.gamesstatic.com/games/cc86e61fea81e2749221491fa0b833b1db5c6f56.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 509,
          name: "15 Crystal Roses: A Tale of Love",
          uuid: "30114db330b68ea9ce38986a931d2795d26f67c2",
          image: "https://r.gamesstatic.com/games/30114db330b68ea9ce38986a931d2795d26f67c2.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 816,
          name: "15 Dragon Pearls",
          uuid: "6a6c1eb6155443449208f7fcfb76bc7c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/6a6c1eb6155443449208f7fcfb76bc7c.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 435,
          name: "1942: Sky Warrior",
          uuid: "ba3ed943720c4e091ac3b499440599183c3217b2",
          image: "https://r.gamesstatic.com/games/ba3ed943720c4e091ac3b499440599183c3217b2.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 300,
          name: "20 Burning Dice",
          uuid: "0be61dbd9e8845b6febb5761f708e7861a3742ab",
          image: "https://r.gamesstatic.com/games/0be61dbd9e8845b6febb5761f708e7861a3742ab.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 134,
          name: "20 Burning Hot",
          uuid: "1743393fbf975b76e80fc747c8270609dab9daa2",
          image: "https://r.gamesstatic.com/games/1743393fbf975b76e80fc747c8270609dab9daa2.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 135,
          name: "20 Dazzling Hot",
          uuid: "3b8e66c89a41206e46aa895c2580dff4a07c6edd",
          image: "https://r.gamesstatic.com/games/3b8e66c89a41206e46aa895c2580dff4a07c6edd.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 136,
          name: "20 Joker Reels",
          uuid: "203475551a9e31e88602298b9b854f09beb628a9",
          image: "https://r.gamesstatic.com/games/203475551a9e31e88602298b9b854f09beb628a9.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 90,
          name: "24 Hour Grand Prix",
          uuid: "5c6b0f3bfbb044e91e5d02692580c47fbe4b8a57",
          image: "https://r.gamesstatic.com/games/5c6b0f3bfbb044e91e5d02692580c47fbe4b8a57.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 817,
          name: "3 Coins",
          uuid: "a7be71ce60f34c78b72bb728c6cf2e0c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a7be71ce60f34c78b72bb728c6cf2e0c.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 818,
          name: "3 Coins: Egypt",
          uuid: "ba550520d30c47ccaef2ebf29b732024",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/ba550520d30c47ccaef2ebf29b732024.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 301,
          name: "30 Spicy Dice",
          uuid: "b6458ec2f70d8c0fbb12a76adb42c062637b7113",
          image: "https://r.gamesstatic.com/games/b6458ec2f70d8c0fbb12a76adb42c062637b7113.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 137,
          name: "30 Spicy Fruits",
          uuid: "3e246f145aef23b3652a88a61f240dd99fa1e2db",
          image: "https://r.gamesstatic.com/games/3e246f145aef23b3652a88a61f240dd99fa1e2db.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 302,
          name: "40 Burning Dice",
          uuid: "772d2d113d9712cfd5fd7b4469f880e7cdb37d47",
          image: "https://r.gamesstatic.com/games/772d2d113d9712cfd5fd7b4469f880e7cdb37d47.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 138,
          name: "40 Burning Hot",
          uuid: "cb1df72cfd1c4df303c3f8db3b05e289c4ffff99",
          image: "https://r.gamesstatic.com/games/cb1df72cfd1c4df303c3f8db3b05e289c4ffff99.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 319,
          name: "40 Lucky King",
          uuid: "140653f8a025ac83acddeeb36cef7e92cbae6d14",
          image: "https://r.gamesstatic.com/games/140653f8a025ac83acddeeb36cef7e92cbae6d14.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 303,
          name: "40 Mega Clover",
          uuid: "082d270d7f5e190a8ded6c135d4a4a71a59e7478",
          image: "https://r.gamesstatic.com/games/082d270d7f5e190a8ded6c135d4a4a71a59e7478.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 670,
          name: "4Horsemen",
          uuid: "77a989b9ac554226a27a7b736c3ccfd8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/77a989b9ac554226a27a7b736c3ccfd8.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 503,
          name: "4Squad",
          uuid: "67f99dbbbb5b737080d1e91181f80fc4b18396fa",
          image: "https://r.gamesstatic.com/games/67f99dbbbb5b737080d1e91181f80fc4b18396fa.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 139,
          name: "5 Burning Heart",
          uuid: "50fdb1326ffa6d2b4af218fd46c3dac7695045da",
          image: "https://r.gamesstatic.com/games/50fdb1326ffa6d2b4af218fd46c3dac7695045da.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 304,
          name: "5 Great Star",
          uuid: "b9aa36bcdb34938912dba4c200bc00e3c9ba2e8f",
          image: "https://r.gamesstatic.com/games/b9aa36bcdb34938912dba4c200bc00e3c9ba2e8f.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 320,
          name: "5 Juggle Fruits",
          uuid: "b95b1ffb95ffaa3d480f07e3184e0592ed533d45",
          image: "https://r.gamesstatic.com/games/b95b1ffb95ffaa3d480f07e3184e0592ed533d45.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 229,
          name: "50 Dragons",
          uuid: "a23cc95d3e81e97844d6a1283b9237eb048d0901",
          image: "https://r.gamesstatic.com/games/a23cc95d3e81e97844d6a1283b9237eb048d0901.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 819,
          name: "777 Gems Respin",
          uuid: "5d0172dc92bc41ed8e3bda001f014ba8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/5d0172dc92bc41ed8e3bda001f014ba8.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 144,
          name: "777 Strike",
          uuid: "3edfd52d8ae619b31bc1f013e3dcf5b06a119ca4",
          image: "https://r.gamesstatic.com/games/3edfd52d8ae619b31bc1f013e3dcf5b06a119ca4.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 882,
          name: "8 Golden Dragon Challenge",
          uuid: "ef8c71833bcd46f4b70c61f14aabbd6e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ef8c71833bcd46f4b70c61f14aabbd6e.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 288,
          name: "Action Joker",
          uuid: "cb8a9babc88382729e4f12e6f057d772258047df",
          image: "https://r.gamesstatic.com/games/cb8a9babc88382729e4f12e6f057d772258047df.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 577,
          name: "Admiral Nelson",
          uuid: "71f8a9c71b524e34bab494ab71d89b34",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/71f8a9c71b524e34bab494ab71d89b34.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 266,
          name: "African Simba",
          uuid: "88b0692ecea987a05b7096754e42f0a14b395c50",
          image: "https://r.gamesstatic.com/games/88b0692ecea987a05b7096754e42f0a14b395c50.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 708,
          name: "Alcatraz",
          uuid: "96023b44a3d46dca5aaeadea1545a4e65af4dbbf",
          image: "https://r.gamesstatic.com/games/96023b44a3d46dca5aaeadea1545a4e65af4dbbf.png",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 578,
          name: "Allways Fruits",
          uuid: "66a1cdbc66ec4847835e4de699cedd60",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/66a1cdbc66ec4847835e4de699cedd60.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 280,
          name: 'Almighty Reels: "Garden of Persephone"',
          uuid: "bc834b28631dbb491be3b2492dc0696a49a4521b",
          image: "https://r.gamesstatic.com/games/bc834b28631dbb491be3b2492dc0696a49a4521b.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 282,
          name: 'Almighty Reels: "Realm of Poseidon"',
          uuid: "c23520b4414dcb29cf816d838174f7be29722d81",
          image: "https://r.gamesstatic.com/games/c23520b4414dcb29cf816d838174f7be29722d81.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 366,
          name: "Aloha",
          uuid: "fb38a897c531e75e3e7b88e672f9e0e62f9347e6",
          image: "https://r.gamesstatic.com/games/fb38a897c531e75e3e7b88e672f9e0e62f9347e6.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 14,
          name: "Always Hot Deluxe",
          uuid: "dd416dd20f5ac16f554cda1b8c572bd171c86a7c",
          image: "https://r.gamesstatic.com/games/dd416dd20f5ac16f554cda1b8c572bd171c86a7c.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 305,
          name: "Amazons Battle",
          uuid: "651f090c618223a301b5ec05cfecef7f3e3c54c5",
          image: "https://r.gamesstatic.com/games/651f090c618223a301b5ec05cfecef7f3e3c54c5.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 267,
          name: "Amazons Diamonds",
          uuid: "4b783adeedf9d65fdd02ad7dfb60c91c14c51b3f",
          image: "https://r.gamesstatic.com/games/4b783adeedf9d65fdd02ad7dfb60c91c14c51b3f.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 306,
          name: "Amazons Story",
          uuid: "dc2890e94595b6e3dc6e472a8277ac184cacc172",
          image: "https://r.gamesstatic.com/games/dc2890e94595b6e3dc6e472a8277ac184cacc172.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 268,
          name: "Ancient Goddess",
          uuid: "00f70a97ae2419070da2ff5999a211d9a098d4e0",
          image: "https://r.gamesstatic.com/games/00f70a97ae2419070da2ff5999a211d9a098d4e0.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 502,
          name: "Ancient Script",
          uuid: "e6554e086ab6841c0381441a41e0a0c4a393ac95",
          image: "https://r.gamesstatic.com/games/e6554e086ab6841c0381441a41e0a0c4a393ac95.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 551,
          name: "Animal Madness",
          uuid: "59eee36c09d4e7dfa4f16c2682f6e92db3f84c88",
          image: "https://r.gamesstatic.com/games/59eee36c09d4e7dfa4f16c2682f6e92db3f84c88.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 363,
          name: "Apollo God Of The Sun",
          uuid: "8fd43ce5d0f11a3f67a2a6cc9c04f04c0d3e8b4f",
          image: "https://r.gamesstatic.com/games/8fd43ce5d0f11a3f67a2a6cc9c04f04c0d3e8b4f.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 367,
          name: "Arcane Reel Chaos Slot",
          uuid: "4afd3686691f7c7b02a3dfe81c353234d968e999",
          image: "https://r.gamesstatic.com/games/4afd3686691f7c7b02a3dfe81c353234d968e999.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 368,
          name: "Archangels: Salvation Slot",
          uuid: "34fa5181cf99cc24129f9c8574f1a35b8cc2bd57",
          image: "https://r.gamesstatic.com/games/34fa5181cf99cc24129f9c8574f1a35b8cc2bd57.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 369,
          name: "Asgardian Stones",
          uuid: "bd49e9fc20cf34736e0748a04d2730ca9fee2355",
          image: "https://r.gamesstatic.com/games/bd49e9fc20cf34736e0748a04d2730ca9fee2355.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 867,
          name: "Athena Ascending",
          uuid: "5270556574f946568675076e58d4128b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/5270556574f946568675076e58d4128b.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 287,
          name: "Autumn Queen",
          uuid: "7e3cb7add18a4550d95c716d1ca10d68705d0a59",
          image: "https://r.gamesstatic.com/games/7e3cb7add18a4550d95c716d1ca10d68705d0a59.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 706,
          name: "Avengers",
          uuid: "181b3eb397deb851f9fb3c51c63fb18d5a524961",
          image: "https://r.gamesstatic.com/games/181b3eb397deb851f9fb3c51c63fb18d5a524961.jpg",
          type: "slots",
          provider: "BrandGames",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 54,
          name: "Aviator",
          uuid: "88e106622db162f752e9fbf6fc492631f0f7c6ba",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/88e106622db162f752e9fbf6fc492631f0f7c6ba.png",
          type: "crash",
          provider: "Spribe",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 820,
          name: "Aztec Fire",
          uuid: "f7b7ff1ffdc34866aa1dfe8ce1e28572",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/f7b7ff1ffdc34866aa1dfe8ce1e28572.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 512,
          name: "Aztec Gems",
          uuid: "83efd7f90d475a2a69bd9a91d284572c2ecd585b",
          image: "https://r.gamesstatic.com/games/83efd7f90d475a2a69bd9a91d284572c2ecd585b.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 513,
          name: "Aztec Gems Deluxe",
          uuid: "33b5ae2d7c23ee9ef35c67550b252e94e900c270",
          image: "https://r.gamesstatic.com/games/33b5ae2d7c23ee9ef35c67550b252e94e900c270.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 307,
          name: "Aztec Glory",
          uuid: "5bd809494a42fc7f52653ebf1d1b2046a08564ec",
          image: "https://r.gamesstatic.com/games/5bd809494a42fc7f52653ebf1d1b2046a08564ec.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 883,
          name: "Aztec Powernudge",
          uuid: "11a534e623574015962ac8ab67955c15",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/11a534e623574015962ac8ab67955c15.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 821,
          name: "Aztec Pyramid",
          uuid: "0e5e44bf96274580969635e44f8b66bd",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0e5e44bf96274580969635e44f8b66bd.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 822,
          name: "Aztec Sun",
          uuid: "9d32bc54c037484fa97ca16d90a2a1f2",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/9d32bc54c037484fa97ca16d90a2a1f2.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 671,
          name: "AztecSpell-10E",
          uuid: "6edfc8b8fdd9425ebd40b97c738c21b6",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/6edfc8b8fdd9425ebd40b97c738c21b6.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 729,
          name: "Bakery Bonanza",
          uuid: "3ddad6f2c02d440f8bf53142ecdb366c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3ddad6f2c02d440f8bf53142ecdb366c.png",
          type: "slots",
          provider: "PGSoft",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 730,
          name: "Bali Vacation",
          uuid: "d8e338a5620045dcaced240472e9ed85",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/d8e338a5620045dcaced240472e9ed85.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 194,
          name: "Bars and Sevens",
          uuid: "f190a15c1dfb9d48823e116f2cf6b3363beaf432",
          image: "https://r.gamesstatic.com/games/f190a15c1dfb9d48823e116f2cf6b3363beaf432.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 731,
          name: "Battleground Royale",
          uuid: "40fc2babe06644d4a7cc3621d2520fac",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/40fc2babe06644d4a7cc3621d2520fac.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 657,
          name: "Bee Hive Bonanza",
          uuid: "3b5d832bb1dd49b293a9ca0544e2c178",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/3b5d832bb1dd49b293a9ca0544e2c178.png",
          type: "slots",
          provider: "NetEnt",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 672,
          name: "Beer Collection - 10 Lines",
          uuid: "79ac88cbe3094e0ea923362714053299",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/79ac88cbe3094e0ea923362714053299.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 673,
          name: "Beer Collection - 20 Lines",
          uuid: "f4bf75acd7d1406388a3482124549f4f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/f4bf75acd7d1406388a3482124549f4f.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 580,
          name: "Bells on Fire Hot",
          uuid: "0ec8ecec1db44451866b03ec38026a15",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/0ec8ecec1db44451866b03ec38026a15.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 579,
          name: "Bells On Fire Rombo",
          uuid: "e7e603227b734916aca0a1401cfa56c7",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/e7e603227b734916aca0a1401cfa56c7.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 292,
          name: "BerryBurst",
          uuid: "d60807caee793b8da17f2967ff754042aa9594fd",
          image: "https://r.gamesstatic.com/games/d60807caee793b8da17f2967ff754042aa9594fd.jpg",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 293,
          name: "BerryBurstMax",
          uuid: "c5d07f580807e8721f1d0d4ed3c87b2a9273bddc",
          image: "https://r.gamesstatic.com/games/c5d07f580807e8721f1d0d4ed3c87b2a9273bddc.jpg",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 838,
          name: "Big Bass - Hold & Spinner",
          uuid: "ffb69ff2f1fd45fda9fe2395c054b044",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ffb69ff2f1fd45fda9fe2395c054b044.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 576,
          name: "Big Bass - Keeping it Reel",
          uuid: "f121c688643b76da9a124525f7c45a2cf7571782",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/f121c688643b76da9a124525f7c45a2cf7571782.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 514,
          name: "Big Bass Bonanza",
          uuid: "4fdeb7a8b906d1b1777b889acf63d61e41317305",
          image: "https://r.gamesstatic.com/games/4fdeb7a8b906d1b1777b889acf63d61e41317305.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 515,
          name: "Big Bass Bonanza Megaways",
          uuid: "8c2d54f2e98d10d22661b8bff97b8e138c061c24",
          image: "https://r.gamesstatic.com/games/8c2d54f2e98d10d22661b8bff97b8e138c061c24.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 884,
          name: "Big Bass Christmas Bash",
          uuid: "374b1b7535f948ab92c2826d1b0682eb",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/374b1b7535f948ab92c2826d1b0682eb.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 839,
          name: "Big Bass Floats My Boat",
          uuid: "34d5f67739934452a5b1b79690aecf75",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/34d5f67739934452a5b1b79690aecf75.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 840,
          name: "Big Bass Halloween",
          uuid: "ec4b202d89ff44a3b1e4a761418794cf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ec4b202d89ff44a3b1e4a761418794cf.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 557,
          name: "Big Bass Splash",
          uuid: "851099ed72db5d6dfc24d31d7c8890d289c160f4",
          image: "https://r.gamesstatic.com/games/851099ed72db5d6dfc24d31d7c8890d289c160f4.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 230,
          name: "Big Ben",
          uuid: "03233e92e893bd84b89920fdc815c513c79d974a",
          image: "https://r.gamesstatic.com/games/03233e92e893bd84b89920fdc815c513c79d974a.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 885,
          name: "Big Burger Load it up with Xtra",
          uuid: "444ab835293b4140992937326c31de93",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/444ab835293b4140992937326c31de93.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 823,
          name: "Big Heist",
          uuid: "cf5c8513949c496787b65f7941e16ff8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/cf5c8513949c496787b65f7941e16ff8.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 516,
          name: "Big Juan",
          uuid: "48cfb1b68588a7866496533def48b0b97669c36e",
          image: "https://r.gamesstatic.com/games/48cfb1b68588a7866496533def48b0b97669c36e.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 886,
          name: "Bigger Bass Blizzard - Christmas",
          uuid: "f46ea7db4d27417fa15dfa5e48b799ab",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/f46ea7db4d27417fa15dfa5e48b799ab.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 517,
          name: "Bigger Bass Bonanza",
          uuid: "37c5dd71a0bf602fb5be698a2b863c7df08369d7",
          image: "https://r.gamesstatic.com/games/37c5dd71a0bf602fb5be698a2b863c7df08369d7.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 732,
          name: "Bikini Paradise",
          uuid: "cbc35769f4f7465f84731d795933df13",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/cbc35769f4f7465f84731d795933df13.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 239,
          name: "Bingo Billions",
          uuid: "51b1d2240bfac317a949eb51d95a004b9b30aceb",
          image: "https://r.gamesstatic.com/games/51b1d2240bfac317a949eb51d95a004b9b30aceb.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 240,
          name: "Bingo Billions BDG",
          uuid: "bdece8262fdafddd9803449eb076d8a9a8fdd5bb",
          image: "https://r.gamesstatic.com/games/bdece8262fdafddd9803449eb076d8a9a8fdd5bb.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 824,
          name: "Black Wolf",
          uuid: "a2a262af2f3943c781a9d8382addbfa8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a2a262af2f3943c781a9d8382addbfa8.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 887,
          name: "Blade & Fangs",
          uuid: "32806e7c0ba9407d8172e73d50399ad0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/32806e7c0ba9407d8172e73d50399ad0.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 888,
          name: "Blazing Wilds Megaways",
          uuid: "33e8cf20b5764c6da8fd07cf305228ac",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/33e8cf20b5764c6da8fd07cf305228ac.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 241,
          name: "Bloodlore Vampire clan",
          uuid: "d6cb558daab656f0ee082e3ed71f16690de3fee2",
          image: "https://r.gamesstatic.com/games/d6cb558daab656f0ee082e3ed71f16690de3fee2.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 841,
          name: "BloodSuckers",
          uuid: "e8ba0a21141334fe973286412191729b33edc54e",
          image: "https://r.gamesstatic.com/games/e8ba0a21141334fe973286412191729b33edc54e.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 227,
          name: "BloodSuckers2",
          uuid: "87f4275df1c107bfbc9ac080fe36083c0b459fdf",
          image: "https://r.gamesstatic.com/games/87f4275df1c107bfbc9ac080fe36083c0b459fdf.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 501,
          name: "Blue Diamond",
          uuid: "957ec00871067f51bb5402d3ff6e6f15d349a614",
          image: "https://r.gamesstatic.com/games/957ec00871067f51bb5402d3ff6e6f15d349a614.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 581,
          name: "Blue Dolphin",
          uuid: "c072cfc44de7427794dc7db6a4742cd2",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/c072cfc44de7427794dc7db6a4742cd2.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 674,
          name: "Blue Panther",
          uuid: "384fac1575ad4ceca20c0545e07335aa",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/384fac1575ad4ceca20c0545e07335aa.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 675,
          name: "Blue Panther Christmas Edition",
          uuid: "df7bfc9ba84546d3ac28a144da9af0fa",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/df7bfc9ba84546d3ac28a144da9af0fa.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 350,
          name: "Bollywood Story",
          uuid: "535ecd5946294b80d92f7219cd32e84ed0019fe3",
          image: "https://r.gamesstatic.com/games/535ecd5946294b80d92f7219cd32e84ed0019fe3.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 67,
          name: "Book Of Aphrodite - The Golden Era",
          uuid: "ddac24f2264e42d5819df493e2d061eb",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/ddac24f2264e42d5819df493e2d061eb.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 68,
          name: "Book Of Aphrodite - The Love Spell",
          uuid: "7a6b8f78dccb41ea82670b31d484a61d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/7a6b8f78dccb41ea82670b31d484a61d.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 62,
          name: "Book Of Aztec",
          uuid: "2650a7e0a78a41fbb0e3d4cdabc417b9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/2650a7e0a78a41fbb0e3d4cdabc417b9.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 69,
          name: "Book Of Baba Yaga",
          uuid: "a7e8e703663241028e5fa2b95575b1a6",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/a7e8e703663241028e5fa2b95575b1a6.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 70,
          name: "Book Of Baba Yaga - Winter Spell",
          uuid: "959e4f98898544f8a1e93eeed9bdbd17",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/959e4f98898544f8a1e93eeed9bdbd17.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 71,
          name: "Book Of Champions",
          uuid: "8db5789bedfa4934b2310dad03a9dee2",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/8db5789bedfa4934b2310dad03a9dee2.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 72,
          name: "Book Of Champions - World Glory",
          uuid: "7b0456045bba4c47b459bbf805b26cc6",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/7b0456045bba4c47b459bbf805b26cc6.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 73,
          name: "Book Of Clovers",
          uuid: "56aa50221cea4f8392a1202f6e608e17",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/56aa50221cea4f8392a1202f6e608e17.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 61,
          name: "Book of Dead",
          uuid: "a0b71a253c284599926231dc51a9128b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/a0b71a253c284599926231dc51a9128b.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 74,
          name: "Book Of Demi Gods 3",
          uuid: "271303fa20e341d8b99754582af13616",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/271303fa20e341d8b99754582af13616.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 75,
          name: "Book Of Demi Gods II",
          uuid: "3a5a051386744b98bc7813576f53e149",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/3a5a051386744b98bc7813576f53e149.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 76,
          name: "Book Of Demi Gods II - Christmas Edition",
          uuid: "09e71590962a4c4ba67183e77077c050",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/09e71590962a4c4ba67183e77077c050.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 77,
          name: "Book Of Demi Gods IV",
          uuid: "5677c1ff9fa74acba355a023ef9269ed",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/5677c1ff9fa74acba355a023ef9269ed.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 78,
          name: "Book Of Easter Piggy Bank",
          uuid: "d8b0e97e61a947f4aba1301908a3976a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/d8b0e97e61a947f4aba1301908a3976a.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 59,
          name: "Book Of Fate",
          uuid: "b70061ea67934b68c125dba0f218ecf3bd8c3900",
          image: "https://r.gamesstatic.com/games/b70061ea67934b68c125dba0f218ecf3bd8c3900.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 63,
          name: "Book Of Fortune",
          uuid: "c6cc3d15e663467ab176bf6c9c5fa9ff",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/c6cc3d15e663467ab176bf6c9c5fa9ff.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 64,
          name: "Book of Fruits",
          uuid: "afb9bb140a24472e8583f21b26c27d97",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/afb9bb140a24472e8583f21b26c27d97.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 79,
          name: "Book Of Irish Treasures",
          uuid: "cb5817d5374047e2834079429f8ddd51",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/cb5817d5374047e2834079429f8ddd51.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 65,
          name: "Book of Lords",
          uuid: "8aa5dfb21a6e4b4ead9dc99846d5d687",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/8aa5dfb21a6e4b4ead9dc99846d5d687.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 80,
          name: "Book Of Lucky Jack - Baba Yaga’s Tales",
          uuid: "e53e5a260aca4c70a3e8017d5a652a85",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/e53e5a260aca4c70a3e8017d5a652a85.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 81,
          name: "Book of Panther",
          uuid: "c67b0ee888e14a73a45a82fe8bb8cc0b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/c67b0ee888e14a73a45a82fe8bb8cc0b.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 82,
          name: "Book of Piggy Bank",
          uuid: "c2c424bdc9f34a529f19f55f15fafe4a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/c2c424bdc9f34a529f19f55f15fafe4a.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 66,
          name: "Book of Queen",
          uuid: "6624a0c2cbb44af3b91ec155748676d7",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/6624a0c2cbb44af3b91ec155748676d7.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 836,
          name: "Book of Ra 10 Deluxe",
          uuid: "6236262d6f3bb6c847dd63d77bc2c45104eb3d98",
          image: "https://r.gamesstatic.com/games/6236262d6f3bb6c847dd63d77bc2c45104eb3d98.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 835,
          name: "Book of Ra 10 Deluxe [Mobile]",
          uuid: "5f2d90faf98551276458a06767f3336019834c32",
          image: "https://r.gamesstatic.com/games/5f2d90faf98551276458a06767f3336019834c32.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 57,
          name: "Book of Ra Deluxe 6",
          uuid: "e4e7cdaf74c7bdbb902f7db9c48e46d80763387c",
          image: "https://r.gamesstatic.com/games/e4e7cdaf74c7bdbb902f7db9c48e46d80763387c.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 265,
          name: "Book of Ra Dice",
          uuid: "4405edf26e6d282b4f862180d88c2f64414e09ce",
          image: "https://r.gamesstatic.com/games/4405edf26e6d282b4f862180d88c2f64414e09ce.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 60,
          name: "Book of Ra Magic",
          uuid: "05c280a3df41996be9283ee0668d6c1cb8070fec",
          image: "https://r.gamesstatic.com/games/05c280a3df41996be9283ee0668d6c1cb8070fec.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 83,
          name: "Book of Rampage",
          uuid: "c962c9a34f22476daf1f39a5a5f185bf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/c962c9a34f22476daf1f39a5a5f185bf.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 84,
          name: "Book of Rampage - Christmas Edition",
          uuid: "791071ba80934aa0b640026b90e65fb2",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/791071ba80934aa0b640026b90e65fb2.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 85,
          name: "Book Of Rebirth",
          uuid: "47cfe0c811c74ee2aa19ed16e1181057",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/47cfe0c811c74ee2aa19ed16e1181057.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 86,
          name: "Book Of Rebirth - Reloaded",
          uuid: "8538667f03c840b2bcbd08a385986d52",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/8538667f03c840b2bcbd08a385986d52.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 87,
          name: "Book Of Sirens",
          uuid: "7c755fed56b741dfb599b5e365e55e61",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/7c755fed56b741dfb599b5e365e55e61.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 88,
          name: "Book Of Sirens - The Golden Era",
          uuid: "8b5627f9c82d4d6288bacfec150d1f76",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/8b5627f9c82d4d6288bacfec150d1f76.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 58,
          name: "Book of Stars",
          uuid: "b0b2996bf689cdcd1c73665eb63be66f00ef23ef",
          image: "https://r.gamesstatic.com/games/b0b2996bf689cdcd1c73665eb63be66f00ef23ef.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 825,
          name: "Book of Sun",
          uuid: "4c951129301d437b832bc0916ff23ebf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/4c951129301d437b832bc0916ff23ebf.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 826,
          name: "Book of Sun: Multichance",
          uuid: "a54215d0cf4c485b9ead8e8fb5752c7d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a54215d0cf4c485b9ead8e8fb5752c7d.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 89,
          name: "Book Of Tribes",
          uuid: "81e9649390b846a6a753a2296a65abd4",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/81e9649390b846a6a753a2296a65abd4.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 889,
          name: "Book of Tut Megaways",
          uuid: "68f59eb379d6465b93fb04b6284d05ca",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/68f59eb379d6465b93fb04b6284d05ca.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 827,
          name: "Book of Wizard",
          uuid: "e0936b843aa84e98aed5e9c83d9a14ff",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/e0936b843aa84e98aed5e9c83d9a14ff.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 828,
          name: "Boom! Boom! Gold!",
          uuid: "b3f2557a241349aebe9591727a4f3c51",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/b3f2557a241349aebe9591727a4f3c51.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 500,
          name: "Bounty Raid",
          uuid: "cb739dc343ef75ca8a11188de7f9c363c8b1d800",
          image: "https://r.gamesstatic.com/games/cb739dc343ef75ca8a11188de7f9c363c8b1d800.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 94,
          name: "Boxing Ring Champions",
          uuid: "6ee29941659a4315acb41dc07a8f1563",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/6ee29941659a4315acb41dc07a8f1563.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 829,
          name: "Buddha Fortune",
          uuid: "f1fe950e9df041a591a5db2c07d66fa4",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/f1fe950e9df041a591a5db2c07d66fa4.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 518,
          name: "Buffalo King",
          uuid: "27ee96c4051f6a650dfda3c8bf12d03603501896",
          image: "https://r.gamesstatic.com/games/27ee96c4051f6a650dfda3c8bf12d03603501896.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 519,
          name: "Buffalo King Megaways",
          uuid: "347d88f3ed834e9c0c6aab548cd5c1e4bb961820",
          image: "https://r.gamesstatic.com/games/347d88f3ed834e9c0c6aab548cd5c1e4bb961820.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 733,
          name: "Buffalo Win",
          uuid: "6bb4240b90ce4f0caadf789f86eb3395",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/6bb4240b90ce4f0caadf789f86eb3395.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 600,
          name: "Bull in a Rodeo",
          uuid: "e9e365bfc0044ecb829e09b1fcafe6e9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/e9e365bfc0044ecb829e09b1fcafe6e9.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 660,
          name: "Buster's Bones",
          uuid: "222a83ce5db647a1bdf971a48ee08a70",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/222a83ce5db647a1bdf971a48ee08a70.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 734,
          name: "Butterfly Blossom",
          uuid: "ff6af7041daa4a64b6267902f7946e54",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/ff6af7041daa4a64b6267902f7946e54.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 339,
          name: "Butterfly Staxx 2",
          uuid: "0a2350a96b0ff0109ccf8f27c3419bb2a2e0e981",
          image: "https://r.gamesstatic.com/games/0a2350a96b0ff0109ccf8f27c3419bb2a2e0e981.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 842,
          name: "ButterflyStaxx",
          uuid: "eb3b1a1e45ebb19aa2ec6b9198e29b820be4d2fb",
          image: "https://r.gamesstatic.com/games/eb3b1a1e45ebb19aa2ec6b9198e29b820be4d2fb.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 830,
          name: "Caishen Wealth",
          uuid: "522d7563846a4b7891942148b6d8d1b0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/522d7563846a4b7891942148b6d8d1b0.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 890,
          name: "Candy Blitz",
          uuid: "e264e52ffb8c47f1875f30a2a89837d9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/e264e52ffb8c47f1875f30a2a89837d9.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 735,
          name: "Candy Bonanza",
          uuid: "d7ac004af593449495f45b79ad1457ba",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/d7ac004af593449495f45b79ad1457ba.png",
          type: "slots",
          provider: "PGSoft",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 831,
          name: "Candy Boom",
          uuid: "8898d30571df4420af6597d06a5d4d56",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/8898d30571df4420af6597d06a5d4d56.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 599,
          name: "Canine Carnage",
          uuid: "ea05d0af6d3d4be0881b4a7cf2ce5e64",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/ea05d0af6d3d4be0881b4a7cf2ce5e64.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 694,
          name: "Captain Marvel",
          uuid: "1ca46c650d00c665d89c16f485987f7eb3b2a84a",
          image: "https://r.gamesstatic.com/games/1ca46c650d00c665d89c16f485987f7eb3b2a84a.jpg",
          type: "slots",
          provider: "BrandGames",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 269,
          name: "Captain Venture",
          uuid: "79dc87adc807e74c15cadef936ba993e19527e52",
          image: "https://r.gamesstatic.com/games/79dc87adc807e74c15cadef936ba993e19527e52.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 504,
          name: "Captain Xeno`s Earth Adventure",
          uuid: "d3e5afeacfab3fc5c9e647aada9f4b29fa03bebf",
          image: "https://r.gamesstatic.com/games/d3e5afeacfab3fc5c9e647aada9f4b29fa03bebf.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 736,
          name: "Captains Bounty",
          uuid: "028900c63ecd4b12a33ab1ea350e8cb9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/028900c63ecd4b12a33ab1ea350e8cb9.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 308,
          name: "Caramel Dice",
          uuid: "72e0887757a480b0b9d0bab65838768a921b0da9",
          image: "https://r.gamesstatic.com/games/72e0887757a480b0b9d0bab65838768a921b0da9.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 309,
          name: "Caramel Hot",
          uuid: "43d8cbe078898a8ed96aecdfb1d8e4e35834410d",
          image: "https://r.gamesstatic.com/games/43d8cbe078898a8ed96aecdfb1d8e4e35834410d.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 582,
          name: "Casanova",
          uuid: "72197acec4ff4065a0c67a061396f9fd",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/72197acec4ff4065a0c67a061396f9fd.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 370,
          name: "Cash Noire",
          uuid: "8d47cb95e98003b18fdaebd57bb9687284c44105",
          image: "https://r.gamesstatic.com/games/8d47cb95e98003b18fdaebd57bb9687284c44105.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 558,
          name: "Cash Patrol",
          uuid: "7fdf5af7d2f19173d6a639c31f6f643d146dd254",
          image: "https://r.gamesstatic.com/games/7fdf5af7d2f19173d6a639c31f6f643d146dd254.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 221,
          name: "Cash Runner",
          uuid: "c3e21daa0b74f1e760412a0eb3793047d2dfb440",
          image: "https://r.gamesstatic.com/games/c3e21daa0b74f1e760412a0eb3793047d2dfb440.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 499,
          name: "Cash Volt",
          uuid: "afd2c00486bfe31f42e8b5618bfea0c28b32ea5a",
          image: "https://r.gamesstatic.com/games/afd2c00486bfe31f42e8b5618bfea0c28b32ea5a.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 541,
          name: "Cash-o-Matic",
          uuid: "bb23ea2ed96e65b002e35b0af202e621c5632c3d",
          image: "https://r.gamesstatic.com/games/bb23ea2ed96e65b002e35b0af202e621c5632c3d.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 505,
          name: "Cat Wilde and the Lost Chapter",
          uuid: "0f9eefa0e42bb314d2ba54c3a3a091533a8ca65f",
          image: "https://r.gamesstatic.com/games/0f9eefa0e42bb314d2ba54c3a3a091533a8ca65f.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 391,
          name: "Cat Wilde Doom",
          uuid: "d581dcae88a049b6866c8465ecfae750",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/d581dcae88a049b6866c8465ecfae750.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 392,
          name: "Cat Wilde Eclipse",
          uuid: "12bf9d42cd4d4c88a55bd6d8ebc8398e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/12bf9d42cd4d4c88a55bd6d8ebc8398e.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 286,
          name: "Changing Fate 40",
          uuid: "c5439221e5123563df528a8e328c066d1307cd1f",
          image: "https://r.gamesstatic.com/games/c5439221e5123563df528a8e328c066d1307cd1f.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 393,
          name: "Charlie Chance",
          uuid: "412ba82a90bf4beebf608b919f6af477",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/412ba82a90bf4beebf608b919f6af477.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 242,
          name: "Cherry Blossoms",
          uuid: "0d666e40bf35d9ec5bdc7db44b1756ea7de1063c",
          image: "https://r.gamesstatic.com/games/0d666e40bf35d9ec5bdc7db44b1756ea7de1063c.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 195,
          name: "Chicago",
          uuid: "0554a88cae8624a735492fb1a454d21f9aaa1f0b",
          image: "https://r.gamesstatic.com/games/0554a88cae8624a735492fb1a454d21f9aaa1f0b.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 571,
          name: "Chilli Heat",
          uuid: "6abb1004e3fb5c1543fc46a530968dfbcd068fce",
          image: "https://r.gamesstatic.com/games/6abb1004e3fb5c1543fc46a530968dfbcd068fce.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 498,
          name: "Chinese Treasures",
          uuid: "a1ace081acbaf3d21a5d4c119fc02b1c81b6f857",
          image: "https://r.gamesstatic.com/games/a1ace081acbaf3d21a5d4c119fc02b1c81b6f857.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 231,
          name: "Choy Sun Doa",
          uuid: "64b16c927cdda954d77146da038db5145194bf25",
          image: "https://r.gamesstatic.com/games/64b16c927cdda954d77146da038db5145194bf25.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 101,
          name: "Christmas Big Bass Bonanza",
          uuid: "345ff75d1807795f64097d155857c4f7fcd10ef7",
          image: "https://r.gamesstatic.com/games/345ff75d1807795f64097d155857c4f7fcd10ef7.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 96,
          name: "Christmas Carol Megaways",
          uuid: "b42b93c09dae4558a8905df6c11d9653",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/b42b93c09dae4558a8905df6c11d9653.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 98,
          name: "Christmas Santa",
          uuid: "711ad1f2c27f424d88a9244a16ab1b56",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/711ad1f2c27f424d88a9244a16ab1b56.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 270,
          name: "Cindereela",
          uuid: "7a7ef9a5ab7d5389b6996943512b0086a1cd2b64",
          image: "https://r.gamesstatic.com/games/7a7ef9a5ab7d5389b6996943512b0086a1cd2b64.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 497,
          name: "Cirque De La Fortune",
          uuid: "ce6dc1c713b6177c235d32f4eb7acb9d9bdc7a6d",
          image: "https://r.gamesstatic.com/games/ce6dc1c713b6177c235d32f4eb7acb9d9bdc7a6d.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 496,
          name: "Clash of the Beasts",
          uuid: "7b6bc04ae824f76e03488bd94979a204550744a6",
          image: "https://r.gamesstatic.com/games/7b6bc04ae824f76e03488bd94979a204550744a6.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 559,
          name: "Cleocatra",
          uuid: "09c8839d5531bbaebf7811058322957192cfac3f",
          image: "https://r.gamesstatic.com/games/09c8839d5531bbaebf7811058322957192cfac3f.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 737,
          name: "Cocktail Nights",
          uuid: "5d08dbcdbab34449b0fb440a0d9ade28",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/5d08dbcdbab34449b0fb440a0d9ade28.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 222,
          name: "Coin Of Apollo",
          uuid: "47846c21f55845fd08564ac1172b9c1afbdf7c94",
          image: "https://r.gamesstatic.com/games/47846c21f55845fd08564ac1172b9c1afbdf7c94.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 371,
          name: "Coins of Egypt",
          uuid: "03d67136f74b0b1a9f30273d36959560c50d0622",
          image: "https://r.gamesstatic.com/games/03d67136f74b0b1a9f30273d36959560c50d0622.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 676,
          name: "Colossus Fruits",
          uuid: "f466c4d1dbf9487aa67ebde1ff60b2e1",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/f466c4d1dbf9487aa67ebde1ff60b2e1.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 677,
          name: "Colossus Fruits - Christmas Edition",
          uuid: "0f51a5e888a64ef1a9626999371f9579",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/0f51a5e888a64ef1a9626999371f9579.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 243,
          name: "Cool Bananas",
          uuid: "af345ca5dcca64f6e32ee6389d2c6e9e3e14cd2c",
          image: "https://r.gamesstatic.com/games/af345ca5dcca64f6e32ee6389d2c6e9e3e14cd2c.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 583,
          name: "Cool Diamonds II",
          uuid: "0fd32163627a488a9285c11cf8cc09d4",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/0fd32163627a488a9285c11cf8cc09d4.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 843,
          name: "CopyCats",
          uuid: "0d045e42e63db018db8b7c789ca03b8015267c68",
          image: "https://r.gamesstatic.com/games/0d045e42e63db018db8b7c789ca03b8015267c68.png",
          type: "slots",
          provider: "NetEnt",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 310,
          name: "Coral Island",
          uuid: "9b700ae29b46a2171540735d3c7dba6bf7021869",
          image: "https://r.gamesstatic.com/games/9b700ae29b46a2171540735d3c7dba6bf7021869.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 658,
          name: "Cornelius",
          uuid: "3102b4556c364861becce635d95ce441",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/3102b4556c364861becce635d95ce441.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 598,
          name: "Count Jokula",
          uuid: "ff39f181d8334e6fa9c59173f07e26f8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/ff39f181d8334e6fa9c59173f07e26f8.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 394,
          name: "Court of Hearts",
          uuid: "ae85ea8feec64fe8ae06670951c55c6a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/ae85ea8feec64fe8ae06670951c55c6a.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 651,
          name: "Cowboys Gold",
          uuid: "03e01832eaac41d5935132ee2b8e0860",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/03e01832eaac41d5935132ee2b8e0860.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 495,
          name: "Crazy Genie",
          uuid: "f04bf72da649a546ffcfe46b57dfc45077f78631",
          image: "https://r.gamesstatic.com/games/f04bf72da649a546ffcfe46b57dfc45077f78631.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 1,
          name: "Crazy Monkey",
          uuid: "f0523e9ecde5ad8b11164fad48d4fe158acdee47",
          image: "https://r.gamesstatic.com/games/f0523e9ecde5ad8b11164fad48d4fe158acdee47.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 2,
          name: "Crazy Monkey 2",
          uuid: "3752692a14c19c1e73bcddb25f3fecd3742d7b58",
          image: "https://r.gamesstatic.com/games/3752692a14c19c1e73bcddb25f3fecd3742d7b58.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 738,
          name: "Crypto Gold",
          uuid: "609eb5e905594b84a33261da9109e4d7",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/609eb5e905594b84a33261da9109e4d7.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 494,
          name: "Crystal Mirror",
          uuid: "006e48551793416035255d458a62c3997b2862aa",
          image: "https://r.gamesstatic.com/games/006e48551793416035255d458a62c3997b2862aa.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 110,
          name: "Cupcakes",
          uuid: "36c5024fef6b45738122ea5bb6001a77",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/36c5024fef6b45738122ea5bb6001a77.png",
          type: "slots",
          provider: "NetEnt",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 520,
          name: "Curse of the Werewolf Megaways",
          uuid: "82cb22f93475cac65e41360d7d54371fdca036d3",
          image: "https://r.gamesstatic.com/games/82cb22f93475cac65e41360d7d54371fdca036d3.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 891,
          name: "Cyclops Smash",
          uuid: "787b16fcf8c54825b56e8c6f4fd33f40",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/787b16fcf8c54825b56e8c6f4fd33f40.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 372,
          name: "Dark King: Forbidden Riches",
          uuid: "dd14c96eb7e41a23dbc80fb16f0af96f4e3b2617",
          image: "https://r.gamesstatic.com/games/dd14c96eb7e41a23dbc80fb16f0af96f4e3b2617.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 321,
          name: "Dark Queen",
          uuid: "56953990fb7d21d61c6da3b9938fc120957d2c3c",
          image: "https://r.gamesstatic.com/games/56953990fb7d21d61c6da3b9938fc120957d2c3c.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 678,
          name: "Dark Wolf",
          uuid: "5bba9d65bb2d4295ace29b20df6bb510",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/5bba9d65bb2d4295ace29b20df6bb510.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 141,
          name: "Dazzle Me Megaways",
          uuid: "699a8f38f22fb6740135d7c30d45d8a4a8b07f70",
          image: "https://r.gamesstatic.com/games/699a8f38f22fb6740135d7c30d45d8a4a8b07f70.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 196,
          name: "Dazzling Diamonds",
          uuid: "f25fcc13b3dce9eb12c1993e99b9d05fb5be1496",
          image: "https://r.gamesstatic.com/games/f25fcc13b3dce9eb12c1993e99b9d05fb5be1496.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 614,
          name: "Dead Man's Trail",
          uuid: "12927f94801a4d069258a1d364b4ac7b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/12927f94801a4d069258a1d364b4ac7b.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 661,
          name: "Deadly 5",
          uuid: "baaafdaa01524b888cfbe3c9e1f17214",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/baaafdaa01524b888cfbe3c9e1f17214.png",
          type: "slots",
          provider: "PushGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 844,
          name: "DeadOrAlive",
          uuid: "9f662a211275f39a9c37e850cd739dbb0a2660f8",
          image: "https://r.gamesstatic.com/games/9f662a211275f39a9c37e850cd739dbb0a2660f8.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 690,
          name: "DeadOrAlive2",
          uuid: "b832ecb5b34d4c69a5c0ee86ee6694e9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/b832ecb5b34d4c69a5c0ee86ee6694e9.jpg",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 294,
          name: "DeadOrAlive2",
          uuid: "28783c50636dd30a3d4ce82c3bcff2d153692370",
          image: "https://r.gamesstatic.com/games/28783c50636dd30a3d4ce82c3bcff2d153692370.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 615,
          name: "Deep Descent",
          uuid: "320a976cc6b24be38a4f705eb955e111",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/320a976cc6b24be38a4f705eb955e111.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 552,
          name: "Def Leppard: Hysteria",
          uuid: "6a8f0a93c924368c38b707cbb852c065ee5f61a9",
          image: "https://r.gamesstatic.com/games/6a8f0a93c924368c38b707cbb852c065ee5f61a9.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 892,
          name: "Demon Pots",
          uuid: "2d7ff8d0d1a441be8b28c9b738e0b40e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/2d7ff8d0d1a441be8b28c9b738e0b40e.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 739,
          name: "Destiny of Sun & Moon",
          uuid: "c22881d0cf1043999f1608286a2a25eb",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/c22881d0cf1043999f1608286a2a25eb.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 337,
          name: "Deuces Wilde",
          uuid: "9e07302c1dba27c7f312ea0ffa731a9ac5602b04",
          image: "https://r.gamesstatic.com/games/9e07302c1dba27c7f312ea0ffa731a9ac5602b04.png",
          type: "poker",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 493,
          name: "Devil`s Number",
          uuid: "2244918849b00bf1067d225fc8a818a18a114ea6",
          image: "https://r.gamesstatic.com/games/2244918849b00bf1067d225fc8a818a18a114ea6.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 584,
          name: "Diamond Cats",
          uuid: "8285bb67dffb4d778387918644a6f0ed",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/8285bb67dffb4d778387918644a6f0ed.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 395,
          name: "Diamonds of the Realm",
          uuid: "2b9914de50284e9d8fc3a58525ae42e5",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/2b9914de50284e9d8fc3a58525ae42e5.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 118,
          name: "Dice",
          uuid: "bbcce2c3acf54d68b0757fac314fd47f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/bbcce2c3acf54d68b0757fac314fd47f.png",
          type: "minigame",
          provider: "Spribe",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 322,
          name: "Dice And Roll",
          uuid: "6bbb66cacc95649bc98b28334d1ad05cea69b7f5",
          image: "https://r.gamesstatic.com/games/6bbb66cacc95649bc98b28334d1ad05cea69b7f5.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 492,
          name: "Dice Dice Dice",
          uuid: "2a069bc0465b310af608836102000833a751837f",
          image: "https://r.gamesstatic.com/games/2a069bc0465b310af608836102000833a751837f.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 740,
          name: "Diner Delights",
          uuid: "6d37c41f0e0540e9b37b73c40edcf825",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/6d37c41f0e0540e9b37b73c40edcf825.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 893,
          name: "Ding Dong Christmas Bells",
          uuid: "5d408d3e9b4f4ea5b27d782f0408c5d6",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/5d408d3e9b4f4ea5b27d782f0408c5d6.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 597,
          name: "Dio - Killing the Dragon",
          uuid: "20cb6fb2b1a0488192faa0001a61402e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/20cb6fb2b1a0488192faa0001a61402e.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 373,
          name: "Divine Fortune MegaWays™",
          uuid: "392bc6ef13ecf3a7da03bc559426ab665b174dec",
          image: "https://r.gamesstatic.com/games/392bc6ef13ecf3a7da03bc559426ab665b174dec.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 244,
          name: "Doctor Love",
          uuid: "b9852ec26d29e01127614750db65c5bea0659ada",
          image: "https://r.gamesstatic.com/games/b9852ec26d29e01127614750db65c5bea0659ada.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 245,
          name: "Doctor Love On Vacation",
          uuid: "08aa1378fab495477ec73f8cee862d1c9f371027",
          image: "https://r.gamesstatic.com/games/08aa1378fab495477ec73f8cee862d1c9f371027.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 709,
          name: "Dolphin",
          uuid: "645ac13151579271e5b929b32252bc88a1d923d3",
          image: "https://r.gamesstatic.com/games/645ac13151579271e5b929b32252bc88a1d923d3.png",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 413,
          name: "Doom of Egypt",
          uuid: "81e9a2ce979d373f0cedc5712265a12cc95e9f7b",
          image: "https://r.gamesstatic.com/games/81e9a2ce979d373f0cedc5712265a12cc95e9f7b.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 741,
          name: "Double Fortune",
          uuid: "05cc79e6145f4ea1947fe3c38d8ca076",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/05cc79e6145f4ea1947fe3c38d8ca076.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 232,
          name: "Double Happiness",
          uuid: "716ef43c014d30be12e4edaaf87c8addc91ed78a",
          image: "https://r.gamesstatic.com/games/716ef43c014d30be12e4edaaf87c8addc91ed78a.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 351,
          name: "Double Stacks",
          uuid: "3f98fce7841d80b26f43707b7c36889bf102fd1e",
          image: "https://r.gamesstatic.com/games/3f98fce7841d80b26f43707b7c36889bf102fd1e.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 845,
          name: "Dracula",
          uuid: "3213c61614454b39d1b5ff847bff68e30078b2a2",
          image: "https://r.gamesstatic.com/games/3213c61614454b39d1b5ff847bff68e30078b2a2.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 233,
          name: "Dragon Emperor",
          uuid: "57c6336841e14626ff072de93ed02c201795bbef",
          image: "https://r.gamesstatic.com/games/57c6336841e14626ff072de93ed02c201795bbef.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 742,
          name: "Dragon Hatch",
          uuid: "8459dd643efb4aba8dd2e02f3c974258",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/8459dd643efb4aba8dd2e02f3c974258.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 695,
          name: "Dragon King",
          uuid: "22d801eaa2646e5235920812016c1343e5d9c1b9",
          image: "https://r.gamesstatic.com/games/22d801eaa2646e5235920812016c1343e5d9c1b9.jpg",
          type: "slots",
          provider: "Qilin",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 491,
          name: "Dragon King: Legend of the Seas",
          uuid: "492eb84a7c7d7709a6dc0db727aa09924a1591c1",
          image: "https://r.gamesstatic.com/games/492eb84a7c7d7709a6dc0db727aa09924a1591c1.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 832,
          name: "Dragon Pearls",
          uuid: "7d44b636437945d480d188a4679fa46b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/7d44b636437945d480d188a4679fa46b.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 355,
          name: "Dragon Reels",
          uuid: "8faca9bfa476d9740c13ec367a9859ffad856422",
          image: "https://r.gamesstatic.com/games/8faca9bfa476d9740c13ec367a9859ffad856422.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 271,
          name: "Dragon Warrior",
          uuid: "79d8b339d2a65aa64f008f29a952fcdbcec7dd7d",
          image: "https://r.gamesstatic.com/games/79d8b339d2a65aa64f008f29a952fcdbcec7dd7d.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 833,
          name: "Dragon Wealth",
          uuid: "6505087834d24065b577a3cf90c21279",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/6505087834d24065b577a3cf90c21279.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 216,
          name: "Dragons Deep",
          uuid: "30dae8835a8a67cf444a3a393a08bdd55482cde4",
          image: "https://r.gamesstatic.com/games/30dae8835a8a67cf444a3a393a08bdd55482cde4.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 616,
          name: "Dragons' Awakening",
          uuid: "8a9d8652a18241d89d88a453d5aa5fcf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/8a9d8652a18241d89d88a453d5aa5fcf.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 490,
          name: "Dragon`s Luck",
          uuid: "0d68c0cae26977c073851cefc69134b6eabac1b3",
          image: "https://r.gamesstatic.com/games/0d68c0cae26977c073851cefc69134b6eabac1b3.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 489,
          name: "Dragon`s Luck MegaWays",
          uuid: "56db2446bf6023760d0ffa78d9c8e610e7f580ca",
          image: "https://r.gamesstatic.com/games/56db2446bf6023760d0ffa78d9c8e610e7f580ca.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 743,
          name: "Dreams of Macau",
          uuid: "2c14e4f28bf74f22bbbd5ceda7e48d53",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/2c14e4f28bf74f22bbbd5ceda7e48d53.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 190,
          name: "Dynasty of Ra",
          uuid: "f5960a53ba3de6acd2c700cea65382fbfff7c5e4",
          image: "https://r.gamesstatic.com/games/f5960a53ba3de6acd2c700cea65382fbfff7c5e4.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 255,
          name: "Eastern Dragon",
          uuid: "5e493158fffbb3ee7beb9365cd4b0cbf250917e6",
          image: "https://r.gamesstatic.com/games/5e493158fffbb3ee7beb9365cd4b0cbf250917e6.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 834,
          name: "Eggs of Gold",
          uuid: "e6562e11fe064231880a6ad8438f818c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/e6562e11fe064231880a6ad8438f818c.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 775,
          name: "Egypt Fire",
          uuid: "3d3feb82eacc498ca981ea1fff950eb0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/3d3feb82eacc498ca981ea1fff950eb0.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 354,
          name: "Egypt Sky",
          uuid: "3cde98e815d9292248a6a5a373c7dd13170ad82e",
          image: "https://r.gamesstatic.com/games/3cde98e815d9292248a6a5a373c7dd13170ad82e.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 521,
          name: "Elemental Gems Megaways",
          uuid: "ec39c3d9804f53652dfc0ddbf0b027b5a3a48041",
          image: "https://r.gamesstatic.com/games/ec39c3d9804f53652dfc0ddbf0b027b5a3a48041.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 542,
          name: "Elements: The Awakening",
          uuid: "2d2b2a09ddbde7d83f92a2a9892b85d09c8b40f7",
          image: "https://r.gamesstatic.com/games/2d2b2a09ddbde7d83f92a2a9892b85d09c8b40f7.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 488,
          name: "Elven Magic",
          uuid: "580fbd46d6914002410d0a8c50b74cafa68e9468",
          image: "https://r.gamesstatic.com/games/580fbd46d6914002410d0a8c50b74cafa68e9468.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 744,
          name: "Emoji Riches",
          uuid: "9e9e7e07e5854f13848bb3a4fc087a34",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9e9e7e07e5854f13848bb3a4fc087a34.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 256,
          name: "Emperors Garden",
          uuid: "fda41641a0ee6cf9294003d0c319c63d2c66fe97",
          image: "https://r.gamesstatic.com/games/fda41641a0ee6cf9294003d0c319c63d2c66fe97.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 257,
          name: "Emperors Garden Dice",
          uuid: "9611f3c6744f8ae842f7825e4fee88c80313f6c6",
          image: "https://r.gamesstatic.com/games/9611f3c6744f8ae842f7825e4fee88c80313f6c6.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 223,
          name: "Empire V",
          uuid: "3550e31cc347aa2ce9849f1cfc9f07e393448fe2",
          image: "https://r.gamesstatic.com/games/3550e31cc347aa2ce9849f1cfc9f07e393448fe2.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 617,
          name: "Epic Joker",
          uuid: "8cdeb1c280134ebf8e1f4549656b1191",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/8cdeb1c280134ebf8e1f4549656b1191.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 487,
          name: "Epic Journey",
          uuid: "ce7c8bd91277e3e933a2f5aed2dbb2186a27e645",
          image: "https://r.gamesstatic.com/games/ce7c8bd91277e3e933a2f5aed2dbb2186a27e645.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 618,
          name: "Erik the Red",
          uuid: "54e635081b2e46068185aefb064474d8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/54e635081b2e46068185aefb064474d8.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 486,
          name: "Esqueleto Mariachi",
          uuid: "f7a4a0723a5fd004ad14222039cf47a375baf2db",
          image: "https://r.gamesstatic.com/games/f7a4a0723a5fd004ad14222039cf47a375baf2db.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 348,
          name: "Excalibur",
          uuid: "cc0457fd171acd7ad1c6faa49cc4f22712ba0257",
          image: "https://r.gamesstatic.com/games/cc0457fd171acd7ad1c6faa49cc4f22712ba0257.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 522,
          name: "Extra Juicy",
          uuid: "4dbba34e840f19f848c0c6bd35aac797c303dc9e",
          image: "https://r.gamesstatic.com/games/4dbba34e840f19f848c0c6bd35aac797c303dc9e.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 168,
          name: "Extra Stars",
          uuid: "2c48ca0ab1574d882aed0e4c3ef260ddc6e9bb05",
          image: "https://r.gamesstatic.com/games/2c48ca0ab1574d882aed0e4c3ef260ddc6e9bb05.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 169,
          name: "Extremely Hot",
          uuid: "da0cbde856c69107a88f4bca5ffc2b6440c23388",
          image: "https://r.gamesstatic.com/games/da0cbde856c69107a88f4bca5ffc2b6440c23388.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 553,
          name: "Eye of Atum",
          uuid: "9ae40767110096790ab2efd8653b101640861417",
          image: "https://r.gamesstatic.com/games/9ae40767110096790ab2efd8653b101640861417.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 776,
          name: "Eye of Gold",
          uuid: "fee4de8a629d44a6804bb19bbc5fe94e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/fee4de8a629d44a6804bb19bbc5fe94e.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 219,
          name: "Eye Of The Dragon",
          uuid: "ac3498a5452b757853d62b01b8e6d68be4455841",
          image: "https://r.gamesstatic.com/games/ac3498a5452b757853d62b01b8e6d68be4455841.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 272,
          name: "Fairy Queen",
          uuid: "6def6e210d014022f9b1ce1749018a79fa939f30",
          image: "https://r.gamesstatic.com/games/6def6e210d014022f9b1ce1749018a79fa939f30.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 311,
          name: "Fast Money",
          uuid: "bab9afc0b15a42d1103a066b8857496490a1ed8f",
          image: "https://r.gamesstatic.com/games/bab9afc0b15a42d1103a066b8857496490a1ed8f.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 109,
          name: "Fat Banker",
          uuid: "3d5fb543aacc4bd0aa413aaa12edcb69",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/3d5fb543aacc4bd0aa413aaa12edcb69.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 662,
          name: "Fat Rabbit",
          uuid: "e6f5cf3e37ed46e48249aa0d99a8582c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/e6f5cf3e37ed46e48249aa0d99a8582c.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 95,
          name: "Fat Santa",
          uuid: "69ecc1ed18f04189ad4411cb18d0b74a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/69ecc1ed18f04189ad4411cb18d0b74a.png",
          type: "slots",
          provider: "PushGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 273,
          name: "Feather Frenzy",
          uuid: "e8b316448ec3af8a370767ea23e7ca6b64065746",
          image: "https://r.gamesstatic.com/games/e8b316448ec3af8a370767ea23e7ca6b64065746.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 663,
          name: "Fire Hopper",
          uuid: "226f6eeb54914aff99e3bd2fcc0dda13",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/226f6eeb54914aff99e3bd2fcc0dda13.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 894,
          name: "Fire Portals",
          uuid: "0536cbc5c12844f78be0ebbeefbb00fb",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/0536cbc5c12844f78be0ebbeefbb00fb.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 895,
          name: "Fire Stampede",
          uuid: "4d191e2fcc574f7abb5656bda8135360",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/4d191e2fcc574f7abb5656bda8135360.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 148,
          name: "Fire Strike",
          uuid: "d036789d5012ddfc9f86e939f8a35b7b8c05d5db",
          image: "https://r.gamesstatic.com/games/d036789d5012ddfc9f86e939f8a35b7b8c05d5db.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 149,
          name: "Fire Strike 2",
          uuid: "0b43fed5d7b6c2870ad111cec74a724fe6d28fab",
          image: "https://r.gamesstatic.com/games/0b43fed5d7b6c2870ad111cec74a724fe6d28fab.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 396,
          name: "Fire Toad",
          uuid: "9d7ab11e2f984c309f36d6a175e6d135",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/9d7ab11e2f984c309f36d6a175e6d135.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 575,
          name: "Firebird Spirit",
          uuid: "ebe193be25b3543542210db4242b60b169482b58",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ebe193be25b3543542210db4242b60b169482b58.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 896,
          name: "Fish Eye",
          uuid: "b7dd657c33b545b2a89095871adc37d1",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/b7dd657c33b545b2a89095871adc37d1.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 777,
          name: "Fish Reef",
          uuid: "73b9bce08e1b4d29b34bb61ed9296dfd",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/73b9bce08e1b4d29b34bb61ed9296dfd.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 485,
          name: "Five Star",
          uuid: "5da3c19280210a757773da89e90d0626d84e1439",
          image: "https://r.gamesstatic.com/games/5da3c19280210a757773da89e90d0626d84e1439.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 484,
          name: "Five Star Power Reels",
          uuid: "a0aa2c6796f98e7aad61d0c6e4936b7a3c9e1b36",
          image: "https://r.gamesstatic.com/games/a0aa2c6796f98e7aad61d0c6e4936b7a3c9e1b36.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 274,
          name: "Flame Dancer",
          uuid: "c1eaf15b47e08c3374b0ac3305437d696b7aed24",
          image: "https://r.gamesstatic.com/games/c1eaf15b47e08c3374b0ac3305437d696b7aed24.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 191,
          name: "Flamenco Roses",
          uuid: "a24fd9ee18b51ac0c4e609c6210c182867e70c74",
          image: "https://r.gamesstatic.com/games/a24fd9ee18b51ac0c4e609c6210c182867e70c74.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 312,
          name: "Flaming Dice",
          uuid: "7685870df82b86c2d81af22f6f9e8efc193846df",
          image: "https://r.gamesstatic.com/games/7685870df82b86c2d81af22f6f9e8efc193846df.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 482,
          name: "Flaming Fox",
          uuid: "1f78a08ca60ab11581069bfaae7578d92b08fb20",
          image: "https://r.gamesstatic.com/games/1f78a08ca60ab11581069bfaae7578d92b08fb20.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 170,
          name: "Flaming Hot",
          uuid: "da4ef84aec53b2372673ec1d236e95b1c9d66c00",
          image: "https://r.gamesstatic.com/games/da4ef84aec53b2372673ec1d236e95b1c9d66c00.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 295,
          name: "Flowers",
          uuid: "3f3c89ac6f5ff1972e951d17fe6576272cfbf412",
          image: "https://r.gamesstatic.com/games/3f3c89ac6f5ff1972e951d17fe6576272cfbf412.jpg",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 866,
          name: "FlowersChristmas",
          uuid: "57cd6f8da3c044e550029408b0303ec4b0c1e109",
          image: "https://r.gamesstatic.com/games/57cd6f8da3c044e550029408b0303ec4b0c1e109.jpg",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 848,
          name: "Football",
          uuid: "fe9764853d4b22493988205be0ab00267ef7a95c",
          image: "https://r.gamesstatic.com/games/fe9764853d4b22493988205be0ab00267ef7a95c.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 323,
          name: "Forest Band",
          uuid: "4f896819a52a6b946a1021be648f77efaa759e47",
          image: "https://r.gamesstatic.com/games/4f896819a52a6b946a1021be648f77efaa759e47.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 143,
          name: "Forever 7`s",
          uuid: "d2664beec6ab3644aec3a4c38d9699cc62e52dc7",
          image: "https://r.gamesstatic.com/games/d2664beec6ab3644aec3a4c38d9699cc62e52dc7.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 596,
          name: "Forge of Fortunes",
          uuid: "ba1a28d6b8374d38b8a31b4f57d8d182",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/ba1a28d6b8374d38b8a31b4f57d8d182.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 897,
          name: "Forge of Olympus",
          uuid: "d24df11eaf11484c86b9da8584a4463e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/d24df11eaf11484c86b9da8584a4463e.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 481,
          name: "Fortune Charm",
          uuid: "bb72bee89dd53b0cd31f92750d9ad93c432e745a",
          image: "https://r.gamesstatic.com/games/bb72bee89dd53b0cd31f92750d9ad93c432e745a.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 480,
          name: "Fortune Fest",
          uuid: "1019cc60d36e74a5061b19ff55eeb6fdfa071ea1",
          image: "https://r.gamesstatic.com/games/1019cc60d36e74a5061b19ff55eeb6fdfa071ea1.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 585,
          name: "Fortune Girl",
          uuid: "218ec377832941d4927627963009d948",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/218ec377832941d4927627963009d948.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 479,
          name: "Fortune House",
          uuid: "d574b68a8ea94b8880080c72a4d7d88772bc2532",
          image: "https://r.gamesstatic.com/games/d574b68a8ea94b8880080c72a4d7d88772bc2532.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 478,
          name: "Fortune House Power Reels",
          uuid: "074a405973345a7a5cfd1a8d80d0d4d0d1b45ca7",
          image: "https://r.gamesstatic.com/games/074a405973345a7a5cfd1a8d80d0d4d0d1b45ca7.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 745,
          name: "Fortune Mouse",
          uuid: "19a513973985443e82e374fc37a6442e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/19a513973985443e82e374fc37a6442e.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 560,
          name: "Fortune of Giza",
          uuid: "61d22dda271310b6156da531d6a829d212cf48bc",
          image: "https://r.gamesstatic.com/games/61d22dda271310b6156da531d6a829d212cf48bc.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 746,
          name: "Fortune OX",
          uuid: "4fb87b09f27e49eeb29406bed87ceb3c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/4fb87b09f27e49eeb29406bed87ceb3c.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 747,
          name: "Fortune Rabbit",
          uuid: "8ea3603dd8e84a8fa5b7590592beb25d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/8ea3603dd8e84a8fa5b7590592beb25d.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 374,
          name: "Fortune Rangers Slot",
          uuid: "b2b1ef251c4cd2d2a808a41c9ef87222a41552e9",
          image: "https://r.gamesstatic.com/games/b2b1ef251c4cd2d2a808a41c9ef87222a41552e9.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 595,
          name: "Fortune Rewind",
          uuid: "2aea49471f2d4a2e8c8faeed39c0bb96",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/2aea49471f2d4a2e8c8faeed39c0bb96.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 171,
          name: "Fortune Spells",
          uuid: "0ba2caf261e43f5bc1b53ec67b82e5df3585e72d",
          image: "https://r.gamesstatic.com/games/0ba2caf261e43f5bc1b53ec67b82e5df3585e72d.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 748,
          name: "Fortune Tiger",
          uuid: "cad776b0bd2a44d4bd1a7fff6390c145",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/cad776b0bd2a44d4bd1a7fff6390c145.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 898,
          name: "Fortunes of Aztec",
          uuid: "a6793e65fba44306b4ea224481274d7c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/a6793e65fba44306b4ea224481274d7c.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 397,
          name: "Free Reelin Joker",
          uuid: "f9ac44fa3ba14496bd21a21daef37f53",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/f9ac44fa3ba14496bd21a21daef37f53.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 360,
          name: "Freibier",
          uuid: "76c6904515550dab7623cea6a841f4a83f0cf73e",
          image: "https://r.gamesstatic.com/games/76c6904515550dab7623cea6a841f4a83f0cf73e.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 679,
          name: "Frozen Fruits - Chase'N’Win",
          uuid: "da89debbc5664884b5216538964f8f31",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/da89debbc5664884b5216538964f8f31.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 899,
          name: "Frozen Tropics",
          uuid: "da1525557cd34d95828e0434e3c52a54",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/da1525557cd34d95828e0434e3c52a54.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 483,
          name: "Fruit Blox",
          uuid: "a674f8eccab8d18cb05f852ed92fb117adb60d5d",
          image: "https://r.gamesstatic.com/games/a674f8eccab8d18cb05f852ed92fb117adb60d5d.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 749,
          name: "Fruit Candy",
          uuid: "7e2d78f1fc6946f88ecd42aa91ddce53",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/7e2d78f1fc6946f88ecd42aa91ddce53.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 209,
          name: "Fruit Cocktail",
          uuid: "67894f8b7229ac27f2fecb00af254e75681d3090",
          image: "https://r.gamesstatic.com/games/67894f8b7229ac27f2fecb00af254e75681d3090.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 210,
          name: "Fruit Cocktail 2",
          uuid: "4283be45875772f6eaace1d2eeaca1c3d8a115f2",
          image: "https://r.gamesstatic.com/games/4283be45875772f6eaace1d2eeaca1c3d8a115f2.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 218,
          name: "Fruit Farm",
          uuid: "878a47d8b0e01b0cf066fc804e805d101b3a3ad0",
          image: "https://r.gamesstatic.com/games/878a47d8b0e01b0cf066fc804e805d101b3a3ad0.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 523,
          name: "Fruit Party",
          uuid: "8d4207b1c2343e3bdfecd094304d1c31dadd0aa1",
          image: "https://r.gamesstatic.com/games/8d4207b1c2343e3bdfecd094304d1c31dadd0aa1.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 524,
          name: "Fruit Party 2",
          uuid: "63f4d19d464970cc97865e5664b720eb9470e2bf",
          image: "https://r.gamesstatic.com/games/63f4d19d464970cc97865e5664b720eb9470e2bf.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 375,
          name: "Fruit Shop MegaWays™",
          uuid: "371f56c12bacbf0cbf4d2811804bb957f6aec829",
          image: "https://r.gamesstatic.com/games/371f56c12bacbf0cbf4d2811804bb957f6aec829.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 619,
          name: "Fruit Strike",
          uuid: "9cb77f35d0f3433dbd8c95dfe485f639",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/9cb77f35d0f3433dbd8c95dfe485f639.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 275,
          name: "Fruitilicious",
          uuid: "a438e8881205921c610ccd447860b9411ce5dd06",
          image: "https://r.gamesstatic.com/games/a438e8881205921c610ccd447860b9411ce5dd06.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 155,
          name: "Fruits Collection - 40 Lines",
          uuid: "a41d1f0e5db0449bbbd1adf934aeb374",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/a41d1f0e5db0449bbbd1adf934aeb374.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 156,
          name: "Fruits Collection – 10 Lines",
          uuid: "a17042cfdd874cf5a968ab52567a2e3f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/a17042cfdd874cf5a968ab52567a2e3f.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 313,
          name: "Fruits Kingdom",
          uuid: "47855bcbf79e72fad5044f0faf6778c93b3cd443",
          image: "https://r.gamesstatic.com/games/47855bcbf79e72fad5044f0faf6778c93b3cd443.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 157,
          name: "Fruits On Ice Collection 10 Lines",
          uuid: "37883cce24c445b4b5c7ee2269569874",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/37883cce24c445b4b5c7ee2269569874.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 158,
          name: "Fruits On Ice Collection 20 Lines",
          uuid: "793efe01e71546bd87beb55b50e577eb",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/793efe01e71546bd87beb55b50e577eb.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 159,
          name: "Fruits On Ice Collection 30 Lines",
          uuid: "f8956b332ac5435c80ef75c63e41ca9f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/f8956b332ac5435c80ef75c63e41ca9f.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 849,
          name: "Fruitshop",
          uuid: "91df143ad01728eda6bfd793ad210b4d62b5fd44",
          image: "https://r.gamesstatic.com/games/91df143ad01728eda6bfd793ad210b4d62b5fd44.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 543,
          name: "Funk Master",
          uuid: "cf7dcc20c1c2fe688a0655390f5330baed6e4f46",
          image: "https://r.gamesstatic.com/games/cf7dcc20c1c2fe688a0655390f5330baed6e4f46.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 258,
          name: "Funky Chicken",
          uuid: "d7ef401a68fa90918e82270202bab5335f37446b",
          image: "https://r.gamesstatic.com/games/d7ef401a68fa90918e82270202bab5335f37446b.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 750,
          name: "Galactic Gems",
          uuid: "a25bde10cfe84329a7abd08ecbfc6194",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/a25bde10cfe84329a7abd08ecbfc6194.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 172,
          name: "Game Of Luck",
          uuid: "c84adf9f4f4193392c937b7aa60bdc526e1a72c8",
          image: "https://r.gamesstatic.com/games/c84adf9f4f4193392c937b7aa60bdc526e1a72c8.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 751,
          name: "Ganesha Fortune",
          uuid: "5ee626ce7a3b4db7af77bd53e9a98ee1",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/5ee626ce7a3b4db7af77bd53e9a98ee1.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 752,
          name: "Ganesha Gold",
          uuid: "c872956d300444229ccf85a1318f5329",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/c872956d300444229ccf85a1318f5329.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 263,
          name: "Gangster Paradise",
          uuid: "e05ed1a4b03ea15dab5c4b4b7c50329167c443e6",
          image: "https://r.gamesstatic.com/games/e05ed1a4b03ea15dab5c4b4b7c50329167c443e6.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 211,
          name: "Garage",
          uuid: "8e78dbfa1338a1a00d71aeeb660ac3bdc2a648cd",
          image: "https://r.gamesstatic.com/games/8e78dbfa1338a1a00d71aeeb660ac3bdc2a648cd.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 753,
          name: "Garuda Gems",
          uuid: "f45246e4c38a4052a2f09a576a657572",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/f45246e4c38a4052a2f09a576a657572.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 525,
          name: "Gates of Olympus",
          uuid: "3ba395633bc5b400031cb8a10d25e792b02f54c4",
          image: "https://r.gamesstatic.com/games/3ba395633bc5b400031cb8a10d25e792b02f54c4.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 900,
          name: "Gates of Olympus 1000",
          uuid: "a545a0864ad148048332ad5a10756b9b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/a545a0864ad148048332ad5a10756b9b.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 594,
          name: "Gates of Troy",
          uuid: "c19e2346290745b4a55ef921143c5f53",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/c19e2346290745b4a55ef921143c5f53.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 901,
          name: "Gears of Horus",
          uuid: "cd9478c60b8941559e1e456445782c62",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/cd9478c60b8941559e1e456445782c62.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 145,
          name: "Gems Bonanza",
          uuid: "769fe95d39a67f9fec5c09ea75333263dad7bc8b",
          image: "https://r.gamesstatic.com/games/769fe95d39a67f9fec5c09ea75333263dad7bc8b.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 477,
          name: "Gems Gone Wild",
          uuid: "1a11b1a6dd552c1bbd1dd130b45bbad2c5355309",
          image: "https://r.gamesstatic.com/games/1a11b1a6dd552c1bbd1dd130b45bbad2c5355309.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 476,
          name: "Gemtastic",
          uuid: "51d7792c5cac30c05a5d5aa7f5078b143b665e8e",
          image: "https://r.gamesstatic.com/games/51d7792c5cac30c05a5d5aa7f5078b143b665e8e.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 664,
          name: "Generous Jack",
          uuid: "a3d7a11fe5b04dab9bc78d8e729dc391",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/a3d7a11fe5b04dab9bc78d8e729dc391.png",
          type: "slots",
          provider: "PushGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 108,
          name: "Giga Jar",
          uuid: "7ff30fdc4f7648778a6a4d49c9929554",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/7ff30fdc4f7648778a6a4d49c9929554.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 507,
          name: "GigantoonZ",
          uuid: "560adb95fa951d24ad759195943d3cfdcb5059de",
          image: "https://r.gamesstatic.com/games/560adb95fa951d24ad759195943d3cfdcb5059de.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 13,
          name: "Gnome",
          uuid: "7fb98fe62dd21b5961a8370911d2a465207f0ca4",
          image: "https://r.gamesstatic.com/games/7fb98fe62dd21b5961a8370911d2a465207f0ca4.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 117,
          name: "Goal",
          uuid: "3106fab94e9a4c47b80a33bafb5da452",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/3106fab94e9a4c47b80a33bafb5da452.png",
          type: "minigame",
          provider: "Spribe",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 693,
          name: "Goblin Heist Powernudge",
          uuid: "10f33bf687954a84a79351a0a4173d8d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/10f33bf687954a84a79351a0a4173d8d.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 696,
          name: "God of Wealth",
          uuid: "3d85b9d8b13c5cfad8f32dadff8e3a7e4b8ad86e",
          image: "https://r.gamesstatic.com/games/3d85b9d8b13c5cfad8f32dadff8e3a7e4b8ad86e.jpg",
          type: "slots",
          provider: "Qilin",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 475,
          name: "God of Wealth",
          uuid: "c1fea7ff487523ecde117c71d09d9acddc2266cc",
          image: "https://r.gamesstatic.com/games/c1fea7ff487523ecde117c71d09d9acddc2266cc.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 778,
          name: "Goddess of Egypt",
          uuid: "49f0af51a6044fbb9d1b0a041df4c4ec",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/49f0af51a6044fbb9d1b0a041df4c4ec.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 384,
          name: "Gods Of Gold",
          uuid: "7cba4c3a061b323371c027d2d7e5fa93124fa586",
          image: "https://r.gamesstatic.com/games/7cba4c3a061b323371c027d2d7e5fa93124fa586.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 779,
          name: "Gold Express",
          uuid: "95f07b28084e4cf7a5f8cd476a5bca00",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/95f07b28084e4cf7a5f8cd476a5bca00.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 710,
          name: "Gold Lemon",
          uuid: "27768c12f3d875a29b60c2210124ebd54b1bac10",
          image: "https://r.gamesstatic.com/games/27768c12f3d875a29b60c2210124ebd54b1bac10.png",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 902,
          name: "Gold Oasis",
          uuid: "36e22995587e4a84882e4d9e450f7560",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/36e22995587e4a84882e4d9e450f7560.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 653,
          name: "Gold Rush",
          uuid: "0c77c7c0822545f58a3e6bd1241f0eac",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/0c77c7c0822545f58a3e6bd1241f0eac.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 586,
          name: "Golden Book",
          uuid: "260e1429f2aa46f8b858f0c974252bf9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/260e1429f2aa46f8b858f0c974252bf9.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 197,
          name: "Golden Cobras Deluxe",
          uuid: "93a4a61cb9a167268cf7b0af284271e51b537d80",
          image: "https://r.gamesstatic.com/games/93a4a61cb9a167268cf7b0af284271e51b537d80.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 474,
          name: "Golden Cryptex",
          uuid: "b7d7e2d1afa2c52504994fb1c1f58efca1aa0182",
          image: "https://r.gamesstatic.com/games/b7d7e2d1afa2c52504994fb1c1f58efca1aa0182.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 620,
          name: "Golden Gods",
          uuid: "bdb5290fe2d04c5596079430afb5ea71",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/bdb5290fe2d04c5596079430afb5ea71.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 473,
          name: "Golden Leprechaun MegaWays",
          uuid: "7a980914ee6315a0bd45cb54aef14eac4ba100d5",
          image: "https://r.gamesstatic.com/games/7a980914ee6315a0bd45cb54aef14eac4ba100d5.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 246,
          name: "Golden Mane",
          uuid: "7760219279f110c991da1d19e99ac100fedaa97b",
          image: "https://r.gamesstatic.com/games/7760219279f110c991da1d19e99ac100fedaa97b.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 398,
          name: "Golden Osiris",
          uuid: "7686451ba5944ad1b07988931f23ee37",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/7686451ba5944ad1b07988931f23ee37.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 296,
          name: "GoldenGrimoire",
          uuid: "d27c8b6f7a1d8c5acefa17af77f5cce7628e0825",
          image: "https://r.gamesstatic.com/games/d27c8b6f7a1d8c5acefa17af77f5cce7628e0825.jpg",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 544,
          name: "Gonzo's Gold",
          uuid: "55ec8049aebe3a87c6c6c23f2185012559a43351",
          image: "https://r.gamesstatic.com/games/55ec8049aebe3a87c6c6c23f2185012559a43351.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 846,
          name: "Gonzo's Quest",
          uuid: "0499284ddffa3a1793aab8e45cf40a01a358ecbe",
          image: "https://r.gamesstatic.com/games/0499284ddffa3a1793aab8e45cf40a01a358ecbe.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 472,
          name: "Gonzo's Quest Megaways",
          uuid: "9d63bb9b0987ba95855edd574732743c80fbdca6",
          image: "https://r.gamesstatic.com/games/9d63bb9b0987ba95855edd574732743c80fbdca6.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 903,
          name: "Good Luck & Good Fortune",
          uuid: "7948a5ebd665498ab595d0af4087868d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/7948a5ebd665498ab595d0af4087868d.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 276,
          name: "Gorilla",
          uuid: "718e380cb7a107e5a7ac6f47a6e31353cffa322e",
          image: "https://r.gamesstatic.com/games/718e380cb7a107e5a7ac6f47a6e31353cffa322e.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 376,
          name: "Gorilla Kingdom Slot",
          uuid: "154467102ed7c52659b23e1c6fa7db62d1dc68f0",
          image: "https://r.gamesstatic.com/games/154467102ed7c52659b23e1c6fa7db62d1dc68f0.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 904,
          name: "Gorilla Mayhem",
          uuid: "3c27f500bcd24988939ccc585b401c39",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/3c27f500bcd24988939ccc585b401c39.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 780,
          name: "Grab the Gold!",
          uuid: "720d9f49d05f451fb1436f1bef029f75",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/720d9f49d05f451fb1436f1bef029f75.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 173,
          name: "Grace Of Cleopatra",
          uuid: "5f4ae0014f65355f365631d95372c7b59edce76d",
          image: "https://r.gamesstatic.com/games/5f4ae0014f65355f365631d95372c7b59edce76d.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 587,
          name: "Grand Casanova",
          uuid: "b3313c6105c7431bbd333f97df4a8595",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/b3313c6105c7431bbd333f97df4a8595.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 277,
          name: "Grand slam casino",
          uuid: "c192d9d933653e0e4a52cd96a321d18964adbd95",
          image: "https://r.gamesstatic.com/games/c192d9d933653e0e4a52cd96a321d18964adbd95.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 471,
          name: "Grand Wheel",
          uuid: "51c660869d7c8e6802d43b246ce596a6b54eb0ec",
          image: "https://r.gamesstatic.com/games/51c660869d7c8e6802d43b246ce596a6b54eb0ec.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 905,
          name: "Gravity Bonanza",
          uuid: "6577425a3ba24b6a857daefa5dadc1da",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/6577425a3ba24b6a857daefa5dadc1da.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 697,
          name: "Great Fortune",
          uuid: "4489d091702ff038b78f9364ef242c503f33668c",
          image: "https://r.gamesstatic.com/games/4489d091702ff038b78f9364ef242c503f33668c.jpg",
          type: "slots",
          provider: "Qilin",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 781,
          name: "Great Panda",
          uuid: "34e4ef639f064fccb56bbf29de934d83",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/34e4ef639f064fccb56bbf29de934d83.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 526,
          name: "Great Rhino",
          uuid: "6be30d5b1a3f6d5f155f0afbef05701a33fd6788",
          image: "https://r.gamesstatic.com/games/6be30d5b1a3f6d5f155f0afbef05701a33fd6788.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 527,
          name: "Great Rhino Deluxe",
          uuid: "8ce2e82e236bb16e8d4d25dac08962c51c090162",
          image: "https://r.gamesstatic.com/games/8ce2e82e236bb16e8d4d25dac08962c51c090162.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 650,
          name: "Great Rhino Megaways",
          uuid: "28807dfa489f48e488827f33df27b81e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/28807dfa489f48e488827f33df27b81e.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 782,
          name: "Green Chilli",
          uuid: "e02057240c5c49feb41f5f7f2798217a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/e02057240c5c49feb41f5f7f2798217a.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 399,
          name: "Green Knight",
          uuid: "015da5cdeed94af79362ef129e009d32",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/015da5cdeed94af79362ef129e009d32.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 754,
          name: "Guardians of Ice & Fire",
          uuid: "0642135cffd94a56b7fee21de59802da",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/0642135cffd94a56b7fee21de59802da.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 324,
          name: "Halloween",
          uuid: "f60cae6da86eff6cbfe9a58a77b3990e48d552a5",
          image: "https://r.gamesstatic.com/games/f60cae6da86eff6cbfe9a58a77b3990e48d552a5.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 470,
          name: "Hammer Gods",
          uuid: "d40edc6b81309732b81fa2a24c451795bc345c8d",
          image: "https://r.gamesstatic.com/games/d40edc6b81309732b81fa2a24c451795bc345c8d.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 755,
          name: "Hawaiian Tiki",
          uuid: "17b50683aefd46e9b508ad34d10a5e79",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/17b50683aefd46e9b508ad34d10a5e79.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 756,
          name: "Heist Stakes",
          uuid: "300630120c3a4292a7b1f03c05939c06",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/300630120c3a4292a7b1f03c05939c06.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 621,
          name: "Helios’ Fury",
          uuid: "72be0f1e569c4569b75b50d59e8b0213",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/72be0f1e569c4569b75b50d59e8b0213.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 704,
          name: "hellboy",
          uuid: "dfab0e4de0281dcac9525783c5cf527d02ad31e1",
          image: "https://r.gamesstatic.com/games/dfab0e4de0281dcac9525783c5cf527d02ad31e1.jpeg",
          type: "slots",
          provider: "BrandGames",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 622,
          name: "Hex",
          uuid: "9dab000cb4ca4e618d441fb2b4d13648",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/9dab000cb4ca4e618d441fb2b4d13648.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 116,
          name: "Hilo",
          uuid: "d7d7988f2e434cbc87b70ca79505f490",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/d7d7988f2e434cbc87b70ca79505f490.png",
          type: "minigame",
          provider: "Spribe",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 783,
          name: "Hit more Gold!",
          uuid: "81828bcb3f8545039f16e90258356797",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/81828bcb3f8545039f16e90258356797.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 784,
          name: "Hit the Gold!",
          uuid: "9482f0a219e84a4c8b2c556742963583",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/9482f0a219e84a4c8b2c556742963583.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 151,
          name: "Hot 7",
          uuid: "ab23ba46f3fc4a2e9bf01875e73dc121",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/ab23ba46f3fc4a2e9bf01875e73dc121.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 126,
          name: "Hot Chance",
          uuid: "aecc90d2cd1fd3abc84327434d4a8de6710c6863",
          image: "https://r.gamesstatic.com/games/aecc90d2cd1fd3abc84327434d4a8de6710c6863.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 289,
          name: "Hot Cubes",
          uuid: "d2b4bc63ea9a882a56defab624d99ed7f1d54497",
          image: "https://r.gamesstatic.com/games/d2b4bc63ea9a882a56defab624d99ed7f1d54497.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 612,
          name: "Hot Diamonds",
          uuid: "40a4617180664b6ead7e1269dec70522",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/40a4617180664b6ead7e1269dec70522.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 561,
          name: "Hot Fiesta",
          uuid: "2f080174c15f8feeffb634363fbd843805bee43a",
          image: "https://r.gamesstatic.com/games/2f080174c15f8feeffb634363fbd843805bee43a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 154,
          name: "Hot Fruits 20",
          uuid: "fb4c8efd3e2a479987f4eea743d2accb",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/fb4c8efd3e2a479987f4eea743d2accb.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 469,
          name: "Hot Hot Chilli Pot",
          uuid: "b63cbae78c9fdc55f780718c9bbcceaedb3a8d8e",
          image: "https://r.gamesstatic.com/games/b63cbae78c9fdc55f780718c9bbcceaedb3a8d8e.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 611,
          name: "Hot Neon",
          uuid: "846f30021e20422e83cd093df6d0a775",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/846f30021e20422e83cd093df6d0a775.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 610,
          name: "Hot Scatter",
          uuid: "5898a1393d6c46228fe450ff3674d975",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/5898a1393d6c46228fe450ff3674d975.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 153,
          name: "Hot Star",
          uuid: "dba888f18231473c8670372fa28970ff",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/dba888f18231473c8670372fa28970ff.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 146,
          name: "Hot to burn",
          uuid: "c476b203157aefae5746cf6a90858297b9dae036",
          image: "https://r.gamesstatic.com/games/c476b203157aefae5746cf6a90858297b9dae036.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 115,
          name: "Hotline",
          uuid: "fc27d12f03ed45fa847e12fc3f86b2f4",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/fc27d12f03ed45fa847e12fc3f86b2f4.png",
          type: "minigame",
          provider: "Spribe",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 691,
          name: "Hotline 2",
          uuid: "e3ac761d3fdb421eb9dc932dcaddba4f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/e3ac761d3fdb421eb9dc932dcaddba4f.jpg",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 142,
          name: "Ice Joker",
          uuid: "902978c176a54c1d9105b5fb7ef6dcc2",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/902978c176a54c1d9105b5fb7ef6dcc2.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 623,
          name: "Ignite the Night",
          uuid: "67ea623756d94b3fa915b464325c3f62",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/67ea623756d94b3fa915b464325c3f62.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 468,
          name: "Imperial Palace",
          uuid: "9824322c03b25598470f700411919fc040757c7e",
          image: "https://r.gamesstatic.com/games/9824322c03b25598470f700411919fc040757c7e.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 325,
          name: "Imperial Wars",
          uuid: "d79e7d0c286f02540590cb1e8a65b87786b53a57",
          image: "https://r.gamesstatic.com/games/d79e7d0c286f02540590cb1e8a65b87786b53a57.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 174,
          name: "Inca Gold II",
          uuid: "aa2e5406c83e798f6d32514d9204cf3fea1e224c",
          image: "https://r.gamesstatic.com/games/aa2e5406c83e798f6d32514d9204cf3fea1e224c.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 906,
          name: "Infective Wild",
          uuid: "0c031eb6b55d4b809d9ffe9cd1cd6bfb",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/0c031eb6b55d4b809d9ffe9cd1cd6bfb.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 718,
          name: "Insect Tricks",
          uuid: "a6f8f8366244fa937d9934ee6a4c247a010539ce",
          image: "https://r.gamesstatic.com/games/a6f8f8366244fa937d9934ee6a4c247a010539ce.jpeg",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 851,
          name: "InvisibleMan",
          uuid: "759fa434b908738b1245e88e041943137a39a918",
          image: "https://r.gamesstatic.com/games/759fa434b908738b1245e88e041943137a39a918.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 247,
          name: "Irish Eyes 2",
          uuid: "0ac20ee3cf3bceb10b5722afae4308f67b96ee7f",
          image: "https://r.gamesstatic.com/games/0ac20ee3cf3bceb10b5722afae4308f67b96ee7f.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 680,
          name: "Irish Treasures",
          uuid: "49648994a5804d26b030cc1d2437adf6",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/49648994a5804d26b030cc1d2437adf6.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 11,
          name: "Island",
          uuid: "28f65ac960d93b7d1ee570072cd8352938e079d6",
          image: "https://r.gamesstatic.com/games/28f65ac960d93b7d1ee570072cd8352938e079d6.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 12,
          name: "Island 2",
          uuid: "213898fa027de5e27ba7d2767370e5d707c69390",
          image: "https://r.gamesstatic.com/games/213898fa027de5e27ba7d2767370e5d707c69390.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 278,
          name: "Island Heat",
          uuid: "652917d9d13501b4be58b352a95776ff15508e51",
          image: "https://r.gamesstatic.com/games/652917d9d13501b4be58b352a95776ff15508e51.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 624,
          name: "It's Time",
          uuid: "63e0e97492584083aa092a493d3c30b9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/63e0e97492584083aa092a493d3c30b9.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 545,
          name: "Jack and the Beanstalk",
          uuid: "29570d3a9a56102c22a1071261b1fed3f2a929ad",
          image: "https://r.gamesstatic.com/games/29570d3a9a56102c22a1071261b1fed3f2a929ad.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 757,
          name: "Jack Frost’s Winter",
          uuid: "4e933341da424b989f8eb58fb991cd8a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/4e933341da424b989f8eb58fb991cd8a.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 297,
          name: "JackHammer2",
          uuid: "c9eda62a56e83a59f3c8757fd39db45707367fac",
          image: "https://r.gamesstatic.com/games/c9eda62a56e83a59f3c8757fd39db45707367fac.jpg",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 467,
          name: "Jackpot Quest",
          uuid: "f01f52a048fe2b2977690829d84ea04d57a2c71e",
          image: "https://r.gamesstatic.com/games/f01f52a048fe2b2977690829d84ea04d57a2c71e.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 338,
          name: "Jacks Or Better",
          uuid: "c792300ffcbc4789e1f9e45a5deaa7127bb60780",
          image:
            "https://r.gamesstatic.com/games/a9e556675295b7c72362861203812dd3/Greentube/c792300ffcbc4789e1f9e45a5deaa7127bb60780.png",
          type: "poker",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 466,
          name: "Jade Charms",
          uuid: "c5f8b679280a8736405edec522e489413a56b151",
          image: "https://r.gamesstatic.com/games/c5f8b679280a8736405edec522e489413a56b151.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 248,
          name: "Jade Idol",
          uuid: "6101616f3967418f97c17f896e1ffb9ac5165c25",
          image: "https://r.gamesstatic.com/games/6101616f3967418f97c17f896e1ffb9ac5165c25.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 107,
          name: "Jammin' Jars",
          uuid: "8f79688b6d454c87b2bc10fd2ddb6d61",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/8f79688b6d454c87b2bc10fd2ddb6d61.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 465,
          name: "Jester Spins",
          uuid: "2184a9a052437ad423ee19c3313ae0e0bc01f914",
          image: "https://r.gamesstatic.com/games/2184a9a052437ad423ee19c3313ae0e0bc01f914.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 758,
          name: "Jewels of Prosperity",
          uuid: "9fcb4fc950c94c1da9a9227d7211a14a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9fcb4fc950c94c1da9a9227d7211a14a.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 103,
          name: "Jingle Bells",
          uuid: "aa529e705a93fbf047339e9f1e8f59fa854d386c",
          image: "https://r.gamesstatic.com/games/aa529e705a93fbf047339e9f1e8f59fa854d386c.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 102,
          name: "Jingle Ways Megaways",
          uuid: "78134d9d9b37ae65fcd17986b27b42c0823dcbc1",
          image: "https://r.gamesstatic.com/games/78134d9d9b37ae65fcd17986b27b42c0823dcbc1.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 464,
          name: "Joe Exotic",
          uuid: "3dcdf835ebd8f14d40d5ab43177268f4f7fca7f1",
          image: "https://r.gamesstatic.com/games/3dcdf835ebd8f14d40d5ab43177268f4f7fca7f1.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 528,
          name: "John Hunter and the Book of Tut",
          uuid: "debf0104a0d66e8e7cfb8467591c5229959eea82",
          image: "https://r.gamesstatic.com/games/debf0104a0d66e8e7cfb8467591c5229959eea82.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 529,
          name: "John Hunter and the Mayan Gods",
          uuid: "eddbcc2dd93d69b929b3da2e737b047df2819b7c",
          image: "https://r.gamesstatic.com/games/eddbcc2dd93d69b929b3da2e737b047df2819b7c.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 530,
          name: "John Hunter and the Quest for Bermuda Riches",
          uuid: "50c6ab0d960e33d611ab0702ed8f57d55fa09a21",
          image: "https://r.gamesstatic.com/games/50c6ab0d960e33d611ab0702ed8f57d55fa09a21.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 531,
          name: "John Hunter and the Tomb of the Scarab Queen",
          uuid: "6886a56a927cc159315554d37f24b77182417ecb",
          image: "https://r.gamesstatic.com/games/6886a56a927cc159315554d37f24b77182417ecb.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 707,
          name: "John Wick",
          uuid: "9fa9657dc6370b29a020c4a49aba67a71e858f01",
          image: "https://r.gamesstatic.com/games/9fa9657dc6370b29a020c4a49aba67a71e858f01.jpg",
          type: "slots",
          provider: "BrandGames",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 665,
          name: "Joker Troupe",
          uuid: "e17bb4f8a3d44ec8bfca929bca4483a7",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/e17bb4f8a3d44ec8bfca929bca4483a7.png",
          type: "slots",
          provider: "PushGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 140,
          name: "Joker Wild",
          uuid: "c703de366afbcbc44102b9ebe8449f817c7d5f54",
          image:
            "https://r.gamesstatic.com/games/a9e556675295b7c72362861203812dd3/Greentube/c703de366afbcbc44102b9ebe8449f817c7d5f54.png",
          type: "poker",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 160,
          name: "Joker Win",
          uuid: "fce45762911f4339a3a02e21263e8efa",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/fce45762911f4339a3a02e21263e8efa.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 852,
          name: "JokerPro",
          uuid: "8567430c4612116202cebc9389fa128cb869b1a5",
          image: "https://r.gamesstatic.com/games/8567430c4612116202cebc9389fa128cb869b1a5.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 698,
          name: "Journey to the West",
          uuid: "71ecf69521e34d45803c9f1cbb2cce41741599bc",
          image: "https://r.gamesstatic.com/games/71ecf69521e34d45803c9f1cbb2cce41741599bc.jpg",
          type: "slots",
          provider: "Qilin",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 532,
          name: "Juicy Fruits",
          uuid: "a5251ca34afcd986423581afe47203db9a468144",
          image: "https://r.gamesstatic.com/games/a5251ca34afcd986423581afe47203db9a468144.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 907,
          name: "Juicy Fruits Multihold",
          uuid: "bb2eef2a40c547bba7e62a3644001c0d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/bb2eef2a40c547bba7e62a3644001c0d.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 759,
          name: "Jungle Delight",
          uuid: "d601cadc9d714455880d78beb1022512",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/d601cadc9d714455880d78beb1022512.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 760,
          name: "Jurassic Kingdom",
          uuid: "59195295bb364b02bbe83b50e77896c9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/59195295bb364b02bbe83b50e77896c9.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 358,
          name: "Kangaroo Land",
          uuid: "bad3457d9ea55fe97e15e3d7762bbf60b4d502d4",
          image: "https://r.gamesstatic.com/games/bad3457d9ea55fe97e15e3d7762bbf60b4d502d4.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 175,
          name: "Kashmir Gold",
          uuid: "5c1b18fcb1d1bb62fe222416ec53f00fcf33037a",
          image: "https://r.gamesstatic.com/games/5c1b18fcb1d1bb62fe222416ec53f00fcf33037a.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 10,
          name: "Keks",
          uuid: "602f21fa5d9f51beaccf663edfd1ded3afb1d6fd",
          image: "https://r.gamesstatic.com/games/602f21fa5d9f51beaccf663edfd1ded3afb1d6fd.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 114,
          name: "Keno",
          uuid: "edeeec0277ad491ebd842faf5e6d51e5",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/edeeec0277ad491ebd842faf5e6d51e5.png",
          type: "minigame",
          provider: "Spribe",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 198,
          name: "King of Cards",
          uuid: "a5420d5302c9cca5cecae053732fba2c45cc5d1c",
          image: "https://r.gamesstatic.com/games/a5420d5302c9cca5cecae053732fba2c45cc5d1c.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 340,
          name: "King of Slots",
          uuid: "d8007bb29490cb22174faf6fc1449feb0d60ed6a",
          image: "https://r.gamesstatic.com/games/d8007bb29490cb22174faf6fc1449feb0d60ed6a.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 361,
          name: "Kingdom Of Legends",
          uuid: "dc468941f45a700c930c83d1f08c943cdbaf0c4b",
          image: "https://r.gamesstatic.com/games/dc468941f45a700c930c83d1f08c943cdbaf0c4b.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 625,
          name: "Kluster Krystals Megaclusters",
          uuid: "0e5b03d86f9e4ed883f37c0761f10a7d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/0e5b03d86f9e4ed883f37c0761f10a7d.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 546,
          name: "Knight Rider",
          uuid: "3b8635ab90abe7b6fe2889e9689b3d1fe9acf840",
          image: "https://r.gamesstatic.com/games/3b8635ab90abe7b6fe2889e9689b3d1fe9acf840.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 547,
          name: "Koi Princess",
          uuid: "19c8bd3579ebae450b0a65e35cccdb9ce8de1873",
          image: "https://r.gamesstatic.com/games/19c8bd3579ebae450b0a65e35cccdb9ce8de1873.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 463,
          name: "Laser Fruit",
          uuid: "3d0519ee71e77eb65b917046024c60e49e137eac",
          image: "https://r.gamesstatic.com/games/3d0519ee71e77eb65b917046024c60e49e137eac.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 400,
          name: "Last Sundown",
          uuid: "bbef4c612cac4a7eaa62ded123118eaf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/bbef4c612cac4a7eaa62ded123118eaf.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 410,
          name: "Legacy of Dead",
          uuid: "d2bac0b22b019348fc141ab3550545d67f613dd4",
          image: "https://r.gamesstatic.com/games/d2bac0b22b019348fc141ab3550545d67f613dd4.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 414,
          name: "Legacy of Egypt",
          uuid: "aa48fffeb8091805f913714a317fc399b1aa0be1",
          image: "https://r.gamesstatic.com/games/aa48fffeb8091805f913714a317fc399b1aa0be1.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 461,
          name: "Legend of Athena",
          uuid: "83a404ff950e80a23a6e5ba412bb1f1815c9442a",
          image: "https://r.gamesstatic.com/games/83a404ff950e80a23a6e5ba412bb1f1815c9442a.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 401,
          name: "Legend of the Ice Dragon",
          uuid: "434bd9a8051e4117ab288d408595f16c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/434bd9a8051e4117ab288d408595f16c.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 462,
          name: "Legendary Excalibur",
          uuid: "857f2b7646c42f85c5378a6e4741fffbb4b2cfdd",
          image: "https://r.gamesstatic.com/games/857f2b7646c42f85c5378a6e4741fffbb4b2cfdd.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 761,
          name: "Legendary Monkey King",
          uuid: "e40af84971df40dea0d737c69cfc6ce7",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/e40af84971df40dea0d737c69cfc6ce7.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 199,
          name: "Leonardo's Code",
          uuid: "ca9191e4169adf8b732e31eb2a5105f775a15d61",
          image: "https://r.gamesstatic.com/games/ca9191e4169adf8b732e31eb2a5105f775a15d61.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 762,
          name: "Leprechaun Riches",
          uuid: "da49fff4398642ccbc6d4f37fc76ec98",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/da49fff4398642ccbc6d4f37fc76ec98.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 593,
          name: "Leprechaun's Vault",
          uuid: "16420a1d31564fbd893f0863da29f7f1",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/16420a1d31564fbd893f0863da29f7f1.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 123,
          name: "Let It Burn",
          uuid: "901ac0d989bb4eca847daae2fb705fdd",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/901ac0d989bb4eca847daae2fb705fdd.png",
          type: "slots",
          provider: "NetEnt",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 636,
          name: "Let's Get Ready To Rumble",
          uuid: "721ff6045f364ec988224d8be07d2e22",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/721ff6045f364ec988224d8be07d2e22.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 847,
          name: "Lights (Fireflies)",
          uuid: "59129b91932a21e503d90f295cd1c706bb95963a",
          image: "https://r.gamesstatic.com/games/59129b91932a21e503d90f295cd1c706bb95963a.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 460,
          name: "Lion Dance",
          uuid: "ff3b2b771d6731dead14a38f6a025f9ef60fb997",
          image: "https://r.gamesstatic.com/games/ff3b2b771d6731dead14a38f6a025f9ef60fb997.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 562,
          name: "Little Gem",
          uuid: "ff64df146aa527066d1757dd95464de790237adc",
          image: "https://r.gamesstatic.com/games/ff64df146aa527066d1757dd95464de790237adc.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 908,
          name: "Lobster Bob's Crazy Crab Shack",
          uuid: "9759d670d36443f6844123d338b0ccaf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/9759d670d36443f6844123d338b0ccaf.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 711,
          name: "Local Pub",
          uuid: "eae2c13d1696a5c41060187518330f83fa506729",
          image: "https://r.gamesstatic.com/games/eae2c13d1696a5c41060187518330f83fa506729.png",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 909,
          name: "Loki's Riches",
          uuid: "75629204d5c14c0787d06972562258ea",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/75629204d5c14c0787d06972562258ea.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 402,
          name: "Lokis fortune",
          uuid: "9ac2dc7b9abe4b6fbcf385168b90024a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/9ac2dc7b9abe4b6fbcf385168b90024a.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 385,
          name: "Long Pao",
          uuid: "ff46ca203f1e5811e2671ea7467c731a11b581a2",
          image: "https://r.gamesstatic.com/games/ff46ca203f1e5811e2671ea7467c731a11b581a2.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 786,
          name: "Lord Fortune 2",
          uuid: "e5723f3e74d743a99b04b83f90880fab",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/e5723f3e74d743a99b04b83f90880fab.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 787,
          name: "Lotus Charm",
          uuid: "6da706c8e18842cc9cffc6b11f69786c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/6da706c8e18842cc9cffc6b11f69786c.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 511,
          name: "Love Joker",
          uuid: "13af573fed76b2c9e457856f27223117267fc836",
          image: "https://r.gamesstatic.com/games/13af573fed76b2c9e457856f27223117267fc836.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 609,
          name: "Lovely Lady",
          uuid: "f47557c650e74187a150c7986cf9497c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/f47557c650e74187a150c7986cf9497c.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 608,
          name: "Lovely Lady Deluxe",
          uuid: "cc8dd9bdd83645b6acb83eddc38b4ccb",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/cc8dd9bdd83645b6acb83eddc38b4ccb.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 607,
          name: "Lovely Lady X-Mas",
          uuid: "b0087b4be1e74b4aa5011373bfc6ba07",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/b0087b4be1e74b4aa5011373bfc6ba07.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 176,
          name: "Lucky And Wild",
          uuid: "7e4c39ef49a6cef85d5923d8e3c79f60c4bb2c57",
          image: "https://r.gamesstatic.com/games/7e4c39ef49a6cef85d5923d8e3c79f60c4bb2c57.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 326,
          name: "Lucky Buzz",
          uuid: "207562877584826520fd1146812d1fe62b6e0b06",
          image: "https://r.gamesstatic.com/games/207562877584826520fd1146812d1fe62b6e0b06.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 763,
          name: "Lucky Clover Lady",
          uuid: "eb5cbd364ebe4608aba080060f8bf090",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/eb5cbd364ebe4608aba080060f8bf090.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 606,
          name: "Lucky Coin",
          uuid: "e687bcf6766f40dbb549576e8ac3a5b9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/e687bcf6766f40dbb549576e8ac3a5b9.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 563,
          name: "Lucky Dragons",
          uuid: "f121b1fef6b65370f3db6d2ab5926a3de3826e68",
          image: "https://r.gamesstatic.com/games/f121b1fef6b65370f3db6d2ab5926a3de3826e68.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 712,
          name: "Lucky Drink",
          uuid: "68d99f33ec5c498bf734f0717727e2c1c8343c2e",
          image: "https://r.gamesstatic.com/games/68d99f33ec5c498bf734f0717727e2c1c8343c2e.png",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 459,
          name: "Lucky Easter",
          uuid: "0bed2ef8512c3ecc415bd736cf5b4905b6b7b442",
          image: "https://r.gamesstatic.com/games/0bed2ef8512c3ecc415bd736cf5b4905b6b7b442.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 699,
          name: "Lucky Eights",
          uuid: "87c0f1ea5f47e3b6e378d2c0937d7cbfe8dff580",
          image: "https://r.gamesstatic.com/games/87c0f1ea5f47e3b6e378d2c0937d7cbfe8dff580.jpg",
          type: "slots",
          provider: "Qilin",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 9,
          name: "Lucky Haunter",
          uuid: "fe70f27c749c0e9e9e8eb61255838a3a836a8837",
          image: "https://r.gamesstatic.com/games/fe70f27c749c0e9e9e8eb61255838a3a836a8837.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 128,
          name: "Lucky Hot",
          uuid: "e02350c55cd748b7429ccbd5cacedbac3fcde573",
          image: "https://r.gamesstatic.com/games/e02350c55cd748b7429ccbd5cacedbac3fcde573.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 681,
          name: "Lucky Jack - Book Of Rebirth",
          uuid: "cb6692b4970145abba91e117bb73d52c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/cb6692b4970145abba91e117bb73d52c.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 682,
          name: "Lucky Jack - Book Of Rebirth - Egyptian Darkness",
          uuid: "ac9882f4e4ce4efaa907a54e76576029",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/ac9882f4e4ce4efaa907a54e76576029.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 152,
          name: "Lucky Joker 5",
          uuid: "d11f4094e06f4a7787cb7d3e8acca3f6",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/d11f4094e06f4a7787cb7d3e8acca3f6.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 362,
          name: "Lucky Lady's Charm Deluxe 10",
          uuid: "204cafd239d57808f86dc7d0f23454201ecb7fa0",
          image: "https://r.gamesstatic.com/games/204cafd239d57808f86dc7d0f23454201ecb7fa0.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 215,
          name: "Lucky Ladys Charm Deluxe 6",
          uuid: "855ef3995e10ace8f26081a525ef0aa931b8aa7f",
          image: "https://r.gamesstatic.com/games/855ef3995e10ace8f26081a525ef0aa931b8aa7f.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 683,
          name: "Lucky Mr. Patrick",
          uuid: "ba53581367354278a70087324b96744e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/ba53581367354278a70087324b96744e.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 764,
          name: "Lucky Neko",
          uuid: "8fcd7247e5834f7bab7ac395ca670df8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/8fcd7247e5834f7bab7ac395ca670df8.png",
          type: "slots",
          provider: "PGSoft",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 765,
          name: "Lucky Piggy",
          uuid: "9843c63a026548fe81202a423c989b7a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9843c63a026548fe81202a423c989b7a.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 458,
          name: "Lucky Wizard",
          uuid: "229d983a6cf13463ebea4617ca98fd8b50afa33c",
          image: "https://r.gamesstatic.com/games/229d983a6cf13463ebea4617ca98fd8b50afa33c.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 234,
          name: "Lucky88",
          uuid: "10a0621fc5c1d449cd211d421864ef74764bfdcf",
          image: "https://r.gamesstatic.com/games/10a0621fc5c1d449cd211d421864ef74764bfdcf.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 249,
          name: "Mad Mad Monkey",
          uuid: "5ae4085e58231d4bdbfc95ddb38b89696f670335",
          image: "https://r.gamesstatic.com/games/5ae4085e58231d4bdbfc95ddb38b89696f670335.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 533,
          name: "Madame Destiny",
          uuid: "055cd949fa8ba7ece5048b1649097fc851777e98",
          image: "https://r.gamesstatic.com/games/055cd949fa8ba7ece5048b1649097fc851777e98.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 649,
          name: "Madame Destiny Megaways",
          uuid: "6c376d1b1f1644ffb87dcf370e506185",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/6c376d1b1f1644ffb87dcf370e506185.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 314,
          name: "Magellan",
          uuid: "90ee5885ad68249c366f28846de33b137d4dda12",
          image: "https://r.gamesstatic.com/games/90ee5885ad68249c366f28846de33b137d4dda12.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 130,
          name: "Magic 27",
          uuid: "b50287eb8bc3ed3e1a4959b2a56660fa049db369",
          image: "https://r.gamesstatic.com/games/b50287eb8bc3ed3e1a4959b2a56660fa049db369.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 291,
          name: "Magic 81",
          uuid: "07836517d898a76c9a28723a35b75e21232dde8c",
          image: "https://r.gamesstatic.com/games/07836517d898a76c9a28723a35b75e21232dde8c.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 788,
          name: "Magic Apple",
          uuid: "0ea917de9d33420c8a5280a5fec21407",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0ea917de9d33420c8a5280a5fec21407.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 789,
          name: "Magic Apple 2",
          uuid: "a233d2cabf5441359403fdd6cdf2179a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a233d2cabf5441359403fdd6cdf2179a.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 790,
          name: "Magic Ball: Multichance",
          uuid: "79cd0ec7ef68493ca8e0c89185ad98e1",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/79cd0ec7ef68493ca8e0c89185ad98e1.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 605,
          name: "Magic Idol",
          uuid: "1f6c126d84fe4db2ac06b2ad27a04668",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/1f6c126d84fe4db2ac06b2ad27a04668.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 853,
          name: "MagicPortals",
          uuid: "ebcf19889400fe52b527f5976c65306833dabca6",
          image: "https://r.gamesstatic.com/games/ebcf19889400fe52b527f5976c65306833dabca6.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 766,
          name: "Mahjong Ways",
          uuid: "5549efbf91194285bd797697598408df",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/5549efbf91194285bd797697598408df.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 767,
          name: "Mahjong Ways 2",
          uuid: "3a0e35201a734a0693459ac86c0345b8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3a0e35201a734a0693459ac86c0345b8.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 177,
          name: "Majestic Forest",
          uuid: "1d5d025238a79b1345bf464727cc851761e200b7",
          image: "https://r.gamesstatic.com/games/1d5d025238a79b1345bf464727cc851761e200b7.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 684,
          name: "Majestic King",
          uuid: "76b340a73ee246f598ee6c62efdcdf75",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/76b340a73ee246f598ee6c62efdcdf75.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 768,
          name: "Majestic Treasures",
          uuid: "63f502b9b90d45b684483238fcddcec9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/63f502b9b90d45b684483238fcddcec9.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 685,
          name: "MajesticKing-EE",
          uuid: "7088f598a7d942eb84693299e775ce41",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/7088f598a7d942eb84693299e775ce41.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 769,
          name: "Mask Carnival",
          uuid: "783dcc2a55c44ef5a9e860f28f9302cf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/783dcc2a55c44ef5a9e860f28f9302cf.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 457,
          name: "Masquerade",
          uuid: "6185b94163bf2089de54457a28f3fef992a56e71",
          image: "https://r.gamesstatic.com/games/6185b94163bf2089de54457a28f3fef992a56e71.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 791,
          name: "Maya Sun",
          uuid: "ebef270054b34ad296c1ca90ac226f26",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/ebef270054b34ad296c1ca90ac226f26.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 456,
          name: "Mayan Gods",
          uuid: "d08c8492919d302cf098d5fe4ffb98094270480a",
          image: "https://r.gamesstatic.com/games/d08c8492919d302cf098d5fe4ffb98094270480a.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 290,
          name: "Mayan Moons",
          uuid: "f2be833f1d1f67b0a8b7685111a7789d06ef8272",
          image: "https://r.gamesstatic.com/games/f2be833f1d1f67b0a8b7685111a7789d06ef8272.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 327,
          name: "Mayan Spirit",
          uuid: "994e588297d89a702e0e48bb4bfa7cdcdfeca92c",
          image: "https://r.gamesstatic.com/games/994e588297d89a702e0e48bb4bfa7cdcdfeca92c.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 592,
          name: "Mega Don",
          uuid: "7455c011e6aa4f9db3d17f287ed2a3c3",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/7455c011e6aa4f9db3d17f287ed2a3c3.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 455,
          name: "Mega Dragon",
          uuid: "25602411cb433c2e616c79e591493be311be460b",
          image: "https://r.gamesstatic.com/games/25602411cb433c2e616c79e591493be311be460b.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 626,
          name: "Mega Flip",
          uuid: "a6dadcf7769f444cb30a7761b44df908",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/a6dadcf7769f444cb30a7761b44df908.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 627,
          name: "Mega Mine",
          uuid: "927e01042b7c435495be16cfb14bb87e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/927e01042b7c435495be16cfb14bb87e.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 591,
          name: "Merlin's Grimoire",
          uuid: "b19b46ae41954b9092afb1ec4523f10b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/b19b46ae41954b9092afb1ec4523f10b.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 770,
          name: "Mermaid Riches",
          uuid: "ce13d02c1ef947fdae234e8477bc800a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/ce13d02c1ef947fdae234e8477bc800a.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 604,
          name: "Merry Fruits",
          uuid: "48f5f2d8c63e42acbb1385b66bcd3420",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/48f5f2d8c63e42acbb1385b66bcd3420.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 771,
          name: "Midas Fortune",
          uuid: "11a34d3d465947dfb121ebb20553f6f1",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/11a34d3d465947dfb121ebb20553f6f1.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 534,
          name: "Might of Ra",
          uuid: "55b8ec248e1fd3cd4da9dc559b7c6f27a107ba42",
          image: "https://r.gamesstatic.com/games/55b8ec248e1fd3cd4da9dc559b7c6f27a107ba42.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 910,
          name: "Mighty Munching Melons",
          uuid: "b80a786888db4abba066b2108291e9e8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/b80a786888db4abba066b2108291e9e8.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 113,
          name: "Mines",
          uuid: "c96f30074d9d43fc95661919c1358eb5",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/c96f30074d9d43fc95661919c1358eb5.png",
          type: "minigame",
          provider: "Spribe",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 112,
          name: "Mini Roulette",
          uuid: "100d5a3dec664588a46fbbf0e1498037",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/100d5a3dec664588a46fbbf0e1498037.png",
          type: "minigame",
          provider: "Spribe",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 235,
          name: "Miss Kitty",
          uuid: "1cc312dcc5a9450e8085c2673e8e0a894377f73c",
          image: "https://r.gamesstatic.com/games/1cc312dcc5a9450e8085c2673e8e0a894377f73c.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 92,
          name: "MMA Champions",
          uuid: "e3564caca7804448a586858c777fa012",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/e3564caca7804448a586858c777fa012.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 911,
          name: "Mochimon",
          uuid: "4cb19c8d4f9343b09174467b41fba96e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/4cb19c8d4f9343b09174467b41fba96e.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 628,
          name: "Money Cart",
          uuid: "c8356dd1372d4aedac18bf27e5d3a46f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/c8356dd1372d4aedac18bf27e5d3a46f.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 629,
          name: "Money Cart 2",
          uuid: "c2faa738edc44f59ac0ce2e7aeec81ec",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/c2faa738edc44f59ac0ce2e7aeec81ec.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 630,
          name: "Money Train",
          uuid: "04e9446563e3410a98609fc114315269",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/04e9446563e3410a98609fc114315269.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 631,
          name: "Money Train 2",
          uuid: "6c6cb9b7fee74f74820b266c7ccef11f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/6c6cb9b7fee74f74820b266c7ccef11f.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 655,
          name: "Money Train 3",
          uuid: "a1e37ef5801d499492a7b1634e8412ae",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/a1e37ef5801d499492a7b1634e8412ae.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 792,
          name: "Moon Sisters",
          uuid: "93dec0dbde874bfbbcd1815eafb5aaec",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/93dec0dbde874bfbbcd1815eafb5aaec.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 328,
          name: "More Dice And Roll",
          uuid: "d7f608a8192ecd1fb7737183e6b563cf71d5dd46",
          image: "https://r.gamesstatic.com/games/d7f608a8192ecd1fb7737183e6b563cf71d5dd46.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 329,
          name: "More Lucky And Wild",
          uuid: "093c61f8a8c80a7391596c70bedec4d396eb27b1",
          image: "https://r.gamesstatic.com/games/093c61f8a8c80a7391596c70bedec4d396eb27b1.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 793,
          name: "More Magic Apple",
          uuid: "8aa413b95f3748d38adf8fcf53abd138",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/8aa413b95f3748d38adf8fcf53abd138.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 590,
          name: "Mötley Crüe",
          uuid: "5f82b572c0b349f0828e49722cb8d43b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/5f82b572c0b349f0828e49722cb8d43b.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 377,
          name: "Motörhead Slot",
          uuid: "46bbca26aadd93fd8214e6220a192c4d0f6ca335",
          image: "https://r.gamesstatic.com/games/46bbca26aadd93fd8214e6220a192c4d0f6ca335.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 589,
          name: "Mount M",
          uuid: "39b8d944ab1441f288cf6ee45a6a915f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/39b8d944ab1441f288cf6ee45a6a915f.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 632,
          name: "Multiplier Odyssey",
          uuid: "3194feb7da184a1c98d0798943ff5b77",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/3194feb7da184a1c98d0798943ff5b77.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 854,
          name: "MultiplierMayhem",
          uuid: "894ab987985e59a7d43c2369078853525a9752f5",
          image: "https://r.gamesstatic.com/games/894ab987985e59a7d43c2369078853525a9752f5.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 220,
          name: "Music Island",
          uuid: "8b2d41554724614c0a5fbc6e9359e37b56786bac",
          image: "https://r.gamesstatic.com/games/8b2d41554724614c0a5fbc6e9359e37b56786bac.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 91,
          name: "Mustang Gold",
          uuid: "1f75f4de43b13aed07b0c37809d11f65415c73eb",
          image: "https://r.gamesstatic.com/games/1f75f4de43b13aed07b0c37809d11f65415c73eb.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 912,
          name: "Mustang Trail",
          uuid: "5424494cc51f487da58d4b13ea949751",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/5424494cc51f487da58d4b13ea949751.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 106,
          name: "Mystery Mission - To The Moon",
          uuid: "aede91876cdf46da8cf519c2c6cebf86",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/aede91876cdf46da8cf519c2c6cebf86.png",
          type: "slots",
          provider: "PushGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 454,
          name: "Mystery Reels Power Reels",
          uuid: "ecaabedb1c6a5ec5a2057b21ee3c04b57c606e1a",
          image: "https://r.gamesstatic.com/games/ecaabedb1c6a5ec5a2057b21ee3c04b57c606e1a.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 633,
          name: "Mystery Stacks",
          uuid: "70e39b24264447f2bb151dd77a92976a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/70e39b24264447f2bb151dd77a92976a.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 453,
          name: "Mystic Wheel",
          uuid: "1c0e9f629fc02657c6a5a162906955e11de79f3e",
          image: "https://r.gamesstatic.com/games/1c0e9f629fc02657c6a5a162906955e11de79f3e.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 772,
          name: "Mystical Spirits",
          uuid: "9a144aed9c0d499ab3d4e4470e0b5232",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9a144aed9c0d499ab3d4e4470e0b5232.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 855,
          name: "MythicMaiden",
          uuid: "66de752d3adef77f197e21ab0d0ca66dcbea168c",
          image: "https://r.gamesstatic.com/games/66de752d3adef77f197e21ab0d0ca66dcbea168c.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 178,
          name: "Neon Dice",
          uuid: "3f79d49d701890c3a06108ac5d52daf5c371abdb",
          image: "https://r.gamesstatic.com/games/3f79d49d701890c3a06108ac5d52daf5c371abdb.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 452,
          name: "Neon Links",
          uuid: "5c15e09bf8d0e3a67b17c555d3665d69f51ecd34",
          image: "https://r.gamesstatic.com/games/5c15e09bf8d0e3a67b17c555d3665d69f51ecd34.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 856,
          name: "NeonStaxx",
          uuid: "e9340825b1f43d974916384ba3cba08bacb25099",
          image: "https://r.gamesstatic.com/games/e9340825b1f43d974916384ba3cba08bacb25099.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 104,
          name: "New Year Riches",
          uuid: "8cb87863c3c1482ca681c762407aa12d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/8cb87863c3c1482ca681c762407aa12d.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 122,
          name: "NFT Megaways",
          uuid: "d1a8dcec130abf14a67c3f7569013c22a2958203",
          image: "https://r.gamesstatic.com/games/d1a8dcec130abf14a67c3f7569013c22a2958203.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 666,
          name: "Nightfall",
          uuid: "1dfcd03957064c2897e0c7078acb77e8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/1dfcd03957064c2897e0c7078acb77e8.png",
          type: "slots",
          provider: "PushGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 686,
          name: "Nights of Egypt",
          uuid: "03063440613a447fa80dc7f70157eeb3",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/03063440613a447fa80dc7f70157eeb3.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 687,
          name: "Nights Of Magic",
          uuid: "2200561696544d7ebcaee94ac34d152c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/2200561696544d7ebcaee94ac34d152c.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 913,
          name: "Nile Fortune",
          uuid: "3c32c142fb04488fa9bb5649d4b21928",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/3c32c142fb04488fa9bb5649d4b21928.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 451,
          name: "Ninja Ways",
          uuid: "5cd38539241ac3af6eb72c52deaa4a5aec1f998c",
          image: "https://r.gamesstatic.com/games/5cd38539241ac3af6eb72c52deaa4a5aec1f998c.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 99,
          name: "North Guardians",
          uuid: "26e4f16e5ad998a88ad9d40de626502ccbcf0368",
          image: "https://r.gamesstatic.com/games/26e4f16e5ad998a88ad9d40de626502ccbcf0368.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 713,
          name: "Ocean Bed",
          uuid: "721b303bd384441c023879f7f6f341163b82f362",
          image: "https://r.gamesstatic.com/games/721b303bd384441c023879f7f6f341163b82f362.png",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 450,
          name: "Ocean Fortune",
          uuid: "e32ab6e48906a5e420692c4f434cd35d3f236659",
          image: "https://r.gamesstatic.com/games/e32ab6e48906a5e420692c4f434cd35d3f236659.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 179,
          name: "Oil Company II",
          uuid: "d0b33e3d5389b7ecb29aa7afaeb6dd40934719ec",
          image: "https://r.gamesstatic.com/games/d0b33e3d5389b7ecb29aa7afaeb6dd40934719ec.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 794,
          name: "Olympian Gods",
          uuid: "2ec3ef2eb7714d128bd4fdd0541b3413",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/2ec3ef2eb7714d128bd4fdd0541b3413.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 180,
          name: "Olympus Glory",
          uuid: "a25911da1766ab7af55ecdc8c9d1ac8fbce94731",
          image: "https://r.gamesstatic.com/games/a25911da1766ab7af55ecdc8c9d1ac8fbce94731.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 773,
          name: "Opera Dynasty",
          uuid: "3e63c46572e3440a93ad8a0258bf78ed",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3e63c46572e3440a93ad8a0258bf78ed.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 279,
          name: "Orca",
          uuid: "f4c29b1f9edb129c0a8bde626d6d412d64a9d1f2",
          image: "https://r.gamesstatic.com/games/f4c29b1f9edb129c0a8bde626d6d412d64a9d1f2.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 774,
          name: "Oriental Prosperity",
          uuid: "42b6bb37e5de459d9050cb4066b580f0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/42b6bb37e5de459d9050cb4066b580f0.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 700,
          name: "Panda's Treasures",
          uuid: "028447689689c5aca9af2a13268b3070cbb3a938",
          image: "https://r.gamesstatic.com/games/028447689689c5aca9af2a13268b3070cbb3a938.jpg",
          type: "slots",
          provider: "Qilin",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 378,
          name: "Parthenon: Quest for Immortality™",
          uuid: "43cc1437dadf8f13049bc00eb39e00263c92628c",
          image: "https://r.gamesstatic.com/games/43cc1437dadf8f13049bc00eb39e00263c92628c.jpeg",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 449,
          name: "Path of Destiny",
          uuid: "27a9a0d2d1b4ca181364e1bd284b2cd29f71c98d",
          image: "https://r.gamesstatic.com/games/27a9a0d2d1b4ca181364e1bd284b2cd29f71c98d.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 795,
          name: "Pearl Diver",
          uuid: "af5337e3efd04aa4af4f6f5958ff293e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/af5337e3efd04aa4af4f6f5958ff293e.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 796,
          name: "Pearl Diver 2: Treasure Chest",
          uuid: "2c989ce267864817a21745adc7e01f3a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/2c989ce267864817a21745adc7e01f3a.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 448,
          name: "Persian Fortune",
          uuid: "92f0018e43d55d4d7c7c0e36a1234c708c5efbb1",
          image: "https://r.gamesstatic.com/games/92f0018e43d55d4d7c7c0e36a1234c708c5efbb1.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 192,
          name: "Pharaohs Ring",
          uuid: "708d86a2c129ce5aaef41eb3ef2d1f28abb89902",
          image: "https://r.gamesstatic.com/games/708d86a2c129ce5aaef41eb3ef2d1f28abb89902.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 714,
          name: "Pharaohs Scrolls",
          uuid: "9bc297e6645ca4e3972d9d2bebbec205a622945e",
          image: "https://r.gamesstatic.com/games/9bc297e6645ca4e3972d9d2bebbec205a622945e.png",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 281,
          name: "Pharaohs Tomb",
          uuid: "1ca1561250b80d6f96131e30e66362fa52561902",
          image: "https://r.gamesstatic.com/games/1ca1561250b80d6f96131e30e66362fa52561902.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 447,
          name: "Phoenix Fire Power Reels",
          uuid: "4d98ef363b360565d2345948d32bbeb34f9c8907",
          image: "https://r.gamesstatic.com/games/4d98ef363b360565d2345948d32bbeb34f9c8907.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 701,
          name: "Phoenix Fortune",
          uuid: "2e7e0389b2e8ef779828555344c027c1249ccfca",
          image: "https://r.gamesstatic.com/games/2e7e0389b2e8ef779828555344c027c1249ccfca.jpg",
          type: "slots",
          provider: "Qilin",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 510,
          name: "Phoenix Reborn",
          uuid: "fddc35098c572199d24c89bfa4c5e0ec088aa43f",
          image: "https://r.gamesstatic.com/games/fddc35098c572199d24c89bfa4c5e0ec088aa43f.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 719,
          name: "Phoenix Rises",
          uuid: "ef4a4ba465544465adf7a8ea75aad960",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/ef4a4ba465544465adf7a8ea75aad960.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 720,
          name: "Piggy Gold",
          uuid: "41704e8c86174999a0405dad23ebe6e0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/41704e8c86174999a0405dad23ebe6e0.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 446,
          name: "Piggy Riches Megaways",
          uuid: "441a38bd86d32d607892c00a6f07b3fd7dbe36c1",
          image: "https://r.gamesstatic.com/games/441a38bd86d32d607892c00a6f07b3fd7dbe36c1.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 857,
          name: "PiggyRiches",
          uuid: "76d855fdf3efa6f07ffbb7d703fb3ab7bafcdeb9",
          image: "https://r.gamesstatic.com/games/76d855fdf3efa6f07ffbb7d703fb3ab7bafcdeb9.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 7,
          name: "Pirate",
          uuid: "b0f20c5641b8fbf68aad9cf91054efc521aa422b",
          image: "https://r.gamesstatic.com/games/b0f20c5641b8fbf68aad9cf91054efc521aa422b.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 8,
          name: "Pirate 2",
          uuid: "1e0353efced20ac46e2820fc2d51a65188a9499a",
          image: "https://r.gamesstatic.com/games/1e0353efced20ac46e2820fc2d51a65188a9499a.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 654,
          name: "Pirate Gold Deluxe",
          uuid: "0f87879f74d746d29582b12c40c43fef",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/0f87879f74d746d29582b12c40c43fef.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 574,
          name: "Pirate Golden Age",
          uuid: "4134b53073b5b156a46895a00bf6ff859d7beb96",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/4134b53073b5b156a46895a00bf6ff859d7beb96.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 914,
          name: "PIZZA! PIZZA? PIZZA!",
          uuid: "87820c62d84e49b28df668566b9115f1",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/87820c62d84e49b28df668566b9115f1.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 250,
          name: "Platinum Pyramid",
          uuid: "67df5d912f5d1223daccf591bf5a35643307b159",
          image: "https://r.gamesstatic.com/games/67df5d912f5d1223daccf591bf5a35643307b159.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 200,
          name: "Plenty of Fruit 20",
          uuid: "beba5843acc38c1b22dc7f85c10f1da115b68cb9",
          image: "https://r.gamesstatic.com/games/beba5843acc38c1b22dc7f85c10f1da115b68cb9.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 213,
          name: "Plenty Of Fruit 20 Hot",
          uuid: "234ca50fc628e41ab75e56c01aaa31cb5ae9e8a3",
          image: "https://r.gamesstatic.com/games/234ca50fc628e41ab75e56c01aaa31cb5ae9e8a3.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 201,
          name: "Plenty of Fruit 40",
          uuid: "996b883906932df4577e247142749d1ae905ce31",
          image: "https://r.gamesstatic.com/games/996b883906932df4577e247142749d1ae905ce31.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 214,
          name: "Plenty Of Jewels 20 Hot",
          uuid: "de6a04e9d231c2ea6dba965a11bd8643e484160c",
          image: "https://r.gamesstatic.com/games/de6a04e9d231c2ea6dba965a11bd8643e484160c.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 212,
          name: "Plenty On Twenty II Hot",
          uuid: "0be71fab844eeff742c241a287af5d12fd50c34e",
          image: "https://r.gamesstatic.com/games/0be71fab844eeff742c241a287af5d12fd50c34e.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 634,
          name: "Plunderland",
          uuid: "e6b82c1f6051450f9e4ae59c956012be",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/e6b82c1f6051450f9e4ae59c956012be.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 915,
          name: "Pompeii Megareels Megaways™",
          uuid: "d0de2808e2ea4231a5ca833ca56f3bd3",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/d0de2808e2ea4231a5ca833ca56f3bd3.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 916,
          name: "Pot of Fortune",
          uuid: "c01b227444024be3ba8c854c5cbf1d68",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/c01b227444024be3ba8c854c5cbf1d68.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 251,
          name: "Potion Commotion",
          uuid: "9a8a33a4558d521e9ff25078733ed0297a1b3d5e",
          image: "https://r.gamesstatic.com/games/9a8a33a4558d521e9ff25078733ed0297a1b3d5e.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 193,
          name: "Power Stars",
          uuid: "4118ea3f37c8f11d60f314573860409cabf52d24",
          image: "https://r.gamesstatic.com/games/4118ea3f37c8f11d60f314573860409cabf52d24.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 715,
          name: "Princess of Swamp",
          uuid: "dcddfc1936e293b4618befb37dd479ba60d4ca30",
          image: "https://r.gamesstatic.com/games/dcddfc1936e293b4618befb37dd479ba60d4ca30.png",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 702,
          name: "Princess Xiaoming",
          uuid: "565270d3b987b8fd7bca93a387aaee00a490b701",
          image: "https://r.gamesstatic.com/games/565270d3b987b8fd7bca93a387aaee00a490b701.jpg",
          type: "slots",
          provider: "Qilin",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 721,
          name: "Prosperity Fortune Tree",
          uuid: "9ba5441a20074564a3f43e067830521a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9ba5441a20074564a3f43e067830521a.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 555,
          name: "Puebla Parade",
          uuid: "1fa91f004341613dc91cea456c2a4b015d79d617",
          image: "https://r.gamesstatic.com/games/1fa91f004341613dc91cea456c2a4b015d79d617.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 445,
          name: "Puss`n Boots",
          uuid: "2273fd5a37302d6ec9855accdaa7a2a7fcfcc982",
          image: "https://r.gamesstatic.com/games/2273fd5a37302d6ec9855accdaa7a2a7fcfcc982.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 569,
          name: "Pyramid",
          uuid: "4402bf8b37ae9b664655165a3e91c1e860c52e52",
          image: "https://r.gamesstatic.com/games/4402bf8b37ae9b664655165a3e91c1e860c52e52.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 564,
          name: "Pyramid King",
          uuid: "3b81c33df76f2d338a4186e39d90672e8410ccb1",
          image: "https://r.gamesstatic.com/games/3b81c33df76f2d338a4186e39d90672e8410ccb1.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 703,
          name: "Qilin",
          uuid: "eef296f42d2c29f415cd4cdfc6d8feebb89bfb3f",
          image: "https://r.gamesstatic.com/games/eef296f42d2c29f415cd4cdfc6d8feebb89bfb3f.jpg",
          type: "slots",
          provider: "Qilin",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 217,
          name: "Queen Cleopatra",
          uuid: "2fa92810c2e0849e722b92eec5e87112a6c84d83",
          image: "https://r.gamesstatic.com/games/2fa92810c2e0849e722b92eec5e87112a6c84d83.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 315,
          name: "Queen Of Rio",
          uuid: "b25dc9cbffbf30a1c033a208449f0eb9d8356595",
          image: "https://r.gamesstatic.com/games/b25dc9cbffbf30a1c033a208449f0eb9d8356595.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 236,
          name: "Queen of the Nile II",
          uuid: "e067abc4b1c266e510f5ca606cd1d6abe3c6fa5a",
          image: "https://r.gamesstatic.com/games/e067abc4b1c266e510f5ca606cd1d6abe3c6fa5a.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 797,
          name: "Queen of the Sun",
          uuid: "0e64cfd8cb444a099f83acc9ab56fad6",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0e64cfd8cb444a099f83acc9ab56fad6.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 722,
          name: "Raider Jane’s Crypt of Fortune",
          uuid: "71d0180ca7054f95bd8d9a5690df0deb",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/71d0180ca7054f95bd8d9a5690df0deb.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 444,
          name: "Rainbow Jackpots",
          uuid: "4b2aaef99ae67781d8d4f71edbdd7f0f6ac861fa",
          image: "https://r.gamesstatic.com/games/4b2aaef99ae67781d8d4f71edbdd7f0f6ac861fa.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 443,
          name: "Rainbow Jackpots Power Lines",
          uuid: "a8ed17436d2aa1e0822f4b06d50e37df09ed15be",
          image: "https://r.gamesstatic.com/games/a8ed17436d2aa1e0822f4b06d50e37df09ed15be.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 917,
          name: "Rainbow Reels",
          uuid: "05d7e8f8c40a4c74a89faa9ff639be6b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/05d7e8f8c40a4c74a89faa9ff639be6b.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 252,
          name: "Ramesses Riches",
          uuid: "bfd8149583e59a681a5b6a1f4a7b4c40e9262e4f",
          image: "https://r.gamesstatic.com/games/bfd8149583e59a681a5b6a1f4a7b4c40e9262e4f.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 635,
          name: "Ramses Revenge",
          uuid: "0369524ac5c04289acd95588766c3961",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/0369524ac5c04289acd95588766c3961.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 723,
          name: "Rave Party Fever",
          uuid: "9109d5a3811f4b4d9763a43177f128f1",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/9109d5a3811f4b4d9763a43177f128f1.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 403,
          name: "Reactoonz",
          uuid: "3e66bc6ea14f444baaab3396ce8a2949",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/3e66bc6ea14f444baaab3396ce8a2949.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 404,
          name: "Reactoonz 2",
          uuid: "3a1ae18ff0414f8f8bd85c4e5ad32934",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/3a1ae18ff0414f8f8bd85c4e5ad32934.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 442,
          name: "Reactor",
          uuid: "2a3ea8a99b3f41da87148b794911447fc136425d",
          image: "https://r.gamesstatic.com/games/2a3ea8a99b3f41da87148b794911447fc136425d.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 237,
          name: "Red Baron",
          uuid: "acc439151720e7b63e8b4d4c7900cda923e9236b",
          image: "https://r.gamesstatic.com/games/acc439151720e7b63e8b4d4c7900cda923e9236b.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 603,
          name: "Red Chilli",
          uuid: "98165c5c75f34c5fb1b16f677cbc4196",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/98165c5c75f34c5fb1b16f677cbc4196.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 918,
          name: "Red Hot Luck",
          uuid: "c957ece3afd344d9956e438c03f7cf5a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/c957ece3afd344d9956e438c03f7cf5a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 386,
          name: "Reef Raider",
          uuid: "bdebf02fdde74b0c57cea21df0375f8b5dd4ab08",
          image: "https://r.gamesstatic.com/games/bdebf02fdde74b0c57cea21df0375f8b5dd4ab08.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 202,
          name: "Reel King",
          uuid: "c3d7d347015d89fe66bad7ec9255f66f404f0898",
          image: "https://r.gamesstatic.com/games/c3d7d347015d89fe66bad7ec9255f66f404f0898.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 441,
          name: "Reel King Mega",
          uuid: "0e4f378b495b32060493bea2a7159ff1437a33ef",
          image: "https://r.gamesstatic.com/games/0e4f378b495b32060493bea2a7159ff1437a33ef.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 858,
          name: "ReelRush",
          uuid: "201df9871e2a47e8200b1bf6d8938519894b8d1c",
          image: "https://r.gamesstatic.com/games/201df9871e2a47e8200b1bf6d8938519894b8d1c.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 440,
          name: "Regal Beasts",
          uuid: "1c85ae48a4a87c1a992390eb7277bf0f13311047",
          image: "https://r.gamesstatic.com/games/1c85ae48a4a87c1a992390eb7277bf0f13311047.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 439,
          name: "Regal Streak",
          uuid: "aaf6b86c957033adc7b1996fcefcf1cdff5ef5a6",
          image: "https://r.gamesstatic.com/games/aaf6b86c957033adc7b1996fcefcf1cdff5ef5a6.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 3,
          name: "Resident",
          uuid: "d2332b8b4e9bbccaf91eff385adbcba319c2a7cd",
          image: "https://r.gamesstatic.com/games/d2332b8b4e9bbccaf91eff385adbcba319c2a7cd.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 357,
          name: "Retro Style",
          uuid: "b1f4d49f605cc91fca70684f333c048f0a0837ca",
          image: "https://r.gamesstatic.com/games/b1f4d49f605cc91fca70684f333c048f0a0837ca.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 667,
          name: "Retro Tapes",
          uuid: "7da35dd5e2ac49fa8accf199df8d3711",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/7da35dd5e2ac49fa8accf199df8d3711.png",
          type: "slots",
          provider: "PushGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 405,
          name: "Rich Wilde Wandering City",
          uuid: "5476e75049fe4e83a007bf5462449444",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/5476e75049fe4e83a007bf5462449444.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 316,
          name: "Rich World",
          uuid: "e20f38f529c44e5c2fd6e9eeb207559cb8e34ad9",
          image: "https://r.gamesstatic.com/games/e20f38f529c44e5c2fd6e9eeb207559cb8e34ad9.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 406,
          name: "Riddle Reels",
          uuid: "75653db408704ad9b390eefb0f71b2a7",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/75653db408704ad9b390eefb0f71b2a7.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 798,
          name: "Rio Gems",
          uuid: "83dfda22840b43d5a8ee9b862d6802af",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/83dfda22840b43d5a8ee9b862d6802af.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 919,
          name: "Ripe Rewards",
          uuid: "9eeb908a2929413a8769c4bcf2bd790a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/9eeb908a2929413a8769c4bcf2bd790a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 724,
          name: "Rise of Apollo",
          uuid: "7f99611b2d1346cca91bfb7e1751c2fa",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/7f99611b2d1346cca91bfb7e1751c2fa.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 407,
          name: "Rise of Dead",
          uuid: "349f8fa27b6444f09d509c0c630551d2",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/349f8fa27b6444f09d509c0c630551d2.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 554,
          name: "Rise of Gods: Reckoning",
          uuid: "243f4bc7f0c25e016317031e1967c58dc93492f2",
          image: "https://r.gamesstatic.com/games/243f4bc7f0c25e016317031e1967c58dc93492f2.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 411,
          name: "Rise of Merlin",
          uuid: "e20bb11288d7e9b0d0caee7719d9cf256334ad47",
          image: "https://r.gamesstatic.com/games/e20bb11288d7e9b0d0caee7719d9cf256334ad47.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 181,
          name: "Rise Of Ra",
          uuid: "557c4887ea2692bf47ed2bc7e4b80e586bdaee96",
          image: "https://r.gamesstatic.com/games/557c4887ea2692bf47ed2bc7e4b80e586bdaee96.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 203,
          name: "Roaring Wilds",
          uuid: "24c4e30e1fd05d824793011c4e7f6b881e276223",
          image: "https://r.gamesstatic.com/games/24c4e30e1fd05d824793011c4e7f6b881e276223.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 438,
          name: "Robin Hoods Wild Forest",
          uuid: "092347635031162ef2ff2794c791aa5951bafb58",
          image: "https://r.gamesstatic.com/games/092347635031162ef2ff2794c791aa5951bafb58.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 556,
          name: "Rocco Gallo",
          uuid: "2c797b5dbbe8d0170cd2b4ac0d3d2db759bdb2c2",
          image: "https://r.gamesstatic.com/games/2c797b5dbbe8d0170cd2b4ac0d3d2db759bdb2c2.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 4,
          name: "Rock Climber",
          uuid: "ce46d073f6267e47c55bebcab4d46ba905c775f7",
          image: "https://r.gamesstatic.com/games/ce46d073f6267e47c55bebcab4d46ba905c775f7.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 437,
          name: "Rocket Men",
          uuid: "d2fdd40071b683470b67059d244b699b8815f3ff",
          image: "https://r.gamesstatic.com/games/d2fdd40071b683470b67059d244b699b8815f3ff.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 330,
          name: "Rolling Dice",
          uuid: "aee813a8bbb53c40c09cf480a26b1c9913335def",
          image: "https://r.gamesstatic.com/games/aee813a8bbb53c40c09cf480a26b1c9913335def.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 725,
          name: "Rooster Rumble",
          uuid: "a518d5c1dccb41179f6777c2960adb1a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/a518d5c1dccb41179f6777c2960adb1a.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 331,
          name: "Route Of Mexico",
          uuid: "dc47a10cc53875f963668e13130f2b69c2292d8a",
          image: "https://r.gamesstatic.com/games/dc47a10cc53875f963668e13130f2b69c2292d8a.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 359,
          name: "Royal Dynasty",
          uuid: "17662a158dc9a7aa6803aff10616343c548c458f",
          image: "https://r.gamesstatic.com/games/17662a158dc9a7aa6803aff10616343c548c458f.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 317,
          name: "Royal Gardens",
          uuid: "f1a35b520e02078f9af29cbc07c40997a9194e12",
          image: "https://r.gamesstatic.com/games/f1a35b520e02078f9af29cbc07c40997a9194e12.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 283,
          name: "Royal Lotus",
          uuid: "2dc458a70c0b4c5205b93a739221f348f1ba3b2c",
          image: "https://r.gamesstatic.com/games/2dc458a70c0b4c5205b93a739221f348f1ba3b2c.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 182,
          name: "Royal Secrets",
          uuid: "ea45784e5406be037d47149fb4e48c53371a4853",
          image: "https://r.gamesstatic.com/games/ea45784e5406be037d47149fb4e48c53371a4853.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 602,
          name: "Royal Unicorn",
          uuid: "e9368eb68f8b4fcd855e68b7887ae5e9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/e9368eb68f8b4fcd855e68b7887ae5e9.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 637,
          name: "Sails of Fortune",
          uuid: "b1c76f6886f94519b85ec68963b3f287",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/b1c76f6886f94519b85ec68963b3f287.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 105,
          name: "Santa Vs Rudolf",
          uuid: "d9b76206e1846e42dc6a4e181877625e684fa875",
          image: "https://r.gamesstatic.com/games/d9b76206e1846e42dc6a4e181877625e684fa875.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 920,
          name: "Santa's Great Gifts",
          uuid: "a27f8dc17a26492c99de37f4b1450ddd",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/a27f8dc17a26492c99de37f4b1450ddd.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 97,
          name: "Santa's Stack",
          uuid: "54e8519ccc134f44ab21b0da85269686",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/54e8519ccc134f44ab21b0da85269686.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 100,
          name: "Santa's Wonderland",
          uuid: "161b7bc9d5f89644b7cfc9f7fe64ea5035a83902",
          image: "https://r.gamesstatic.com/games/161b7bc9d5f89644b7cfc9f7fe64ea5035a83902.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 253,
          name: "Savannah Sunrise",
          uuid: "5638d7ccd1f8fd785a0a1aee4c6e4c03faddf24c",
          image: "https://r.gamesstatic.com/games/5638d7ccd1f8fd785a0a1aee4c6e4c03faddf24c.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 688,
          name: "Savannah's Queen",
          uuid: "0f28ab10a09049fbbce81086c8d10500",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/0f28ab10a09049fbbce81086c8d10500.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 799,
          name: "Scarab Boost",
          uuid: "c19dff883d8241a78937627667e35297",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/c19dff883d8241a78937627667e35297.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 800,
          name: "Scarab Riches",
          uuid: "28b6bf9585a24b5c8959b6c8dc83b16a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/28b6bf9585a24b5c8959b6c8dc83b16a.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 801,
          name: "Scarab Temple",
          uuid: "1b1f5174c8b74de189e3e269ae292da2",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/1b1f5174c8b74de189e3e269ae292da2.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 412,
          name: "Scroll of Dead",
          uuid: "d5bcda507a0ae2286a44a82440508adb3e16182f",
          image: "https://r.gamesstatic.com/games/d5bcda507a0ae2286a44a82440508adb3e16182f.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 859,
          name: "ScruffyDuck",
          uuid: "f2e0ee8d70b1f41f7f92bb4f689b4462e3e9afb3",
          image: "https://r.gamesstatic.com/games/f2e0ee8d70b1f41f7f92bb4f689b4462e3e9afb3.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 262,
          name: "Sea Sirens (LLC)",
          uuid: "f8741facae241a49f1360b98ebb0c0a6a3cff92f",
          image: "https://r.gamesstatic.com/games/f8741facae241a49f1360b98ebb0c0a6a3cff92f.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 692,
          name: "Secret Of Dead",
          uuid: "e764e82647fd4b1ca4e4afdc90b39050",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/e764e82647fd4b1ca4e4afdc90b39050.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 850,
          name: "Secrets of Atlantis",
          uuid: "5f0116f0a9b9b26140993a01b64f8d40c7a72d78",
          image: "https://r.gamesstatic.com/games/5f0116f0a9b9b26140993a01b64f8d40c7a72d78.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 860,
          name: "SecretsOfChristmas",
          uuid: "0f2f53b745a429dbf585bf5c7e245cb112e2b319",
          image: "https://r.gamesstatic.com/games/0f2f53b745a429dbf585bf5c7e245cb112e2b319.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 716,
          name: "Sevens",
          uuid: "c2d2b1fb2acf391c6f406e93beb6c6c78a15d81e",
          image: "https://r.gamesstatic.com/games/c2d2b1fb2acf391c6f406e93beb6c6c78a15d81e.png",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 436,
          name: "Shah Mat",
          uuid: "9788b3ec8c5bd28ad1f2a1d7dc6c5ea72b513c87",
          image: "https://r.gamesstatic.com/games/9788b3ec8c5bd28ad1f2a1d7dc6c5ea72b513c87.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 588,
          name: "Shamrock Miner",
          uuid: "c2fb13b7a8f647d984edeecb0ef8ab8f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/c2fb13b7a8f647d984edeecb0ef8ab8f.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 204,
          name: "Sharky",
          uuid: "4476405774a3c048d3cc4d3b83dc5774c5b3a6d1",
          image: "https://r.gamesstatic.com/games/4476405774a3c048d3cc4d3b83dc5774c5b3a6d1.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 705,
          name: "Shazam",
          uuid: "befb00240dcb3153054e4818356e1db423b51a7e",
          image: "https://r.gamesstatic.com/games/befb00240dcb3153054e4818356e1db423b51a7e.jpg",
          type: "slots",
          provider: "BrandGames",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 921,
          name: "Shield of Sparta",
          uuid: "d106d5e3d3df41b0887ead2f4cd3bab7",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/d106d5e3d3df41b0887ead2f4cd3bab7.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 183,
          name: "Shining Crown",
          uuid: "846db8b1ea3c0d639cc069618b01b73638e951d1",
          image: "https://r.gamesstatic.com/games/846db8b1ea3c0d639cc069618b01b73638e951d1.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 205,
          name: "Shooting Stars",
          uuid: "0b11f97a8e5d52a21fd0de470e699fffdb9cbc91",
          image: "https://r.gamesstatic.com/games/0b11f97a8e5d52a21fd0de470e699fffdb9cbc91.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 127,
          name: "Sizzling 6",
          uuid: "e355fffe8405129a5ef8b6a5e77ff83d0a118de4",
          image: "https://r.gamesstatic.com/games/e355fffe8405129a5ef8b6a5e77ff83d0a118de4.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 125,
          name: "Sizzling Hot 6 Extra Gold",
          uuid: "85c1535bc203f3d5a7d746b47b7e84f8955299d0",
          image: "https://r.gamesstatic.com/games/85c1535bc203f3d5a7d746b47b7e84f8955299d0.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 922,
          name: "Sky Bounty",
          uuid: "a5ec278da33940309ee22c5fad7805f0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/a5ec278da33940309ee22c5fad7805f0.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 535,
          name: "Smugglers Cove",
          uuid: "6a65b7d55e4da33a640fcab5721d8ca7853996c0",
          image: "https://r.gamesstatic.com/games/6a65b7d55e4da33a640fcab5721d8ca7853996c0.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 638,
          name: "Snake Arena",
          uuid: "efee2139ff024213883823e07752a070",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/efee2139ff024213883823e07752a070.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 408,
          name: "Snakebite",
          uuid: "5fb05eca391c403a98ddccb2d95f6d7e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/5fb05eca391c403a98ddccb2d95f6d7e.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 434,
          name: "Snow Wild and the 7 Features",
          uuid: "4aa7c925524266bbd7174d1c063e30f1d984146c",
          image: "https://r.gamesstatic.com/games/4aa7c925524266bbd7174d1c063e30f1d984146c.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 726,
          name: "Songkran Splash",
          uuid: "3fb1a302eca74c14b44fc508f42af574",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3fb1a302eca74c14b44fc508f42af574.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 639,
          name: "Space Miners",
          uuid: "fccbd81f21324bf086a766a5ff678c69",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/fccbd81f21324bf086a766a5ff678c69.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 341,
          name: "Space Wars",
          uuid: "7ae2162b408679c2c28196d8e451989b3e3dd662",
          image: "https://r.gamesstatic.com/games/7ae2162b408679c2c28196d8e451989b3e3dd662.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 548,
          name: "Space Wars 2",
          uuid: "cc2c91e6f69df07858542ce21accf68807060472",
          image: "https://r.gamesstatic.com/games/cc2c91e6f69df07858542ce21accf68807060472.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 332,
          name: "Spanish Passion",
          uuid: "0dfdbbdb6afc9f5810b9be59f9bd26f29b6e8b78",
          image: "https://r.gamesstatic.com/games/0dfdbbdb6afc9f5810b9be59f9bd26f29b6e8b78.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 861,
          name: "Sparks",
          uuid: "64d860091b16d9a754ce86625378166a6e5f6077",
          image: "https://r.gamesstatic.com/games/64d860091b16d9a754ce86625378166a6e5f6077.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 409,
          name: "Sparky Shortz",
          uuid: "fa7dd0ee53df46e68c16a3f2fcce305b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PlayNGo/fa7dd0ee53df46e68c16a3f2fcce305b.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 727,
          name: "Speed Winner",
          uuid: "38662e49afe8437bbdaef0377226b600",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/38662e49afe8437bbdaef0377226b600.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 342,
          name: "Spellcast ",
          uuid: "5df2041fdc99932cb8ac4c8a21433848aedf2b4c",
          image: "https://r.gamesstatic.com/games/5df2041fdc99932cb8ac4c8a21433848aedf2b4c.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 379,
          name: "Spinata Grande",
          uuid: "c27f36095ea673062f5cef5de407192eb7bd364d",
          image: "https://r.gamesstatic.com/games/c27f36095ea673062f5cef5de407192eb7bd364d.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 343,
          name: "Spinsane ",
          uuid: "94774fffad5a68bd5b5b4c16f20433127a3342ee",
          image: "https://r.gamesstatic.com/games/94774fffad5a68bd5b5b4c16f20433127a3342ee.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 728,
          name: "Spirited Wonders",
          uuid: "e2f2f35a22d34460bc7ebac4818122cd",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/e2f2f35a22d34460bc7ebac4818122cd.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 206,
          name: "Star Nova",
          uuid: "67e5014209183f93e5309b6935893c21f793a612",
          image: "https://r.gamesstatic.com/games/67e5014209183f93e5309b6935893c21f793a612.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 862,
          name: "StarBurst",
          uuid: "e1c86afe93ad7ef54096708419deee8dd8fc0ac2",
          image: "https://r.gamesstatic.com/games/e1c86afe93ad7ef54096708419deee8dd8fc0ac2.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 549,
          name: "Starburst XXXtreme",
          uuid: "03823129c8858cade89053b6bf3af8fb92b4c8e1",
          image: "https://r.gamesstatic.com/games/03823129c8858cade89053b6bf3af8fb92b4c8e1.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 923,
          name: "Starlight Christmas",
          uuid: "3de123dd11b84c3a91700c668322f564",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/3de123dd11b84c3a91700c668322f564.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 536,
          name: "Starlight Princess",
          uuid: "43dff8048070ece704ef6e1b2376d4e8be11516e",
          image: "https://r.gamesstatic.com/games/43dff8048070ece704ef6e1b2376d4e8be11516e.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 924,
          name: "Starlight Princess 1000",
          uuid: "ca28c470ffee46b7ab4ba5826dbd53d2",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ca28c470ffee46b7ab4ba5826dbd53d2.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 259,
          name: "Starmania",
          uuid: "1f89c40d72343a1fb87d95b07aec9712abb52241",
          image: "https://r.gamesstatic.com/games/1f89c40d72343a1fb87d95b07aec9712abb52241.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 537,
          name: "Starz Megaways",
          uuid: "f087582c11d59114075771252bc8815cb056af99",
          image: "https://r.gamesstatic.com/games/f087582c11d59114075771252bc8815cb056af99.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 863,
          name: "SteamTower",
          uuid: "2024fbd8a3a110f2430d28511273168b298ff77f",
          image: "https://r.gamesstatic.com/games/2024fbd8a3a110f2430d28511273168b298ff77f.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 264,
          name: "Stein Haus",
          uuid: "8aa1a282b0def9fa69fe4ca918b0b714d8cd90d7",
          image: "https://r.gamesstatic.com/games/8aa1a282b0def9fa69fe4ca918b0b714d8cd90d7.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 864,
          name: "Stickers",
          uuid: "24de9416b21466f4a6fb6d2f91e12ad969d6aab8",
          image: "https://r.gamesstatic.com/games/24de9416b21466f4a6fb6d2f91e12ad969d6aab8.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 925,
          name: "Sticky Bees",
          uuid: "21c22022c6b346b58e51a2c1b19d8920",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/21c22022c6b346b58e51a2c1b19d8920.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 802,
          name: "Sticky Piggy",
          uuid: "1e5e042292d240fd827cee79720ddc65",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/1e5e042292d240fd827cee79720ddc65.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 689,
          name: "Story Of Medusa",
          uuid: "044f702afbf54217970688e3c9161619",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spinomenal/044f702afbf54217970688e3c9161619.png",
          type: "slots",
          provider: "Spinomenal",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 926,
          name: "Strawberry Cocktail",
          uuid: "84c3c5abdb6944759c11785178e1f05b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/84c3c5abdb6944759c11785178e1f05b.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 927,
          name: "Sugar Rush",
          uuid: "ae459d56301842488dfce6253d8bd57a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ae459d56301842488dfce6253d8bd57a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 928,
          name: "Sugar Rush 1000",
          uuid: "9e74ba33f44c4190902301dc15f9caee",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/9e74ba33f44c4190902301dc15f9caee.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 929,
          name: "Sugar Rush Xmas",
          uuid: "083309bf086e43d4bb2501ad923d3ded",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/083309bf086e43d4bb2501ad923d3ded.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 930,
          name: "Sugar Supreme Powernudge",
          uuid: "4ed91ece772e497ca1725874a8f0fff9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/4ed91ece772e497ca1725874a8f0fff9.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 333,
          name: "Summer Bliss",
          uuid: "a8f4abc9ad5b64eb92f393cd8fab2a0ba0ed604c",
          image: "https://r.gamesstatic.com/games/a8f4abc9ad5b64eb92f393cd8fab2a0ba0ed604c.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 803,
          name: "Sun of Egypt",
          uuid: "0e555e02213542159b153848c33b3642",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0e555e02213542159b153848c33b3642.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 804,
          name: "Sun of Egypt 2",
          uuid: "46b06ba21c4e495abae007e9589f245d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/46b06ba21c4e495abae007e9589f245d.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 805,
          name: "Sun of Egypt 3",
          uuid: "0cdf02b8242842e8bdc8e6022b69a443",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0cdf02b8242842e8bdc8e6022b69a443.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 806,
          name: "Sunlight Princess",
          uuid: "2826c2f8830346cfb7b63da427f29345",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/2826c2f8830346cfb7b63da427f29345.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 318,
          name: "Super 20",
          uuid: "ec34fe7e03c06396299af1d78ae4edb1f5f8ebd4",
          image: "https://r.gamesstatic.com/games/ec34fe7e03c06396299af1d78ae4edb1f5f8ebd4.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 640,
          name: "Super Boost",
          uuid: "8e757690e38c4ced89152c2112bf9533",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/8e757690e38c4ced89152c2112bf9533.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 868,
          name: "Super Golf Drive",
          uuid: "973f6c33352647b3a9d9bb7f26091940",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/973f6c33352647b3a9d9bb7f26091940.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 807,
          name: "Super Marble",
          uuid: "95dba0e61df048c5a6c90303cd10e66c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/95dba0e61df048c5a6c90303cd10e66c.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 808,
          name: "Super Rich GOD",
          uuid: "0b1b5bf147fa4a83b86c09248f69fbaf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/0b1b5bf147fa4a83b86c09248f69fbaf.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 717,
          name: "Super Sevens",
          uuid: "fc3f8bdc1608f7e0b898f353b0d189b204337cbb",
          image: "https://r.gamesstatic.com/games/fc3f8bdc1608f7e0b898f353b0d189b204337cbb.jpeg",
          type: "slots",
          provider: "Belatra",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 869,
          name: "Supermarket Spree",
          uuid: "6ad03a82288e435e93b75175c3929012",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/6ad03a82288e435e93b75175c3929012.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 656,
          name: "Superstars",
          uuid: "478ff6a987a741b9b28e1e61d9e48c37",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/478ff6a987a741b9b28e1e61d9e48c37.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 129,
          name: "Supra Hot",
          uuid: "053082eac539723eefae50a5a5417afeee16148a",
          image: "https://r.gamesstatic.com/games/053082eac539723eefae50a5a5417afeee16148a.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 119,
          name: "Sweet Bonanza",
          uuid: "1acab4542fe201953a50fadb54bd2d38cca7da35",
          image: "https://r.gamesstatic.com/games/1acab4542fe201953a50fadb54bd2d38cca7da35.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 147,
          name: "Sweet Bonanza Xmas",
          uuid: "bcf989e03a9844c46ac16e925390b075c85170b2",
          image: "https://r.gamesstatic.com/games/bcf989e03a9844c46ac16e925390b075c85170b2.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 5,
          name: "Sweet Life",
          uuid: "83cb65f35f1eb670574779509464d51533ee01a4",
          image: "https://r.gamesstatic.com/games/83cb65f35f1eb670574779509464d51533ee01a4.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 6,
          name: "Sweet Life 2",
          uuid: "cbcda7f65f39e06cdc27bbe2d933fa53771ed1cf",
          image: "https://r.gamesstatic.com/games/cbcda7f65f39e06cdc27bbe2d933fa53771ed1cf.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 344,
          name: "Sweety Honey Fruity ",
          uuid: "c8544668d60074aea17e62419fa7903a1ef46b65",
          image: "https://r.gamesstatic.com/games/c8544668d60074aea17e62419fa7903a1ef46b65.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 572,
          name: "Sword Of Ares",
          uuid: "47e1542d286526a93942e2cb12e0574b77d20ef0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/47e1542d286526a93942e2cb12e0574b77d20ef0.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 659,
          name: "Taco Fury XXXtreme",
          uuid: "24f18a3e00a647039981c16206d5df9e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/24f18a3e00a647039981c16206d5df9e.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 508,
          name: "Tale Of Kyubiko",
          uuid: "e63e603e80a2fe3ffc32b5d32c50b7c9e1bea3d0",
          image: "https://r.gamesstatic.com/games/e63e603e80a2fe3ffc32b5d32c50b7c9e1bea3d0.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 506,
          name: "Tales of Asgard: Freya's Wedding",
          uuid: "95da95b63f059db97c080f6777e60afd676668a4",
          image: "https://r.gamesstatic.com/games/95da95b63f059db97c080f6777e60afd676668a4.png",
          type: "slots",
          provider: "PlayNGo",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 365,
          name: "Tales of Darkness: Lunar Eclipse",
          uuid: "c440cceb495beeb0ea733c42296be315ea7202d0",
          image: "https://r.gamesstatic.com/games/c440cceb495beeb0ea733c42296be315ea7202d0.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 641,
          name: "Templar Tumble",
          uuid: "56e95c6331aa4af7951aecf8818e3752",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/56e95c6331aa4af7951aecf8818e3752.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 433,
          name: "Ten Elements",
          uuid: "0bf17cff663517ca5e7b6276655870dd2c1c4889",
          image: "https://r.gamesstatic.com/games/0bf17cff663517ca5e7b6276655870dd2c1c4889.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 870,
          name: "Thai River Wonders",
          uuid: "3af38713b1ec439496383e721eac8a76",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3af38713b1ec439496383e721eac8a76.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 931,
          name: "The Alter Ego",
          uuid: "ab26d67bc17e43c98bf5227c95a23cb7",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ab26d67bc17e43c98bf5227c95a23cb7.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 932,
          name: "The Big Dawgs",
          uuid: "25cfc82c07884f599c3182cd123f43c4",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/25cfc82c07884f599c3182cd123f43c4.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 356,
          name: "The Big Journey",
          uuid: "8084e935500439935990dd023827ed16ada5e419",
          image: "https://r.gamesstatic.com/games/8084e935500439935990dd023827ed16ada5e419.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 380,
          name: "The Creature from the Black Lagoon",
          uuid: "7425955d6e7cd7749401b7ac9e9b1538dad5cd56",
          image: "https://r.gamesstatic.com/games/7425955d6e7cd7749401b7ac9e9b1538dad5cd56.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 121,
          name: "The Dog House",
          uuid: "4f8e98ca754ed0807f18077add77d6686289283a",
          image: "https://r.gamesstatic.com/games/4f8e98ca754ed0807f18077add77d6686289283a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 837,
          name: "The Dog House Dog or Alive",
          uuid: "1782782a8c5440ca9f0e01c76ba8d1d5",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/1782782a8c5440ca9f0e01c76ba8d1d5.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 120,
          name: "The Dog House Megaways",
          uuid: "006ce507bb774122a5b0cb17988bf73c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/006ce507bb774122a5b0cb17988bf73c.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 432,
          name: "The Equalizer",
          uuid: "6669322f3e5ba6c730066d6b165c74564b91d9b2",
          image: "https://r.gamesstatic.com/games/6669322f3e5ba6c730066d6b165c74564b91d9b2.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 184,
          name: "The Great Egypt",
          uuid: "9c5d6bf88df3f89f57e593231cddd6b01ad07bf7",
          image: "https://r.gamesstatic.com/games/9c5d6bf88df3f89f57e593231cddd6b01ad07bf7.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 871,
          name: "The Great Icescape",
          uuid: "e98c0dd778594bdfbc9f9b30a32d1d90",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/e98c0dd778594bdfbc9f9b30a32d1d90.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 642,
          name: "The Great Pigsby",
          uuid: "800ded6fb57d456c909f9fbfdf1feeaf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/800ded6fb57d456c909f9fbfdf1feeaf.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 565,
          name: "The Great Stick-Up",
          uuid: "4cd312cecdade75c9a20a64065cad8a92898ea50",
          image: "https://r.gamesstatic.com/games/4cd312cecdade75c9a20a64065cad8a92898ea50.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 431,
          name: "The Greatest Train Robbery",
          uuid: "b7682e0a41515c604ef912122511dcd7f5b005e6",
          image: "https://r.gamesstatic.com/games/b7682e0a41515c604ef912122511dcd7f5b005e6.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 785,
          name: "The King of Heroes",
          uuid: "d05ef5b1f084494984de0ef8dad8beb0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/d05ef5b1f084494984de0ef8dad8beb0.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 387,
          name: "The Legend of Shangri-La",
          uuid: "14d671846cd51ce49688eca2b118f1827073d49f",
          image: "https://r.gamesstatic.com/games/14d671846cd51ce49688eca2b118f1827073d49f.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 933,
          name: "The Money Men Megaways",
          uuid: "6318eff7622445a8b0243ec4b569523c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/6318eff7622445a8b0243ec4b569523c.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 872,
          name: "The Queen’s Banquet",
          uuid: "833a181b68684eba95718cfb4f3bd23d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/833a181b68684eba95718cfb4f3bd23d.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 207,
          name: "The Real King Gold Records",
          uuid: "8386859402a54ce577364495498fedced152dfd0",
          image: "https://r.gamesstatic.com/games/8386859402a54ce577364495498fedced152dfd0.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 261,
          name: "The Real King™ Aloha Hawaii (LLC)",
          uuid: "174d66511afb3ca826cbd757768f4c69a96d6956",
          image: "https://r.gamesstatic.com/games/174d66511afb3ca826cbd757768f4c69a96d6956.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 347,
          name: "The Reel Steal",
          uuid: "bccdaec282bf8b02e6a589e70ffdd28d58d2400f",
          image: "https://r.gamesstatic.com/games/bccdaec282bf8b02e6a589e70ffdd28d58d2400f.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 260,
          name: "The Snake Charmer",
          uuid: "eeea8ea881cfe21f7aa2e57ba0207108d4edd9cc",
          image: "https://r.gamesstatic.com/games/eeea8ea881cfe21f7aa2e57ba0207108d4edd9cc.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 334,
          name: "The Story Of Alexander",
          uuid: "386f804facc73b941af5e03c2c20aa9b04c3f914",
          image: "https://r.gamesstatic.com/games/386f804facc73b941af5e03c2c20aa9b04c3f914.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 570,
          name: "The Ultimate 5",
          uuid: "968264344395349fc7751606bf2773d7d2771d6a",
          image: "https://r.gamesstatic.com/games/968264344395349fc7751606bf2773d7d2771d6a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 185,
          name: "The White Wolf",
          uuid: "1f383dbd6bbd30d13606da078fa42c57f380ade6",
          image: "https://r.gamesstatic.com/games/1f383dbd6bbd30d13606da078fa42c57f380ade6.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 934,
          name: "The Wild Gang",
          uuid: "327d81d804c0432a916cbb09365f6f12",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/327d81d804c0432a916cbb09365f6f12.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 430,
          name: "The Wild Hatter",
          uuid: "9d482dc5ee659d1ba8c56d021b550c5ceaac6293",
          image: "https://r.gamesstatic.com/games/9d482dc5ee659d1ba8c56d021b550c5ceaac6293.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 429,
          name: "Thor`s Vengeance",
          uuid: "13de706e4824ad72ce03aa56ac9346efc9c51e66",
          image: "https://r.gamesstatic.com/games/13de706e4824ad72ce03aa56ac9346efc9c51e66.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 809,
          name: "Thunder of Olympus",
          uuid: "b2a65bdc62774dd7beef0341a95ca647",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/b2a65bdc62774dd7beef0341a95ca647.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 810,
          name: "Tiger Jungle",
          uuid: "3c64f6afbaae43be8ec5bb6ef21b1f6e",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/3c64f6afbaae43be8ec5bb6ef21b1f6e.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 643,
          name: "Tiger Kingdom",
          uuid: "e8412093ce6d4fe9a1474311a0d9ecef",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/e8412093ce6d4fe9a1474311a0d9ecef.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 811,
          name: "Tiger Stone",
          uuid: "bb9d4952c9d3439395f1cf81db36c0a5",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/bb9d4952c9d3439395f1cf81db36c0a5.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 812,
          name: "Tiger's Gold",
          uuid: "08ec077730e94e19a7518d03b6e9e574",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/08ec077730e94e19a7518d03b6e9e574.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 935,
          name: "Timber Stacks",
          uuid: "21cc7f487b8840de9a6c00a4ab49989a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/21cc7f487b8840de9a6c00a4ab49989a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 644,
          name: "Top Dawg$",
          uuid: "bb808c587fa94563be7f4085fdf35314",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/bb808c587fa94563be7f4085fdf35314.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 284,
          name: "Totally Wild",
          uuid: "0e66fbaab204d75080283a067f34f7e0f02971ad",
          image: "https://r.gamesstatic.com/games/0e66fbaab204d75080283a067f34f7e0f02971ad.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 428,
          name: "Totem Lightning Power Reels",
          uuid: "3ac5164d8f8b233e09f47fa18b9a31eeccd592ed",
          image: "https://r.gamesstatic.com/games/3ac5164d8f8b233e09f47fa18b9a31eeccd592ed.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 873,
          name: "Totem Wonders",
          uuid: "c623d8e81d5a40abb97f5fb74f1e78e8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/c623d8e81d5a40abb97f5fb74f1e78e8.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 427,
          name: "Treasure Mine",
          uuid: "c46af1ece7aaa6b54ae0356b2fcc16d11a982702",
          image: "https://r.gamesstatic.com/games/c46af1ece7aaa6b54ae0356b2fcc16d11a982702.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 426,
          name: "Treasure Mine Power Reels",
          uuid: "e737c9e598f386f3dfc0eb47964b4111e61f3f7b",
          image: "https://r.gamesstatic.com/games/e737c9e598f386f3dfc0eb47964b4111e61f3f7b.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 874,
          name: "Treasures Of Aztec",
          uuid: "a1fb63e00e7d4ab88e98daeff5a52aaf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/a1fb63e00e7d4ab88e98daeff5a52aaf.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 936,
          name: "Trees of Treasure",
          uuid: "6e61c22b17464387b8284c4221705e41",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/6e61c22b17464387b8284c4221705e41.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 566,
          name: "Triple Tigers",
          uuid: "fab335a0c32fe80fcf56b5ecc482358832cb115e",
          image: "https://r.gamesstatic.com/games/fab335a0c32fe80fcf56b5ecc482358832cb115e.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 645,
          name: "Trolls’ Gold",
          uuid: "1b1e428683414b9aa1519b78dd576f90",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/1b1e428683414b9aa1519b78dd576f90.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 937,
          name: "Tundra’s Fortune",
          uuid: "6b9552635b904cc6ada0625df01dda1c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/6b9552635b904cc6ada0625df01dda1c.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 938,
          name: "Twilight Princess",
          uuid: "bf69cd32980a471ea1b357961d0bedc8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/bf69cd32980a471ea1b357961d0bedc8.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 353,
          name: "Twin Spin Deluxe",
          uuid: "b988c6f2bbe09c70922951354e8f23e0419c3381",
          image: "https://r.gamesstatic.com/games/b988c6f2bbe09c70922951354e8f23e0419c3381.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 381,
          name: "Twin Spin MegaWays",
          uuid: "3d778a8a48e829e019846be83c8a5fda4a5e99ef",
          image: "https://r.gamesstatic.com/games/3d778a8a48e829e019846be83c8a5fda4a5e99ef.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 345,
          name: "Twinhappiness ",
          uuid: "85bcc8d4729d29f242765da7b3322e4ea0a50153",
          image: "https://r.gamesstatic.com/games/85bcc8d4729d29f242765da7b3322e4ea0a50153.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 865,
          name: "TwinSpin",
          uuid: "960feedd2f94e730a76687381438c65219edd936",
          image: "https://r.gamesstatic.com/games/960feedd2f94e730a76687381438c65219edd936.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 186,
          name: "Ultimate Hot",
          uuid: "2368f8e700eade7827336827bc27da30c3619c99",
          image: "https://r.gamesstatic.com/games/2368f8e700eade7827336827bc27da30c3619c99.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 150,
          name: "Ultra Hold&Spin",
          uuid: "63d60209701d2114713b6e15be30a14a36eb3f86",
          image: "https://r.gamesstatic.com/games/63d60209701d2114713b6e15be30a14a36eb3f86.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 875,
          name: "Vampire’s Charm",
          uuid: "cc3ea067c19f4fbd8c5f7dc68282d9e8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/cc3ea067c19f4fbd8c5f7dc68282d9e8.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 425,
          name: "Vault of Anubis",
          uuid: "1c3df5dd9b90565d917cd697bcdf3e4740a22807",
          image: "https://r.gamesstatic.com/games/1c3df5dd9b90565d917cd697bcdf3e4740a22807.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 254,
          name: "Venetian Rose",
          uuid: "599bd28b1993cba6ef8e7f8a2ba68d6c6fb7309b",
          image: "https://r.gamesstatic.com/games/599bd28b1993cba6ef8e7f8a2ba68d6c6fb7309b.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 187,
          name: "Versailles Gold",
          uuid: "b4868de7e40f03235352b11e22ada9f6e4e31c8c",
          image: "https://r.gamesstatic.com/games/b4868de7e40f03235352b11e22ada9f6e4e31c8c.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 424,
          name: "Vicky Ventura",
          uuid: "54040482886248b7869410dc28df9e5f300d4768",
          image: "https://r.gamesstatic.com/games/54040482886248b7869410dc28df9e5f300d4768.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 224,
          name: "Victorious",
          uuid: "c5b8a4a1db25d28b961f5685945380970cbad9fb",
          image: "https://r.gamesstatic.com/games/c5b8a4a1db25d28b961f5685945380970cbad9fb.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 668,
          name: "Viking Clash",
          uuid: "7fe8b69af0da453aaf4b305df07fe237",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/7fe8b69af0da453aaf4b305df07fe237.png",
          type: "slots",
          provider: "PushGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 364,
          name: "Viking Dragon",
          uuid: "cac364fd87110b771ef1e954d14982e3a009d16d",
          image: "https://r.gamesstatic.com/games/cac364fd87110b771ef1e954d14982e3a009d16d.png",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 939,
          name: "Viking Forge",
          uuid: "22c1e70ff73c43fe93e64bcb4ca1123b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/22c1e70ff73c43fe93e64bcb4ca1123b.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 388,
          name: "Vikings",
          uuid: "10a2fd11442f6eb4fc59944e278d24396c271f87",
          image: "https://r.gamesstatic.com/games/10a2fd11442f6eb4fc59944e278d24396c271f87.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 646,
          name: "Volatile Vikings",
          uuid: "2589617356c64ec28b0a96b2af6a79d6",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/2589617356c64ec28b0a96b2af6a79d6.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 423,
          name: "War Of Gods",
          uuid: "e436e2d3ebdce3b540766429ae916c4ff5ad72ac",
          image: "https://r.gamesstatic.com/games/e436e2d3ebdce3b540766429ae916c4ff5ad72ac.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 382,
          name: "Warlords Slot",
          uuid: "b9af04ea043512a4b8643f917c73d840044391d3",
          image: "https://r.gamesstatic.com/games/b9af04ea043512a4b8643f917c73d840044391d3.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 876,
          name: "Ways of the Qilin",
          uuid: "5bab76cce2cb43e4a19819b66c99cdd3",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/5bab76cce2cb43e4a19819b66c99cdd3.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 238,
          name: "Werewolf Wild",
          uuid: "8f84e3c214cfc3b641e9053a6304faca5946e75d",
          image: "https://r.gamesstatic.com/games/8f84e3c214cfc3b641e9053a6304faca5946e75d.jpg",
          type: "slots",
          provider: "NYX",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 422,
          name: "What the Fox Megaways",
          uuid: "6c5271344d2e4a9ee58fbe57af5512ae68a4c50a",
          image: "https://r.gamesstatic.com/games/6c5271344d2e4a9ee58fbe57af5512ae68a4c50a.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 940,
          name: "Wheel O'Gold",
          uuid: "e6a4e0e0b393427d8909c84a3a0eb51a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/e6a4e0e0b393427d8909c84a3a0eb51a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 669,
          name: "Wheel of Wonders",
          uuid: "0d03702702fa40e993c534b9aae3b3bf",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/0d03702702fa40e993c534b9aae3b3bf.png",
          type: "slots",
          provider: "PushGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 389,
          name: "Who`s The Bride?",
          uuid: "25ea429288cd29e3fe421734614b0118e9b1ee55",
          image: "https://r.gamesstatic.com/games/25ea429288cd29e3fe421734614b0118e9b1ee55.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 877,
          name: "Wild Bandito",
          uuid: "4aa4ec1698704e52ba4890ce1f98d083",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/4aa4ec1698704e52ba4890ce1f98d083.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 383,
          name: "Wild Bazaar Slot",
          uuid: "c35215d68180dd0274c07293948a21490ba850a4",
          image: "https://r.gamesstatic.com/games/c35215d68180dd0274c07293948a21490ba850a4.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 538,
          name: "Wild Beach Party",
          uuid: "224e15ddc9aec9aff92a32cadfdeb87be1b0934c",
          image: "https://r.gamesstatic.com/games/224e15ddc9aec9aff92a32cadfdeb87be1b0934c.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 878,
          name: "Wild Bounty Showdown",
          uuid: "f02ebf1aa06149fc8e8c07197ab1fb29",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/f02ebf1aa06149fc8e8c07197ab1fb29.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 421,
          name: "Wild Cats Multiline",
          uuid: "c551038bcf276b5abfcbc556f4d9cd48f2a4011e",
          image: "https://r.gamesstatic.com/games/c551038bcf276b5abfcbc556f4d9cd48f2a4011e.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 647,
          name: "Wild Chapo",
          uuid: "27cfe55f97974a909c7098ddc27e12df",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/27cfe55f97974a909c7098ddc27e12df.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 879,
          name: "Wild Coaster",
          uuid: "b5ea7407bc9c43ca9855287978ef4f2c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/b5ea7407bc9c43ca9855287978ef4f2c.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 285,
          name: "Wild Country",
          uuid: "ee56e70f4f82d6bc149e8814fa2f1fe829f14ae7",
          image: "https://r.gamesstatic.com/games/ee56e70f4f82d6bc149e8814fa2f1fe829f14ae7.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 601,
          name: "Wild Dragon",
          uuid: "071cfc095cb447309b08792aeef3950a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/071cfc095cb447309b08792aeef3950a.png",
          type: "slots",
          provider: "Amatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 420,
          name: "Wild Fight",
          uuid: "eeb9c4581da2045dcf92d69da0b1318bf49a7107",
          image: "https://r.gamesstatic.com/games/eeb9c4581da2045dcf92d69da0b1318bf49a7107.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 880,
          name: "Wild Fireworks",
          uuid: "c86abd5b024a49bfb71ada7fa66a61c4",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/c86abd5b024a49bfb71ada7fa66a61c4.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 573,
          name: "Wild Hop & Drop",
          uuid: "0ce9b9dfa4a7cdf012979461ad8a94f74351dd0d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/0ce9b9dfa4a7cdf012979461ad8a94f74351dd0d.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 419,
          name: "Wild Nords",
          uuid: "e9ce1c65095e3ba2e874f8aa982a0e4c802c8530",
          image: "https://r.gamesstatic.com/games/e9ce1c65095e3ba2e874f8aa982a0e4c802c8530.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 349,
          name: "Wild Turkey",
          uuid: "88cdfaca5ba705b91dd8543e3aa8462ebb03ad03",
          image: "https://r.gamesstatic.com/games/88cdfaca5ba705b91dd8543e3aa8462ebb03ad03.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 539,
          name: "Wild West Gold",
          uuid: "7d0984bf0ea9d077e468b0eed90ef3a2d1619887",
          image: "https://r.gamesstatic.com/games/7d0984bf0ea9d077e468b0eed90ef3a2d1619887.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 567,
          name: "Wild West Gold Megaways",
          uuid: "6307cb98a008c23b400686f9829752b7c6322d7c",
          image: "https://r.gamesstatic.com/games/6307cb98a008c23b400686f9829752b7c6322d7c.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 418,
          name: "Wild Wild Chest",
          uuid: "bcf58aefa21ad915299f8ea2b9d14a9f460688a9",
          image: "https://r.gamesstatic.com/games/bcf58aefa21ad915299f8ea2b9d14a9f460688a9.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 652,
          name: "Wild Wild Riches",
          uuid: "516d3eeff066486db2b5d6c415810b3f",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/516d3eeff066486db2b5d6c415810b3f.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 352,
          name: "Wild-O-Tron 3000",
          uuid: "e25ab07aa149dc466b68ac78f25549f2e0ad0786",
          image: "https://r.gamesstatic.com/games/e25ab07aa149dc466b68ac78f25549f2e0ad0786.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 648,
          name: "Wildchemy",
          uuid: "397bd6c3f262479487e6e881ffc67541",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/397bd6c3f262479487e6e881ffc67541.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 225,
          name: "WildWater",
          uuid: "721fd6f63c49564341a9d63db39c4d4521d42a91",
          image: "https://r.gamesstatic.com/games/721fd6f63c49564341a9d63db39c4d4521d42a91.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 226,
          name: "WildWildWest",
          uuid: "43c0e7b0f77e233fa09ec5a99c176e87603d5ceb",
          image: "https://r.gamesstatic.com/games/43c0e7b0f77e233fa09ec5a99c176e87603d5ceb.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 881,
          name: "Win Win Fish Prawn Crab",
          uuid: "f24e62e6dce344fdb7469ff9c0c85cd2",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/f24e62e6dce344fdb7469ff9c0c85cd2.png",
          type: "slots",
          provider: "PGSoft",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 417,
          name: "Wings of Ra",
          uuid: "d176af54d0beab309f1ed24d49159531a945e5f6",
          image: "https://r.gamesstatic.com/games/d176af54d0beab309f1ed24d49159531a945e5f6.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 390,
          name: "Wings of Riches",
          uuid: "e41d1c2c276e3b0b79fa923f99eaf91920c3f931",
          image: "https://r.gamesstatic.com/games/e41d1c2c276e3b0b79fa923f99eaf91920c3f931.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 416,
          name: "Winter Wonders",
          uuid: "508f8d27e321343c948d3ec711f4efb0b0983e32",
          image: "https://r.gamesstatic.com/games/508f8d27e321343c948d3ec711f4efb0b0983e32.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 188,
          name: "Witches Charm",
          uuid: "302d923360a73a263834079c494d113fc6c54d38",
          image: "https://r.gamesstatic.com/games/302d923360a73a263834079c494d113fc6c54d38.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 228,
          name: "Wolf Cub",
          uuid: "74daff1e5b1535c9078f02f0d123f10c8566ffb8",
          image: "https://r.gamesstatic.com/games/74daff1e5b1535c9078f02f0d123f10c8566ffb8.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 540,
          name: "Wolf Gold",
          uuid: "3c3fa7acb75f2c1bd998f7fb1620e823666ffd6c",
          image: "https://r.gamesstatic.com/games/3c3fa7acb75f2c1bd998f7fb1620e823666ffd6c.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 813,
          name: "Wolf Night",
          uuid: "f3ade42652f04aafa1ed2ca53ccd9d7d",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/f3ade42652f04aafa1ed2ca53ccd9d7d.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 814,
          name: "Wolf Saga",
          uuid: "d6a7fac791b8443d8a89ddba7d596dc9",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/d6a7fac791b8443d8a89ddba7d596dc9.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 346,
          name: "Wolfs Bane ",
          uuid: "a263b7390c4d797ba813617eef9449ac6f442c51",
          image: "https://r.gamesstatic.com/games/a263b7390c4d797ba813617eef9449ac6f442c51.png",
          type: "slots",
          provider: "NetEnt",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 336,
          name: "Wonder Tree",
          uuid: "4ed59128cdf5ecf9645f59c31c10d0ddd9d49923",
          image: "https://r.gamesstatic.com/games/4ed59128cdf5ecf9645f59c31c10d0ddd9d49923.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 335,
          name: "Wonderheart",
          uuid: "b719fb542cddf3bf300da00067b884b7d8696184",
          image: "https://r.gamesstatic.com/games/b719fb542cddf3bf300da00067b884b7d8696184.png",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 815,
          name: "Wukong",
          uuid: "7d06f98541734ca9b0065adbfed864e8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/7d06f98541734ca9b0065adbfed864e8.png",
          type: "slots",
          provider: "3Oaks",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 208,
          name: "Xtra Hot",
          uuid: "609b1fb18daf01b95ca2876dd7bd384023f20a87",
          image: "https://r.gamesstatic.com/games/609b1fb18daf01b95ca2876dd7bd384023f20a87.jpg",
          type: "slots",
          provider: "Greentube",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 941,
          name: "Year of the Dragon King",
          uuid: "4c07daf5b12f4e88bdd53690f1186065",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/4c07daf5b12f4e88bdd53690f1186065.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 415,
          name: "Yucatan`s Mystery",
          uuid: "4653361ae3783b0e65252a18ae58d84c67c55316",
          image: "https://r.gamesstatic.com/games/4653361ae3783b0e65252a18ae58d84c67c55316.png",
          type: "slots",
          provider: "RedTiger",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 189,
          name: "Zodiac Wheel",
          uuid: "2a31ed845f9ca5fc607793c8c110b5526d89ad21",
          image: "https://r.gamesstatic.com/games/2a31ed845f9ca5fc607793c8c110b5526d89ad21.jpg",
          type: "slots",
          provider: "EGT",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 568,
          name: "Zombie Carnival",
          uuid: "0a05396d239f6400ad042efdd18d069df5d29dca",
          image: "https://r.gamesstatic.com/games/0a05396d239f6400ad042efdd18d069df5d29dca.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 0,
          priority: 1,
          favorites: false,
          enabled: 1
        }
      ]
    },
    {
      id: 35,
      name: "Сортировка",
      providerId: 0,
      providerName: "",
      games: [
        {
          game_id: 900,
          name: "Gates of Olympus 1000",
          uuid: "a545a0864ad148048332ad5a10756b9b",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/a545a0864ad148048332ad5a10756b9b.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 1,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 119,
          name: "Sweet Bonanza",
          uuid: "1acab4542fe201953a50fadb54bd2d38cca7da35",
          image: "https://r.gamesstatic.com/games/1acab4542fe201953a50fadb54bd2d38cca7da35.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 2,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 121,
          name: "The Dog House",
          uuid: "4f8e98ca754ed0807f18077add77d6686289283a",
          image: "https://r.gamesstatic.com/games/4f8e98ca754ed0807f18077add77d6686289283a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 3,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 514,
          name: "Big Bass Bonanza",
          uuid: "4fdeb7a8b906d1b1777b889acf63d61e41317305",
          image: "https://r.gamesstatic.com/games/4fdeb7a8b906d1b1777b889acf63d61e41317305.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 4,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 209,
          name: "Fruit Cocktail",
          uuid: "67894f8b7229ac27f2fecb00af254e75681d3090",
          image: "https://r.gamesstatic.com/games/67894f8b7229ac27f2fecb00af254e75681d3090.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 1,
          priority: 5,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 110,
          name: "Cupcakes",
          uuid: "36c5024fef6b45738122ea5bb6001a77",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/36c5024fef6b45738122ea5bb6001a77.png",
          type: "slots",
          provider: "NetEnt",
          hit: 1,
          priority: 6,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 662,
          name: "Fat Rabbit",
          uuid: "e6f5cf3e37ed46e48249aa0d99a8582c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/e6f5cf3e37ed46e48249aa0d99a8582c.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 7,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 145,
          name: "Gems Bonanza",
          uuid: "769fe95d39a67f9fec5c09ea75333263dad7bc8b",
          image: "https://r.gamesstatic.com/games/769fe95d39a67f9fec5c09ea75333263dad7bc8b.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 8,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 120,
          name: "The Dog House Megaways",
          uuid: "006ce507bb774122a5b0cb17988bf73c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/006ce507bb774122a5b0cb17988bf73c.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 9,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 147,
          name: "Sweet Bonanza Xmas",
          uuid: "bcf989e03a9844c46ac16e925390b075c85170b2",
          image: "https://r.gamesstatic.com/games/bcf989e03a9844c46ac16e925390b075c85170b2.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 10,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 108,
          name: "Giga Jar",
          uuid: "7ff30fdc4f7648778a6a4d49c9929554",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/7ff30fdc4f7648778a6a4d49c9929554.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 11,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 109,
          name: "Fat Banker",
          uuid: "3d5fb543aacc4bd0aa413aaa12edcb69",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/3d5fb543aacc4bd0aa413aaa12edcb69.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 12,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 729,
          name: "Bakery Bonanza",
          uuid: "3ddad6f2c02d440f8bf53142ecdb366c",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3ddad6f2c02d440f8bf53142ecdb366c.png",
          type: "slots",
          provider: "PGSoft",
          hit: 1,
          priority: 13,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 837,
          name: "The Dog House Dog or Alive",
          uuid: "1782782a8c5440ca9f0e01c76ba8d1d5",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/1782782a8c5440ca9f0e01c76ba8d1d5.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 14,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 107,
          name: "Jammin' Jars",
          uuid: "8f79688b6d454c87b2bc10fd2ddb6d61",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/8f79688b6d454c87b2bc10fd2ddb6d61.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 15,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 663,
          name: "Fire Hopper",
          uuid: "226f6eeb54914aff99e3bd2fcc0dda13",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/226f6eeb54914aff99e3bd2fcc0dda13.png",
          type: "slots",
          provider: "PushGaming",
          hit: 1,
          priority: 16,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 210,
          name: "Fruit Cocktail 2",
          uuid: "4283be45875772f6eaace1d2eeaca1c3d8a115f2",
          image: "https://r.gamesstatic.com/games/4283be45875772f6eaace1d2eeaca1c3d8a115f2.jpg",
          type: "slots",
          provider: "Igrosoft",
          hit: 1,
          priority: 17,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 557,
          name: "Big Bass Splash",
          uuid: "851099ed72db5d6dfc24d31d7c8890d289c160f4",
          image: "https://r.gamesstatic.com/games/851099ed72db5d6dfc24d31d7c8890d289c160f4.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 18,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 735,
          name: "Candy Bonanza",
          uuid: "d7ac004af593449495f45b79ad1457ba",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/d7ac004af593449495f45b79ad1457ba.png",
          type: "slots",
          provider: "PGSoft",
          hit: 1,
          priority: 19,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 123,
          name: "Let It Burn",
          uuid: "901ac0d989bb4eca847daae2fb705fdd",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/901ac0d989bb4eca847daae2fb705fdd.png",
          type: "slots",
          provider: "NetEnt",
          hit: 1,
          priority: 20,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 517,
          name: "Bigger Bass Bonanza",
          uuid: "37c5dd71a0bf602fb5be698a2b863c7df08369d7",
          image: "https://r.gamesstatic.com/games/37c5dd71a0bf602fb5be698a2b863c7df08369d7.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 21,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 839,
          name: "Big Bass Floats My Boat",
          uuid: "34d5f67739934452a5b1b79690aecf75",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/34d5f67739934452a5b1b79690aecf75.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 22,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 838,
          name: "Big Bass - Hold & Spinner",
          uuid: "ffb69ff2f1fd45fda9fe2395c054b044",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ffb69ff2f1fd45fda9fe2395c054b044.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 23,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 54,
          name: "Aviator",
          uuid: "88e106622db162f752e9fbf6fc492631f0f7c6ba",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/88e106622db162f752e9fbf6fc492631f0f7c6ba.png",
          type: "crash",
          provider: "Spribe",
          hit: 1,
          priority: 24,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 582,
          name: "Casanova",
          uuid: "72197acec4ff4065a0c67a061396f9fd",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/72197acec4ff4065a0c67a061396f9fd.jpg",
          type: "slots",
          provider: "Amatic",
          hit: 1,
          priority: 25,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 559,
          name: "Cleocatra",
          uuid: "09c8839d5531bbaebf7811058322957192cfac3f",
          image: "https://r.gamesstatic.com/games/09c8839d5531bbaebf7811058322957192cfac3f.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 26,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 523,
          name: "Fruit Party",
          uuid: "8d4207b1c2343e3bdfecd094304d1c31dadd0aa1",
          image: "https://r.gamesstatic.com/games/8d4207b1c2343e3bdfecd094304d1c31dadd0aa1.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 27,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 843,
          name: "CopyCats",
          uuid: "0d045e42e63db018db8b7c789ca03b8015267c68",
          image: "https://r.gamesstatic.com/games/0d045e42e63db018db8b7c789ca03b8015267c68.png",
          type: "slots",
          provider: "NetEnt",
          hit: 1,
          priority: 28,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 525,
          name: "Gates of Olympus",
          uuid: "3ba395633bc5b400031cb8a10d25e792b02f54c4",
          image: "https://r.gamesstatic.com/games/3ba395633bc5b400031cb8a10d25e792b02f54c4.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 29,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 561,
          name: "Hot Fiesta",
          uuid: "2f080174c15f8feeffb634363fbd843805bee43a",
          image: "https://r.gamesstatic.com/games/2f080174c15f8feeffb634363fbd843805bee43a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 30,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 764,
          name: "Lucky Neko",
          uuid: "8fcd7247e5834f7bab7ac395ca670df8",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/8fcd7247e5834f7bab7ac395ca670df8.png",
          type: "slots",
          provider: "PGSoft",
          hit: 1,
          priority: 31,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 533,
          name: "Madame Destiny",
          uuid: "055cd949fa8ba7ece5048b1649097fc851777e98",
          image: "https://r.gamesstatic.com/games/055cd949fa8ba7ece5048b1649097fc851777e98.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 32,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 928,
          name: "Sugar Rush 1000",
          uuid: "9e74ba33f44c4190902301dc15f9caee",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/9e74ba33f44c4190902301dc15f9caee.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 33,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 622,
          name: "Hex",
          uuid: "9dab000cb4ca4e618d441fb2b4d13648",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/9dab000cb4ca4e618d441fb2b4d13648.png",
          type: "slots",
          provider: "RelaxGaming",
          hit: 1,
          priority: 34,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 927,
          name: "Sugar Rush",
          uuid: "ae459d56301842488dfce6253d8bd57a",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ae459d56301842488dfce6253d8bd57a.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 35,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 929,
          name: "Sugar Rush Xmas",
          uuid: "083309bf086e43d4bb2501ad923d3ded",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/083309bf086e43d4bb2501ad923d3ded.png",
          type: "slots",
          provider: "Pragmatic",
          hit: 1,
          priority: 36,
          favorites: false,
          enabled: 1
        },
        {
          game_id: 657,
          name: "Bee Hive Bonanza",
          uuid: "3b5d832bb1dd49b293a9ca0544e2c178",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/3b5d832bb1dd49b293a9ca0544e2c178.png",
          type: "slots",
          provider: "NetEnt",
          hit: 1,
          priority: 100,
          favorites: false,
          enabled: 1
        }
      ]
    }
  ],
  bonusGames: [
    {
      id: 3,
      name: "Lucky Lady’s Charm",
      url: "/js-games/lucky_lady/game.html"
    },
    {
      id: 5,
      name: "Fruit Cocktail",
      url: "/js-games/fruit_cocktail/game.html"
    },
    {
      id: 2,
      name: "Book of Ra Classic",
      url: "/js-games/book_of_ra_classic/game.html"
    },
    {
      id: 40,
      name: "Lord of Ocean",
      url: "/js-games/lord_of_ocean/game.html"
    },
    {
      id: 23,
      name: "Sharky",
      url: "/js-games/sharky/game.html"
    },
    {
      id: 19,
      name: "Beetle Mania Deluxe",
      url: "/js-games/beetle_mania/game.html"
    },
    {
      id: 36,
      name: "Book of Ra Deluxe",
      url: "/js-games/book-of-ra-deluxe-2/game.html"
    },
    {
      id: 20,
      name: "Sizzling Hot Deluxe",
      url: "/js-games/sizzling_hot_deluxe/game.html"
    },
    {
      id: 48,
      name: "Diamond 7",
      url: "/js-games/diamond7/game.html"
    },
    {
      id: 18,
      name: "Just Jewels Deluxe",
      url: "/js-games/just_jewels_deluxe/game.html"
    },
    {
      id: 44,
      name: "Lucky Lady’s Charm",
      url: "/js-games/lucky_lady_deluxe/game.html"
    },
    {
      id: 45,
      name: "Queens of Hearts",
      url: "/js-games/queen_of_hearts/game.html"
    }
  ],
  gameHits: [
    {
      game_id: 54,
      name: "Aviator",
      uuid: "88e106622db162f752e9fbf6fc492631f0f7c6ba",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/88e106622db162f752e9fbf6fc492631f0f7c6ba.png",
      providerId: 12,
      providerName: "Spribe"
    },
    {
      game_id: 729,
      name: "Bakery Bonanza",
      uuid: "3ddad6f2c02d440f8bf53142ecdb366c",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/3ddad6f2c02d440f8bf53142ecdb366c.png",
      providerId: 18,
      providerName: "PGSoft"
    },
    {
      game_id: 657,
      name: "Bee Hive Bonanza",
      uuid: "3b5d832bb1dd49b293a9ca0544e2c178",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/3b5d832bb1dd49b293a9ca0544e2c178.png",
      providerId: 4,
      providerName: "NetEnt"
    },
    {
      game_id: 838,
      name: "Big Bass - Hold & Spinner",
      uuid: "ffb69ff2f1fd45fda9fe2395c054b044",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ffb69ff2f1fd45fda9fe2395c054b044.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 514,
      name: "Big Bass Bonanza",
      uuid: "4fdeb7a8b906d1b1777b889acf63d61e41317305",
      image: "https://r.gamesstatic.com/games/4fdeb7a8b906d1b1777b889acf63d61e41317305.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 839,
      name: "Big Bass Floats My Boat",
      uuid: "34d5f67739934452a5b1b79690aecf75",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/34d5f67739934452a5b1b79690aecf75.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 557,
      name: "Big Bass Splash",
      uuid: "851099ed72db5d6dfc24d31d7c8890d289c160f4",
      image: "https://r.gamesstatic.com/games/851099ed72db5d6dfc24d31d7c8890d289c160f4.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 517,
      name: "Bigger Bass Bonanza",
      uuid: "37c5dd71a0bf602fb5be698a2b863c7df08369d7",
      image: "https://r.gamesstatic.com/games/37c5dd71a0bf602fb5be698a2b863c7df08369d7.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 735,
      name: "Candy Bonanza",
      uuid: "d7ac004af593449495f45b79ad1457ba",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/d7ac004af593449495f45b79ad1457ba.png",
      providerId: 18,
      providerName: "PGSoft"
    },
    {
      game_id: 582,
      name: "Casanova",
      uuid: "72197acec4ff4065a0c67a061396f9fd",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/72197acec4ff4065a0c67a061396f9fd.jpg",
      providerId: 13,
      providerName: "Amatic"
    },
    {
      game_id: 559,
      name: "Cleocatra",
      uuid: "09c8839d5531bbaebf7811058322957192cfac3f",
      image: "https://r.gamesstatic.com/games/09c8839d5531bbaebf7811058322957192cfac3f.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 843,
      name: "CopyCats",
      uuid: "0d045e42e63db018db8b7c789ca03b8015267c68",
      image: "https://r.gamesstatic.com/games/0d045e42e63db018db8b7c789ca03b8015267c68.png",
      providerId: 4,
      providerName: "NetEnt"
    },
    {
      game_id: 110,
      name: "Cupcakes",
      uuid: "36c5024fef6b45738122ea5bb6001a77",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/36c5024fef6b45738122ea5bb6001a77.png",
      providerId: 4,
      providerName: "NetEnt"
    },
    {
      game_id: 109,
      name: "Fat Banker",
      uuid: "3d5fb543aacc4bd0aa413aaa12edcb69",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/3d5fb543aacc4bd0aa413aaa12edcb69.png",
      providerId: 15,
      providerName: "PushGaming"
    },
    {
      game_id: 662,
      name: "Fat Rabbit",
      uuid: "e6f5cf3e37ed46e48249aa0d99a8582c",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/e6f5cf3e37ed46e48249aa0d99a8582c.png",
      providerId: 15,
      providerName: "PushGaming"
    },
    {
      game_id: 663,
      name: "Fire Hopper",
      uuid: "226f6eeb54914aff99e3bd2fcc0dda13",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/226f6eeb54914aff99e3bd2fcc0dda13.png",
      providerId: 15,
      providerName: "PushGaming"
    },
    {
      game_id: 209,
      name: "Fruit Cocktail",
      uuid: "67894f8b7229ac27f2fecb00af254e75681d3090",
      image: "https://r.gamesstatic.com/games/67894f8b7229ac27f2fecb00af254e75681d3090.jpg",
      providerId: 3,
      providerName: "Igrosoft"
    },
    {
      game_id: 210,
      name: "Fruit Cocktail 2",
      uuid: "4283be45875772f6eaace1d2eeaca1c3d8a115f2",
      image: "https://r.gamesstatic.com/games/4283be45875772f6eaace1d2eeaca1c3d8a115f2.jpg",
      providerId: 3,
      providerName: "Igrosoft"
    },
    {
      game_id: 523,
      name: "Fruit Party",
      uuid: "8d4207b1c2343e3bdfecd094304d1c31dadd0aa1",
      image: "https://r.gamesstatic.com/games/8d4207b1c2343e3bdfecd094304d1c31dadd0aa1.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 525,
      name: "Gates of Olympus",
      uuid: "3ba395633bc5b400031cb8a10d25e792b02f54c4",
      image: "https://r.gamesstatic.com/games/3ba395633bc5b400031cb8a10d25e792b02f54c4.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 900,
      name: "Gates of Olympus 1000",
      uuid: "a545a0864ad148048332ad5a10756b9b",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/a545a0864ad148048332ad5a10756b9b.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 145,
      name: "Gems Bonanza",
      uuid: "769fe95d39a67f9fec5c09ea75333263dad7bc8b",
      image: "https://r.gamesstatic.com/games/769fe95d39a67f9fec5c09ea75333263dad7bc8b.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 108,
      name: "Giga Jar",
      uuid: "7ff30fdc4f7648778a6a4d49c9929554",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/7ff30fdc4f7648778a6a4d49c9929554.png",
      providerId: 15,
      providerName: "PushGaming"
    },
    {
      game_id: 622,
      name: "Hex",
      uuid: "9dab000cb4ca4e618d441fb2b4d13648",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/9dab000cb4ca4e618d441fb2b4d13648.png",
      providerId: 14,
      providerName: "RelaxGaming"
    },
    {
      game_id: 561,
      name: "Hot Fiesta",
      uuid: "2f080174c15f8feeffb634363fbd843805bee43a",
      image: "https://r.gamesstatic.com/games/2f080174c15f8feeffb634363fbd843805bee43a.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 107,
      name: "Jammin' Jars",
      uuid: "8f79688b6d454c87b2bc10fd2ddb6d61",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PushGaming/8f79688b6d454c87b2bc10fd2ddb6d61.png",
      providerId: 15,
      providerName: "PushGaming"
    },
    {
      game_id: 532,
      name: "Juicy Fruits",
      uuid: "a5251ca34afcd986423581afe47203db9a468144",
      image: "https://r.gamesstatic.com/games/a5251ca34afcd986423581afe47203db9a468144.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 123,
      name: "Let It Burn",
      uuid: "901ac0d989bb4eca847daae2fb705fdd",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/NetEnt/901ac0d989bb4eca847daae2fb705fdd.png",
      providerId: 4,
      providerName: "NetEnt"
    },
    {
      game_id: 764,
      name: "Lucky Neko",
      uuid: "8fcd7247e5834f7bab7ac395ca670df8",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/PGSoft/8fcd7247e5834f7bab7ac395ca670df8.png",
      providerId: 18,
      providerName: "PGSoft"
    },
    {
      game_id: 533,
      name: "Madame Destiny",
      uuid: "055cd949fa8ba7ece5048b1649097fc851777e98",
      image: "https://r.gamesstatic.com/games/055cd949fa8ba7ece5048b1649097fc851777e98.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 111,
      name: "Plinko",
      uuid: "a475050d2627431a9fbe9739a19ddf80",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Spribe/a475050d2627431a9fbe9739a19ddf80.png",
      providerId: 12,
      providerName: "Spribe"
    },
    {
      game_id: 927,
      name: "Sugar Rush",
      uuid: "ae459d56301842488dfce6253d8bd57a",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/ae459d56301842488dfce6253d8bd57a.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 928,
      name: "Sugar Rush 1000",
      uuid: "9e74ba33f44c4190902301dc15f9caee",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/9e74ba33f44c4190902301dc15f9caee.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 929,
      name: "Sugar Rush Xmas",
      uuid: "083309bf086e43d4bb2501ad923d3ded",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/083309bf086e43d4bb2501ad923d3ded.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 119,
      name: "Sweet Bonanza",
      uuid: "1acab4542fe201953a50fadb54bd2d38cca7da35",
      image: "https://r.gamesstatic.com/games/1acab4542fe201953a50fadb54bd2d38cca7da35.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 147,
      name: "Sweet Bonanza Xmas",
      uuid: "bcf989e03a9844c46ac16e925390b075c85170b2",
      image: "https://r.gamesstatic.com/games/bcf989e03a9844c46ac16e925390b075c85170b2.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 121,
      name: "The Dog House",
      uuid: "4f8e98ca754ed0807f18077add77d6686289283a",
      image: "https://r.gamesstatic.com/games/4f8e98ca754ed0807f18077add77d6686289283a.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 837,
      name: "The Dog House Dog or Alive",
      uuid: "1782782a8c5440ca9f0e01c76ba8d1d5",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/1782782a8c5440ca9f0e01c76ba8d1d5.png",
      providerId: 11,
      providerName: "Pragmatic"
    },
    {
      game_id: 120,
      name: "The Dog House Megaways",
      uuid: "006ce507bb774122a5b0cb17988bf73c",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Pragmatic/006ce507bb774122a5b0cb17988bf73c.png",
      providerId: 11,
      providerName: "Pragmatic"
    }
  ],
  banners: [
    {
      id: 2,
      name: "Приз за отзывы",
      position: 6,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>VULPIN-розыгрыш среди отзывов! <br>Оставляй свои отзывы в чате или в личном сообщении админу и автоматически участвуй в розыгрыше! Победитель получает бонус - х2 к депозиту на основной счет. <br><br><i>*В акции участвуют все отзывы, отправленные за период с понедельника по понедельник. Один победитель определяется случайным образом во вторник.</i></p>",
      relativeUrl: "review_bonus",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Приз за отзыв комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Приз за отзыв моб.jpeg"
    },
    {
      id: 4,
      name: "Еженедельный розыгрыш",
      position: 7,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Получи 1000 рублей на основной счет!&nbsp;</p><p>Бонус получает каждый игрок, который за неделю сделал депозитов на сумму более 5000 рублей.&nbsp;</p><p><br></p><p><i>*Сумма ваших депозитов вычисляется за период с понедельника по понедельник, бонус начиcляется по вторникам в виде промокода</i></p>",
      relativeUrl: "1000_rub",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/1000 рублей комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/1000 рубелй моб.jpeg"
    },
    {
      id: 5,
      name: "Шальные ручки",
      position: 5,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Акция Шальные ручки на VULPIN! Сделай больше всех депозитов за неделю и получи подарок - х2 к депозиту на основной баланс!&nbsp;</p><p><br></p><p><i>*В акции учавствуют все депозиты за период с понедельника по понедельник, один победитель определяется каждый вторник.</i></p>",
      relativeUrl: "lucky_hands",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Шальные ручки комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Шальные ручки моб.jpeg"
    },
    {
      id: 7,
      name: "Приветственный бонус",
      position: 1,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Получи х2 к первому депозиту на основной счет! Бонус начисляется автоматически сразу после пополнения.&nbsp;<br></p>",
      relativeUrl: "firstdeposit",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Перводеп комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Перводеп моб.jpeg"
    },
    {
      id: 8,
      name: "Кешбек 15%",
      position: 2,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Получай кешбек 15% к каждому своему пополнению!<br></p><p><i><br></i></p><p><i>*Кешбек начисляется после пополнения: вагер х10, кешаут 3&nbsp;</i></p><p></p>",
      relativeUrl: "cashback_15",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Кешбек 15 комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Кешбек 15 моб.jpeg"
    },
    {
      id: 10,
      name: "Счастливые часы",
      position: 4,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p>Супер кешбек в Счастливые часы! Каждый четверг и пятницу лови 20% кешбека к пополнению.&nbsp;</p><p><i><br></i></p><p><i>*Кешбек начисляется после пополнения: вагер х10, кешаут 3</i></p>",
      relativeUrl: "happy_time",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Счастливые часы комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Счастливые часы моб.jpeg"
    },
    {
      id: 11,
      name: "Однорукий бандит",
      position: 3,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        '<p>Принимай участие в чат-игре "Однорукий бандит" и выигрывай бонус - х2 к депозиту на основной счет! Игра регулярно проводится в основном чате, подпишись, чтобы не пропустить!&nbsp;</p>',
      relativeUrl: "banditgame",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/Однорукий бандит комп.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/Однорукий бандит моб.jpeg"
    }
  ]
};

export const testBalance = {
  currencies: {
    RUB: {
      decimal: 0,
      type: "fiat"
    },
    EUR: {
      decimal: 2,
      type: "fiat"
    },
    UZS: {
      decimal: 0,
      type: "fiat"
    },
    USDT: {
      decimal: 2,
      type: "crypto"
    }
  },
  currency: "RUB",
  balances: {
    main: 55,
    payout: 33.4456,
    bonus: 1000,
    depositWager: 0,
    bonusWager: 40000
  }
};
