import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ENUM_MODE_GAME } from "../store/enums";

export default function useCheckDemoMode({ currentGame: game, mode }) {
  const { t } = useTranslation();
  const excludes = useSelector((state) => state.games.excludeDemoMode);
  const isDemoMode = useMemo(() => mode === ENUM_MODE_GAME.demo, [mode]);
  const isExcludeGame = useMemo(
    () => isDemoMode && excludes.includes(game?.provider),
    [excludes, game, isDemoMode]
  );

  const avaibleDemo = useMemo(
    () => !excludes.includes(game?.providerName || game?.provider),
    [excludes, game]
  );
  const avaibleReal = useMemo(() => true, []);

  const message = useMemo(
    () =>
      t("Demo mode is not supported in games provided by provider.", {
        provider: game?.provider
      }),
    [game]
  );

  const errorMes = useMemo(() => {
    const errorMes = isExcludeGame ? message : false;
    return errorMes;
  }, [isExcludeGame, message]);

  return { errorMes, isExcludeGame, avaibleDemo, avaibleReal };
}
