import React from "react";

export default function IconCoin() {
  return (
    <svg
      width="30.239960"
      height="30.239929"
      viewBox="0 0 30.24 30.2399"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip128_9">
          <rect
            id="free-icon-dollar-10081145 1"
            width="30.239960"
            height="30.239960"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
        <linearGradient
          x1="3.150024"
          y1="21.104977"
          x2="27.089993"
          y2="21.104977"
          id="paint_linear_128_11_0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE8B36" />
          <stop offset="0.150000" stopColor="#FBD83F" />
          <stop offset="0.300000" stopColor="#EE8B36" />
          <stop offset="0.500000" stopColor="#FBD73F" />
          <stop offset="0.700000" stopColor="#EE8B36" />
          <stop offset="0.850000" stopColor="#FBD73F" />
          <stop offset="1.000000" stopColor="#EE8B36" />
        </linearGradient>
        <linearGradient
          x1="6.032905"
          y1="4.772888"
          x2="22.875298"
          y2="21.615288"
          id="paint_linear_128_13_0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAFF5A" />
          <stop offset="1.000000" stopColor="#FBD43D" />
        </linearGradient>
        <linearGradient
          x1="8.746840"
          y1="6.853110"
          x2="19.631969"
          y2="18.820580"
          id="paint_linear_128_14_0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBD38" />
          <stop offset="1.000000" stopColor="#EE8B36" />
        </linearGradient>
        <linearGradient
          x1="9.218091"
          y1="7.958138"
          x2="19.373676"
          y2="18.113731"
          id="paint_linear_128_15_0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAFF5A" />
          <stop offset="1.000000" stopColor="#FBD43D" />
        </linearGradient>
        <linearGradient
          x1="15.700783"
          y1="14.433271"
          x2="9.389448"
          y2="8.203840"
          id="paint_linear_128_18_0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFFF" stopOpacity="0.501961" />
          <stop offset="1.000000" stopColor="#FFFFFF" stopOpacity="0.000000" />
        </linearGradient>
        <linearGradient
          x1="9.449951"
          y1="13.859995"
          x2="20.789936"
          y2="13.859995"
          id="paint_linear_128_19_0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBD38" />
          <stop offset="1.000000" stopColor="#EE8B36" />
        </linearGradient>
        <linearGradient
          x1="15.700869"
          y1="14.433297"
          x2="9.389534"
          y2="8.203870"
          id="paint_linear_128_27_0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFFF" stopOpacity="0.501961" />
          <stop offset="1.000000" stopColor="#FFFFFF" stopOpacity="0.000000" />
        </linearGradient>
        <linearGradient
          x1="10.944374"
          y1="19.553919"
          x2="3.652763"
          y2="19.678658"
          id="paint_linear_128_28_0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFFF" stopOpacity="0.501961" />
          <stop offset="1.000000" stopColor="#FFFFFF" stopOpacity="0.000000" />
        </linearGradient>
      </defs>
      <rect
        id="free-icon-dollar-10081145 1"
        width="30.239960"
        height="30.239960"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <g clipPath="url(#clip128_9)">
        <path
          id="Vector"
          d="M27.09 13.86L27.09 16.37C27.09 20.41 25.07 23.93 22.05 26.2C21.04 26.83 20.03 27.46 18.9 27.84C17.76 28.22 16.37 28.47 15.12 28.47C13.86 28.47 12.47 28.09 11.34 27.72C10.2 27.34 9.19 26.83 8.18 26.08C5.16 23.93 3.15 20.28 3.15 16.25L3.15 13.73C3.15 7.18 8.56 1.76 15.12 1.76C21.67 1.76 27.09 7.3 27.09 13.86Z"
          fill="#FCCD3B"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M24.82 20.79C24.06 21.92 23.05 22.8 22.05 23.56C21.04 24.19 20.03 24.82 18.9 25.2C17.76 25.57 16.37 25.83 15.12 25.83C13.86 25.83 12.47 25.57 11.34 25.2C10.2 24.82 9.19 24.31 8.18 23.56C7.18 22.8 6.17 21.79 5.41 20.79C4.03 18.9 3.15 16.5 3.15 13.86L3.15 16.37C3.15 20.41 5.16 23.93 8.18 26.2C9.19 26.83 10.2 27.46 11.34 27.84C12.47 28.22 13.86 28.47 15.12 28.47C16.37 28.47 17.76 28.22 18.9 27.84C20.03 27.46 21.04 26.96 22.05 26.2C25.07 24.06 27.09 20.41 27.09 16.37L27.09 13.86C27.09 16.5 26.2 18.9 24.82 20.79Z"
          fill="url(#paint_linear_128_11_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M15.12 1.89C8.56 1.89 3.15 7.3 3.15 13.86C3.15 20.41 8.56 25.83 15.12 25.83C21.67 25.83 27.09 20.41 27.09 13.86C27.09 7.3 21.67 1.89 15.12 1.89ZM15.12 23.31C9.95 23.31 5.67 19.02 5.67 13.86C5.67 8.69 9.95 4.41 15.12 4.41C20.28 4.41 24.57 8.69 24.57 13.86C24.57 19.02 20.28 23.31 15.12 23.31Z"
          fill="url(#paint_linear_128_13_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M15.11 4.4C9.95 4.4 5.66 8.69 5.66 13.86C5.66 19.02 9.95 23.3 15.11 23.3C20.28 23.3 24.56 19.02 24.56 13.86C24.56 8.69 20.28 4.4 15.11 4.4ZM15.11 22.04C10.58 22.04 6.92 18.39 6.92 13.86C6.92 9.32 10.58 5.66 15.11 5.66C19.65 5.66 23.3 9.32 23.3 13.86C23.3 18.39 19.65 22.04 15.11 22.04Z"
          fill="url(#paint_linear_128_14_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M23.3 13.86C23.3 18.38 19.64 22.05 15.11 22.05C10.59 22.05 6.92 18.38 6.92 13.86C6.92 9.33 10.59 5.66 15.11 5.66C19.64 5.66 23.3 9.33 23.3 13.86Z"
          fill="url(#paint_linear_128_15_0)"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <g opacity="0.150000">
          <path
            id="Vector"
            d="M15.49 22.42C20.03 22.42 23.68 18.77 23.68 14.23C23.68 11.84 22.67 9.82 21.16 8.31C22.55 9.82 23.43 11.71 23.43 13.98C23.43 18.52 19.78 22.17 15.24 22.17C13.1 22.17 11.08 21.29 9.57 19.9C10.96 21.42 13.1 22.42 15.49 22.42Z"
            fill="#000000"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </g>
        <g opacity="0.150000">
          <path
            id="Vector"
            d="M6.04 14.23C6.04 9.07 10.33 4.78 15.49 4.78C18.01 4.78 20.28 5.79 22.05 7.43C20.28 5.66 17.89 4.53 15.24 4.53C10.08 4.53 5.79 8.82 5.79 13.98C5.79 16.63 6.93 19.15 8.69 20.79C6.93 19.02 6.04 16.75 6.04 14.23Z"
            fill="#000000"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </g>
        <path
          id="Vector"
          d="M15.11 5.66C10.58 5.66 6.92 9.32 6.92 13.86C6.92 16.37 8.18 18.77 9.95 20.28L21.41 8.82C20.03 6.92 17.63 5.66 15.11 5.66Z"
          fill="url(#paint_linear_128_18_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M18.26 13.23L11.96 13.23C11.21 13.23 10.71 12.72 10.71 11.97C10.71 11.21 11.21 10.71 11.96 10.71L18.26 10.71C19.02 10.71 19.52 11.21 19.52 11.97C19.52 12.34 19.78 12.6 20.15 12.6C20.53 12.6 20.78 12.34 20.78 11.97C20.78 10.58 19.65 9.45 18.26 9.45L15.74 9.45L15.74 8.82C15.74 8.44 15.49 8.18 15.11 8.18C14.74 8.18 14.48 8.44 14.48 8.82L14.48 9.45L11.96 9.45C10.58 9.45 9.44 10.58 9.44 11.97C9.44 13.35 10.58 14.49 11.96 14.49L18.26 14.49C19.02 14.49 19.52 14.99 19.52 15.75C19.52 16.5 19.02 17.01 18.26 17.01L11.96 17.01C11.21 17.01 10.71 16.5 10.71 15.75C10.71 15.37 10.45 15.12 10.08 15.12C9.7 15.12 9.44 15.37 9.44 15.75C9.44 17.13 10.58 18.27 11.96 18.27L14.48 18.27L14.48 18.9C14.48 19.27 14.74 19.53 15.11 19.53C15.49 19.53 15.74 19.27 15.74 18.9L15.74 18.27L18.26 18.27C19.65 18.27 20.78 17.13 20.78 15.75C20.78 14.36 19.65 13.23 18.26 13.23Z"
          fill="url(#paint_linear_128_19_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <g opacity="0.150000">
          <path
            id="Vector"
            d="M18.27 13.23L11.97 13.23C11.71 13.23 11.46 13.1 11.21 12.97C11.46 13.35 11.84 13.48 12.22 13.48L18.52 13.48C19.15 13.48 19.65 13.73 20.16 14.11C19.78 13.6 19.02 13.23 18.27 13.23Z"
            fill="#000000"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </g>
        <g opacity="0.150000">
          <path
            id="Vector"
            d="M19.9 16.12C19.9 15.75 19.65 15.37 19.4 15.12C19.53 15.37 19.65 15.49 19.65 15.87C19.65 16.63 19.15 17.13 18.39 17.13L12.09 17.13C11.84 17.13 11.59 17.01 11.34 16.88C11.59 17.26 11.97 17.38 12.34 17.38L18.64 17.38C19.27 17.38 19.9 16.75 19.9 16.12Z"
            fill="#000000"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </g>
        <g opacity="0.150000">
          <path
            id="Vector"
            d="M19.27 11.21C19.4 11.46 19.53 11.59 19.53 11.97C19.53 12.22 19.65 12.47 19.9 12.6C19.9 12.47 19.9 12.47 19.9 12.34C19.9 11.84 19.65 11.46 19.27 11.21Z"
            fill="#000000"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </g>
        <g opacity="0.150000">
          <path
            id="Vector"
            d="M10.33 13.86C9.95 13.48 9.7 12.85 9.7 12.22C9.7 10.83 10.83 9.7 12.22 9.7L14.74 9.7L14.74 9.19C14.74 8.82 14.99 8.56 15.37 8.56L15.62 8.56C15.49 8.31 15.24 8.18 14.99 8.18C14.61 8.18 14.36 8.44 14.36 8.82L14.36 9.45L11.84 9.45C10.45 9.45 9.32 10.58 9.32 11.97C9.44 12.72 9.82 13.48 10.33 13.86Z"
            fill="#000000"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </g>
        <g opacity="0.150000">
          <path
            id="Vector"
            d="M16.12 9.82L18.64 9.82C19.27 9.82 19.78 10.08 20.28 10.45C19.78 9.95 19.15 9.57 18.39 9.57L16.12 9.57L16.12 9.82Z"
            fill="#000000"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </g>
        <g opacity="0.150000">
          <path
            id="Vector"
            d="M14.86 19.27L14.86 18.64L14.49 18.64L14.49 18.9C14.49 19.15 14.61 19.4 14.86 19.53C14.86 19.4 14.86 19.27 14.86 19.27Z"
            fill="#000000"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </g>
        <g opacity="0.150000">
          <path
            id="Vector"
            d="M9.82 16.12C9.82 15.75 10.08 15.49 10.45 15.49L10.71 15.49C10.58 15.24 10.33 15.12 10.08 15.12C9.7 15.12 9.44 15.37 9.44 15.75C9.44 16.5 9.82 17.26 10.33 17.64C9.95 17.26 9.82 16.63 9.82 16.12Z"
            fill="#000000"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </g>
        <path
          id="Vector"
          d="M15.12 1.89C8.56 1.89 3.15 7.3 3.15 13.86C3.15 17.51 4.78 20.66 7.3 22.93L9.07 21.16C6.93 19.4 5.67 16.75 5.67 13.86C5.67 8.69 9.95 4.41 15.12 4.41C18.01 4.41 20.66 5.79 22.42 7.81L24.19 6.04C21.92 3.52 18.77 1.89 15.12 1.89Z"
          fill="url(#paint_linear_128_27_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M5.41 20.79C4.03 18.9 3.15 16.5 3.15 13.86L3.15 16.37C3.15 20.03 4.78 23.18 7.3 25.45L7.3 22.93C6.55 22.3 5.92 21.54 5.41 20.79Z"
          fill="url(#paint_linear_128_28_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
