import React from "react";

export default function IconBanknote() {
  return (
    <svg
      width="31.000000"
      height="30.000000"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip128_39">
          <rect
            id="free-icon-withdrawal-9721980 1"
            rx="0"
            width="30.000000"
            height="29.000000"
            transform="translate(0.500000 0.500000)"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <rect
        id="free-icon-withdrawal-9721980 1"
        rx="0"
        width="30.000000"
        height="29.000000"
        transform="translate(0.500000 0.500000)"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <g clipPath="url(#clip128_39)">
        <path
          id="Vector"
          d="M3.06 1.75L28.42 1.75C29.84 1.75 30.99 2.87 30.99 4.24L30.99 10.09C30.99 11.47 29.84 12.59 28.42 12.59L3.06 12.59C1.65 12.59 0.49 11.47 0.49 10.09L0.49 4.24C0.49 2.87 1.65 1.75 3.06 1.75Z"
          fill="#3E5959"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M25.27 7.17L25.27 27.49C25.27 27.91 24.92 28.24 24.49 28.24L6.99 28.24C6.56 28.24 6.22 27.91 6.22 27.49L6.22 7.17L25.27 7.17Z"
          fill="#70DA40"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M8.69 7.17L22.79 7.17L22.79 22.54C20.9 22.54 19.38 24.02 19.38 25.85L12.11 25.85C12.11 24.02 10.58 22.54 8.69 22.54L8.69 7.17L8.69 7.17Z"
          fill="#1FBF66"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M15.74 20.5C16.69 20.5 17.45 21.24 17.45 22.16C17.45 23.07 16.69 23.81 15.74 23.81C14.8 23.81 14.03 23.07 14.03 22.16C14.03 21.24 14.8 20.5 15.74 20.5Z"
          fill="#70DA40"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M17.22 7.17C17.37 7.41 17.45 7.69 17.45 8C17.45 8.91 16.69 9.65 15.74 9.65C14.8 9.65 14.03 8.91 14.03 8C14.03 7.69 14.12 7.41 14.27 7.17L17.22 7.17Z"
          fill="#70DA40"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M15.74 18.5C17.69 18.5 19.28 16.96 19.28 15.08C19.28 13.2 17.69 11.66 15.74 11.66C13.79 11.66 12.21 13.2 12.21 15.08C12.21 16.96 13.79 18.5 15.74 18.5Z"
          fill="#70DA40"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M4.01 7.64C3.74 7.64 3.52 7.43 3.52 7.17C3.52 6.9 3.74 6.69 4.01 6.69L27.47 6.69C27.75 6.69 27.97 6.9 27.97 7.17C27.97 7.43 27.75 7.64 27.47 7.64L4.01 7.64Z"
          fill="#2D4141"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M13.71 15.55C13.44 15.55 13.22 15.34 13.22 15.08C13.22 14.82 13.44 14.6 13.71 14.6L13.94 14.6C14.05 14.34 14.25 14.09 14.54 13.9C14.63 13.85 14.73 13.8 14.84 13.75C15.09 13.65 15.38 13.77 15.48 14.01C15.59 14.25 15.46 14.53 15.21 14.63C15.17 14.65 15.12 14.67 15.08 14.7C14.95 14.78 14.87 14.9 14.83 15.01C14.8 15.13 14.81 15.25 14.85 15.35C14.86 15.37 14.87 15.39 14.89 15.41C14.89 15.42 14.9 15.43 14.91 15.44C14.94 15.47 14.97 15.48 15 15.49C15.03 15.49 15.05 15.49 15.07 15.47C15.09 15.46 15.1 15.46 15.1 15.45C15.14 15.41 15.18 15.26 15.22 15.1C15.3 14.77 15.38 14.42 15.69 14.13C15.81 14.02 15.95 13.94 16.1 13.89C16.3 13.82 16.51 13.81 16.72 13.86C16.93 13.9 17.13 14 17.29 14.16C17.41 14.28 17.5 14.43 17.56 14.6L17.57 14.6L17.77 14.6C18.04 14.6 18.27 14.82 18.27 15.08C18.27 15.34 18.04 15.55 17.77 15.55L17.58 15.55C17.57 15.58 17.57 15.61 17.56 15.63C17.43 15.96 17.19 16.23 16.81 16.36C16.78 16.37 16.74 16.37 16.71 16.38C16.44 16.45 16.18 16.29 16.11 16.04C16.05 15.78 16.21 15.52 16.47 15.46C16.48 15.46 16.48 15.46 16.49 15.46C16.56 15.43 16.61 15.38 16.63 15.31C16.67 15.2 16.68 15.07 16.65 14.95C16.64 14.94 16.64 14.92 16.63 14.9C16.62 14.87 16.61 14.85 16.59 14.83C16.57 14.81 16.54 14.79 16.51 14.79C16.48 14.78 16.44 14.78 16.41 14.79C16.4 14.8 16.38 14.8 16.37 14.81C16.27 14.91 16.22 15.12 16.17 15.31C16.1 15.61 16.04 15.88 15.81 16.11C15.74 16.17 15.68 16.23 15.61 16.27C15.38 16.42 15.12 16.46 14.87 16.43C14.63 16.4 14.4 16.29 14.23 16.12C14.16 16.06 14.11 16 14.06 15.93C14.02 15.86 13.98 15.79 13.95 15.72C13.92 15.66 13.9 15.61 13.89 15.55L13.71 15.55L13.71 15.55Z"
          fill="#1FBF66"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M1.85 16.48C1.85 16.22 2.07 16 2.34 16C2.61 16 2.83 16.22 2.83 16.48L2.83 22.85L3.85 21.87C4.04 21.69 4.35 21.69 4.55 21.87C4.74 22.06 4.74 22.36 4.55 22.55L2.69 24.34C2.5 24.53 2.19 24.53 2 24.34L0.14 22.55C-0.05 22.36 -0.05 22.06 0.14 21.87C0.33 21.69 0.64 21.69 0.84 21.87L1.85 22.85L1.85 16.48L1.85 16.48Z"
          fill="#CFCFCF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
