import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDataLogin, setModalLogin } from "../store/siteSlice";

export default function useLoginAction() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);

  const onLoginAction = useCallback(
    (cb) => {
      if (!isAuth) {
        dispatch(setModalLogin(true));
        dispatch(setDataLogin({ cb }));
        return;
      }

      cb();
    },
    [isAuth]
  );

  return { onLoginAction };
}
