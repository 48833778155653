import axios from "axios";
import { TEST_MODE } from "../store/enums";

export const API_URL = "/api";
export const PREFIX = TEST_MODE ? "https://dev777.cryptoslot.games/" : "";

axios.interceptors.response.use((response) => {
  if (response.data.message === "Unauthenticated.") {
    localStorage.removeItem("user_id");
    localStorage.clear();
    window.location.href = "/";
  }
  return response;
});

export const cusAxios = (data) =>
  new Promise((resolve, reject) => {
    axios(data)
      .then((response) => {
        const dataResp = response.data;

        if (+dataResp.errorCode === 1) {
          window.dispatchEvent(new CustomEvent("session-error"));
          reject(response);
        }
        resolve(response);
      })
      .catch(reject);
  });

// export const thenMessageServer = (e) => {
//   const messages = messageByText();

//   const data = e.data;
//   if (!data) return errorToast(messages.unexpected);

//   if (data.status || data.ok) {
//     if (data.message) successToast(data.message);
//     return;
//   }

//   return errorToast(data.message || messages.unknown);
// };

// export const catchMessageServer = (e) => {
//   if (typeof e === "string") return errorToast(e);
//   const messages = messageByText();

//   if (!e.response?.data) return errorToast(messages.notRespond);
//   return errorToast(e.response?.data?.message || messages.unknown);
// };
