import React from "react";
import { useSelector } from "react-redux";

export default function TermsEn() {
  const siteName = useSelector((state) => state.common.casinoName);

  return (
    <div className="page-content">
      <div className="wrapper">
        <div className="page-content-terms-of-use">
          <h1>User Agreement</h1>
          <h3>1. Introduction</h3>
          <p>
            1.1. By visiting any of the sections of the {siteName} casino (“Bot”, “Site”, “App”),
            the user automatically agrees with all the clauses of the User Agreement (“Agreement”),
            Privacy Policy, advertising terms, game rules, bonuses and special offers that appear in
            {siteName} from time to time. If the player does not want or cannot agree with these
            conditions and comply with them, then he should not use the services of {siteName}. The
            use of the {siteName} bot, website or gaming application will be regarded as the
            player's full acceptance of all the clauses of this Agreement.
          </p>
          <p>&nbsp;</p>
          <h2>General terms and conditions</h2>
          <h3>2. Sides</h3>
          <p>
            2.1. All clauses of the Agreement, including the pronouns “we”, “us”, “our” or “{siteName}”, “company”, “bot”, “website”, refer to the company TCS Casino Games LTD. | Registration no. J23/4554/2019 | Greentube Malta Limited, W Business Centre, Level 4, Dun Karm Street, Birkirkara BKR 9033, Malta | License # L1203425W001180 (01.03.2020- 28.02.2030) | D. authorization: 306/28.02.2023 (01.03.2023-29.02.2024) with which the player enters into an agreement, in accordance with the paragraph above.
          </p>
          <p>&nbsp;</p>
          <h3>3. Changing conditions</h3>
          <p>
            3.1. We have the right to make any changes, edit, update and change the Agreement for a
            number of reasons: commercial, legal, as well as for reasons related to customer
            service. The current clauses of the Agreement and the dates of their entry into force
            are present in the bot, website, applications. We inform players about all changes,
            amendments and additions by posting the text of the amended Agreement in the bot,
            website, applications (hereinafter referred to as the {siteName} service). The Player is
            personally responsible for familiarization with the current Agreement. The Company has
            the right to make changes to the Agreement and its {siteName} services without prior
            informing the players.
          </p>
          <p>&nbsp;</p>
          <p>
            3.2. In case of disagreement with the amended clauses of the Agreement, the player has
            the right to stop using the {siteName} services. Further use of the {siteName} service
            after the amended clauses of the Agreement come into force will be regarded as its full
            acceptance, regardless of whether the player received a corresponding notification or
            learned about the changes from the updated Agreement.
          </p>
          <p>&nbsp;</p>
          <h3>4. Legal Requirements</h3>
          <p>
            4.1. Persons under the age of 18 or the age (“Legal Age”), which is mandatory for legal
            participation in gambling under the laws of a particular jurisdiction, are prohibited
            from using the {siteName} service. The use of the {siteName} service by persons under
            the Legal Age is a direct violation of the Agreement. In this regard, we have the right
            to request documents that can confirm the age of the player. A player may be denied
            services and their account may be temporarily suspended unless, upon our request, proof
            is provided that the player is of legal age.
          </p>
          <p>&nbsp;</p>
          <p>
            4.2. Online gambling is prohibited by law in some jurisdictions. By accepting the
            Agreement, the player is aware that the Company cannot provide guarantees or legal
            advice regarding the legitimacy of using the {siteName} service in the territory of the
            jurisdiction in which the player is located. We cannot claim that the company's services
            do not violate the laws of the player's jurisdiction. The player uses the services of
            the company of his own free will and assumes full responsibility, being well aware of
            all possible risks.
          </p>
          <p>&nbsp;</p>
          <p>
            4.3. We do not seek and do not intend to provide the player with services that violate
            the laws of his jurisdiction. By accepting the Agreement, the player confirms and
            guarantees that the use of the {siteName} service complies with the laws and regulations
            in force in the territory of his jurisdiction.
          </p>
          <p>&nbsp;</p>
          <p>
            4.4. The players are fully responsible for paying taxes and fees that apply to any
            winnings received as a result of using the {siteName} service.
          </p>
          <p>&nbsp;</p>
          <p>
            4.5. For legal reasons, we do not accept players from the following countries: Iran,
            North Korea, Sudan, Syria, China, Iraq, Pakistan, Yemen, Turkmenistan, Ghana, Paraguay,
            Mauritius, Myanmar, Ethiopia, Nepal, Peru, Benin, Costa Rica, Ecuador, Haiti,
            Madagascar, Go, Uruguay, Aland Islands, Bhutan, Botswana, Cambodia, Cameroon, Equatorial
            Guinea, Kuwait, Mali, Zambia, Liechtenstein, American Samoa, Andorra, Anguilla,
            Antarctica, Bermuda, Bonaire, Sint Eustatius and Saba , Bouvet Island, British Indian
            Ocean Territory, Burkina Faso, Burundi, Cape Verde, Central African Republic, Chad,
            Christmas Island, Cocos (Keeling) Islands, Comoros, Cook Islands, Ivory Coast, Curaçao,
            Djibouti, Dominica, El Salvador , Eritrea, Eswatini, Falkland Islands, Faroe Islands,
            Fiji, French Guiana, French Polynesia, French Southern Territories, Gabon, Gambia,
            Gibraltar, Grenada, Guadeloupe, Guam, Guatemala, Guernsey, Guinea-Bissau, Heard Island
            and McDonald's Islands, Saint Throne, Honduras, Iran, Isle of Man, Jamaica, Jersey,
            Kiribati, Lao People's Democratic Republic, Lesotho, Liberia, Malawi, Marshall Islands,
            Martinique, Mauritania, Mayotte, Micronesia, Montserrat, Mozambique, Nauru, New
            Caledonia, Niger, Niue , Norfolk Island, Northern Mariana Islands, Palau, Palestine,
            State, Papua New Guinea, Pitcairn, Reunion, Rwanda, Saint Barthelemy, Saint Helena,
            Ascension and Tristan da Cunha, Saint Kitts and Nevis, Saint Lucia , Saint Martin
            (French part), Saint Pierre and Miquelon, Saint Vincent and the Grenadines, Samoa, San
            Marino, Sao Tome and Principe, Senegal, Sierra Leone, Sint Maarten (Dutch part), Solomon
            Islands, South Georgia and the South Sandwich Islands, South Sudan, Sudan, Suriname,
            Svalbard and Jan Mayen, Syrian Arab Republic, Tanzania, United Republic, Timor-Leste,
            Tokelau, Tonga, Turks and Caicos Islands, Tuvalu, Vanuatu, Wallis and Futuna, Zimbabwe,
            Cayman Islands. You agree that you will not open an account and use {siteName} services
            or transfer funds to it if you are a resident of one of the above jurisdictions.
          </p>
          <p>&nbsp;</p>
          <h3>5. Opening an account</h3>
          <p>
            5.1. To get access to all the main services of {siteName}, you need to open an account.
            Depending on the {siteName} service used, the player needs to complete the steps to
            register a game account, for example, provide personal information: phone number and
            date of birth. In the Telegram bot, the registration of a game account occurs
            automatically after the player starts the bot with the /start command.
          </p>
          <p>&nbsp;</p>
          <p>
            5.2. To confirm the truth of the information, the Company has the right to request
            documents confirming the identity of the player. If, for some reason, the player is
            unable to present documents confirming his identity, then the Company has the right to
            suspend the player's account until he presents the documents.
          </p>
          <p>&nbsp;</p>
          <p>
            5.3. The Player confirms that when registering with {siteName}, he provided complete,
            accurate and reliable information about himself, and if there are any changes in it, the
            Player will immediately add them to his profile. Failure or disregard for this policy
            may result in restrictions, account suspension or blocking, and payment cancellations.
          </p>
          <p>&nbsp;</p>
          <p>
            5.4. In case of any questions or problems during registration, the player can contact
            the support team in the telegram chat mode @{siteName}_games.
          </p>
          <p>&nbsp;</p>
          <p>
            5.5. Each player can open only one account in the {siteName} service. The remaining
            accounts opened by the player will be considered as duplicate. We have the right to
            close such accounts and:
          </p>
          <p>&nbsp;</p>
          <p>5.5.1. invalidate all operations carried out using a duplicate account;</p>
          <p>&nbsp;</p>
          <p>
            5.5.2. do not return to players all deposits and bets made from a duplicate account;
          </p>
          <p>&nbsp;</p>
          <p>
            5.5.3. demand the return of all winnings, bonuses and funds received when using a
            duplicate account. The player is obliged to return them at our first request.
          </p>
          <p>&nbsp;</p>
          <h3>6. ID confirmation; money laundering protection</h3>
          <p>6.1. By accepting this Agreement, the player undertakes, agrees and warrants that:</p>
          <p>&nbsp;</p>
          <p>
            6.1.1. the player is at least 18 years of age, or sufficient to be considered the Legal
            Age for the legal right to participate in gambling without violating the current laws of
            their jurisdiction;
          </p>
          <p>&nbsp;</p>
          <p>
            6.1.2. the player is the full and legal owner of all funds on his account. All
            information provided by the player is true, reliable, up-to-date and accurate;
          </p>
          <p>&nbsp;</p>
          <p>
            6.1.3. the player is fully aware that the use of the {siteName} service may lead to the
            loss of funds, the player assumes full responsibility for any possible losses. The
            player confirms that he uses the {siteName} service at his own will, decision and at his
            own peril and risk. The Player does not have the right to present any claims to the
            Company related to his losses and losses;
          </p>
          <p>&nbsp;</p>
          <p>
            6.1.4. the player clearly understands the general principles, rules and procedures for
            the provision of services and the specifics of the game on the Internet. The player
            acknowledges that it is his responsibility to ensure that data, bets and games are
            correct. The Player agrees not to perform actions and deeds that may harm the reputation
            of the Company.
          </p>
          <p>&nbsp;</p>
          <p>
            6.2. By accepting the terms of the Agreement, the player gives us the right to verify
            the accuracy of the information specified by the player in the Account Profile.
          </p>
          <p>&nbsp;</p>
          <p>
            6.3. During the period of checks, the ability to withdraw funds from the player's
            account may be limited.
          </p>
          <p>&nbsp;</p>
          <p>
            6.4. If during the verification it is established that the information provided is
            false, this will be regarded as a violation of the terms of the Agreement, which in turn
            gives us the right to close the player's account or refuse the player to use the
            {siteName} service, in addition to other actions at our discretion.
          </p>
          <p>&nbsp;</p>
          <p>
            6.5. If we are unable to verify that a player is of legal age, then we will have the
            right to suspend the player's account.
          </p>
          <p>&nbsp;</p>
          <h3>7. Username, password and security</h3>
          <p>
            7.1. After opening an account with {siteName}, the player is obliged to keep his
            password and username in secret and in no case disclose this information to a third
            party. In the case of using the telegram bot {siteName}, the player undertakes not to
            provide access to his account in the Telegram messenger to third parties.
          </p>
          <p>&nbsp;</p>
          <p>
            7.2. The Player is responsible for the safety of the password, as well as any actions
            and transactions made with his account. In addition, the player is responsible for all
            losses incurred by him as a result of the actions of a third party.
          </p>
          <p>&nbsp;</p>
          <p>
            7.3. In the event of a security breach and unauthorized access to the account, the
            player must immediately notify the Company. If necessary, the player is obliged to
            provide the Company with evidence that unauthorized access actually took place. The
            company is not responsible for damages incurred by the player as a result of incorrect
            or careless use of the username and password by a third party or for unauthorized access
            to the account.
          </p>
          <p>&nbsp;</p>
          <h3>8. Placing and withdrawing funds from the account</h3>
          <p>
            8.1. In order to take part in {siteName} gambling, the player needs to deposit a certain
            amount of money on his account.
          </p>
          <p>&nbsp;</p>
          <p>8.2. The Player acknowledges and undertakes that:</p>
          <p>&nbsp;</p>
          <p>8.2.1. the funds deposited into the game balance were not obtained illegally;</p>
          <p>&nbsp;</p>
          <p>
            8.2.2. will not refuse completed transactions, will not deny and reverse payments made,
            which may be the reason for the return of money to a third party.
          </p>
          <p>&nbsp;</p>
          <p>
            8.3. We do not accept funds from third parties: friends, relatives or partners. The
            player is obliged to deposit funds only from his account, payment card or system
            registered in the name of the player.
          </p>
          <p>&nbsp;</p>
          <p>
            8.4. In the case of a bank transfer request for the return of funds to their rightful
            owner, all costs and commissions are paid by the recipient.
          </p>
          <p>&nbsp;</p>
          <p>
            8.5. We do not accept payments in cash. We may use various electronic payment processors
            or financial institutions for electronic payments, including payments and payouts to
            players. Except in cases where the rules and conditions of these institutions do not
            contradict the terms of the Agreement, the player fully accepts such rules.
          </p>
          <p>&nbsp;</p>
          <p>
            8.6. The Player agrees not to refuse, cancel or cancel transactions made with his
            account. In addition, in each of these cases, the player is obliged to return or
            compensate us for the amount of unplaced funds, including expenses that we may incur in
            collecting the player's deposits.
          </p>
          <p>&nbsp;</p>
          <p>
            8.7. We have the right to block a player's account, as well as cancel payments and
            collect winnings, if we suspect that a deposit has been made fraudulently. We have the
            right to notify the appropriate authorities of fraudulent payments and illegal
            activities. We have the right to use collection agencies to recover payments. The
            company is not responsible for the unauthorized use of credit cards, regardless of
            whether they were reported stolen or not.
          </p>
          <p>&nbsp;</p>
          <p>
            8.8. We have the right to use the positive balance of the player's account to settle the
            amount of money that the player must reimburse the Company, including in cases of
            repeated bets or clause 14 (“Errors and omissions”).
          </p>
          <p>&nbsp;</p>
          <p>
            8.9. The Player is fully aware and accepts the fact that the game account is not a bank
            account. Therefore, in relation to it, insurance methods, replenishment, guarantees and
            other methods of protection from the deposit insurance system and other insurance
            systems do not apply. No interest is accrued on the funds on the gaming account.
          </p>
          <p>&nbsp;</p>
          <p>
            8.10. The player has the right to submit a request to withdraw money from the account,
            provided that:
          </p>
          <p>&nbsp;</p>
          <p>
            8.10.1. all payments received on the account have been verified, and none of them have
            been canceled or canceled;
          </p>
          <p>&nbsp;</p>
          <p>
            8.10.2. the verification activities referred to in section 6 were carried out properly.
          </p>
          <p>&nbsp;</p>
          <p>
            8.11. When applying for a withdrawal of funds from your account, it is important to
            consider the following points:
          </p>
          <p>&nbsp;</p>
          <p>
            8.11.1. the game profile is completely filled out, and the mail indicated in it is
            confirmed. In the case of using the {siteName} bot, this requirement does not apply;
          </p>
          <p>&nbsp;</p>
          <p>
            8.11.2. funds must be withdrawn in the same way that was used to deposit them into the
            account;
          </p>
          <p>&nbsp;</p>
          <p>
            8.11.3. if the amount requested for withdrawal exceeds 1000 USD, then the withdrawal is
            possible only to the player's bitcoin wallet;
          </p>
          <p>&nbsp;</p>
          <p>
            8.11.4. if the turnover of funds (the amount of bets) from the main balance of the
            player at the time of the application for withdrawal is less than double the size of the
            deposit, then the withdrawal is not possible.
          </p>
          <p>To withdraw, you need to bet twice the amount of the deposit.</p>
          <p>&nbsp;</p>
          <p>
            8.11.5. In the turnover of bets, only those bets that were made by the player in slot
            games are taken into account, bets in the risk game are not taken into account.
          </p>
          <p>&nbsp;</p>
          <p>
            8.12. We have the right to withhold a commission in the amount of our costs for
            withdrawing funds not involved in the game.
          </p>
          <p>&nbsp;</p>
          <p>
            8.13. The withdrawal amount up to 1000 USD is paid to the client's account from 10
            minutes to 2 days from the moment of submission of applications, excluding weekends and
            holidays.
          </p>
          <p>&nbsp;</p>
          <p>
            8.14. The withdrawal amount from 1000 to 3000 USD is paid to the client's account within
            5 days from the date of submission of applications, excluding weekends and holidays.
          </p>
          <p>&nbsp;</p>
          <p>
            8.15. The withdrawal amount from 3,000 USD to 10,000 USD is paid to the client's account
            within 14 days from the date of submission of applications, excluding weekends and
            holidays.
          </p>
          <p>&nbsp;</p>
          <p>
            8.16. The withdrawal amount from 10,000 to 15,000 USD is paid to the client's account
            within 21 days from the date of application, excluding weekends and holidays.
          </p>
          <p>&nbsp;</p>
          <p>
            8.17. The withdrawal amount from 15000 USD is paid to the client's account within 30
            days from the date of application, except for weekends and holidays.
          </p>
          <p>&nbsp;</p>
          <p>8.18. The withdrawal amount cannot exceed 20,000 USD in 30 calendar days.</p>
          <p>&nbsp;</p>
          <p>
            8.19. Withdrawal terms are calculated from the moment of creation of the last withdrawal
            request.
          </p>
          <p>&nbsp;</p>
          <p>
            8.20. When making a withdrawal in rubles in the amount of more than 15,000 rubles,
            applications can be divided into several payments of 15,000 rubles each. This is the
            limit of the payment system with which the payment processing process takes place.
          </p>
          <p>&nbsp;</p>
          <p>
            8.21. The terms for withdrawing funds may be longer than those specified in paragraphs.
            8.13. - 8.17. due to the increased workload of the finance department, the identity
            verification procedure or other necessary checks. The Player can find out about the
            reasons for the withdrawal delay in each specific case in the support service.
          </p>
          <p>&nbsp;</p>
          <h3>9. Deposit and Withdrawal in Russian rubles</h3>
          <p>
            9.1. Operations of Deposit (replenishment) and Withdrawal (withdrawal) of funds from the
            Main Balance occur at the current exchange rate of the Bitcoin cryptocurrency to the
            Russian ruble and may change at any time. The player agrees that exchange rate
            fluctuations are not a reason to reject transactions.
          </p>
          <p>&nbsp;</p>
          <p>10. Collusion, misleading acts, fraud and criminal activity</p>
          <p>
            10.1. The following activities are strictly prohibited, the commission of such actions
            will be regarded as a direct violation of the Agreement:
          </p>
          <p>&nbsp;</p>
          <p>10.1.1. transfer of information to a third party;</p>
          <p>&nbsp;</p>
          <p>10.1.2. illegal activities: fraud, use of malware and software bugs;</p>
          <p>&nbsp;</p>
          <p>
            10.1.3. fraudulent actions, which are the use of stolen, cloned or otherwise illegally
            obtained bank card data to replenish an account;
          </p>
          <p>&nbsp;</p>
          <p>
            10.1.4. participation in criminal activities, money laundering and other activities,
            participation in which may entail criminal liability;
          </p>
          <p>&nbsp;</p>
          <p>
            10.1.5. entry, attempted entry or intention to take part in a criminal conspiracy with
            another player while playing on the {siteName} service;
          </p>
          <p>&nbsp;</p>
          <p>
            10.2. We have the right to suspend, cancel or revoke payments or winnings associated
            with bonus funds in the event that we suspect that a player is intentionally abusing
            them.
          </p>
          <p>&nbsp;</p>
          <p>
            10.3. We have the right, without prior notice, to prohibit players from accessing the
            {siteName} services and block their accounts if they are suspected of fraudulent
            activity. In such cases, we are not responsible for the return and compensation of funds
            available on the accounts of such players. Players are required to fully cooperate with
            us in dealing with such cases.
          </p>
          <p>&nbsp;</p>
          <p>
            10.4. Players are prohibited from using the services and software of {siteName} to
            commit any fraudulent, illegal activities and transactions in accordance with the laws
            of a particular jurisdiction. If it is established or revealed that the player has
            committed such actions, the Company may suspend or block the player's account and
            withhold the funds available on it. In such cases, the players do not have the right to
            present any claims to the Company.
          </p>
          <p>&nbsp;</p>
          <h3>11. Other prohibited actions in {siteName} services</h3>
          <p>
            11.1. On {siteName} services it is forbidden to use an aggressive or offensive manner of
            communication, profanity, threats and use any violent actions against employees and
            other players.
          </p>
          <p>&nbsp;</p>
          <p>
            11.2. Players promise not to take actions aimed at hacking the security system of the
            {siteName} service, gaining illegal access to closed data or DDoS attacks. Players
            suspected of violating this rule will be subject to an account suspension and all funds
            will not be refunded.
          </p>
          <p>&nbsp;</p>
          <p>
            11.3. We are not responsible for losses and damages that may be incurred by our players
            or a third party due to technical failures provoked by virus attacks or other malicious
            actions directed against {siteName}.
          </p>
          <p>&nbsp;</p>
          <h3>12. Validity and cancellation of the contract</h3>
          <p>
            12.1. We have the right to delete a player's account without prior notice in the
            following cases:
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.1. the Company has decided to stop providing services to all players or a specific
            user;
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.2. the player's account is somehow linked to the previously deleted account for
            violation of the Agreement;
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.3. the player's account is associated with an account that is currently blocked, in
            which case we have the right to close the account, regardless of how it is connected,
            and completely block the credentials on these accounts;
          </p>
          <p>&nbsp;</p>
          <p>12.1.4. the player takes part in a criminal conspiracy or tries to hack the system;</p>
          <p>&nbsp;</p>
          <p>
            12.1.5. the player interferes with the operation of the {siteName} software or tries to
            manipulate it;
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.6. the player uses his account for purposes that may be regarded as illegal, in
            accordance with the laws of a particular jurisdiction;
          </p>
          <p>&nbsp;</p>
          <p>
            12.1.7. the player publishes information in {siteName} services that is offensive or
            humiliating.
          </p>
          <p>&nbsp;</p>
          <p>
            12.2. We have the right to close the player's account or cancel the Agreement by sending
            the player a notification by telegram or email to the address specified in the contact
            information. In the event of such action on our part, with the exception of the
            situations referred to in paragraphs 9 (“Collusion, misleading, fraudulent and criminal
            activity”) and 16 (“Breach of the terms”) of the current Agreement, we undertake to
            return to the player the funds held on the account.
          </p>
          <p>&nbsp;</p>
          <p>
            12.3. If the account is blocked or deleted, the refund of the funds that were on it at
            the time of closing is not made. No other funds can be credited or cashed out to it.
          </p>
          <p>&nbsp;</p>
          <p>
            12.4. In case of closing the account, neither party has any obligations towards the
            other.
          </p>
          <p>&nbsp;</p>
          <h3>13. Site changes</h3>
          <p>
            13.1. We have the right at any time, at our sole discretion, to make changes or
            supplement the services offered in the {siteName} services in order to maintain and
            update the resource.
          </p>
          <p>&nbsp;</p>
          <h3>14. System errors</h3>
          <p>
            14.1. If the Site fails during the game, the Company will try to correct the situation
            as soon as possible. We are not responsible for malfunctions in information technology
            tools resulting from the operation of the equipment used by players to access the Site,
            as well as for failures in the operation of Internet providers.
          </p>
          <p>&nbsp;</p>
          <h3>15. Mistakes and shortcomings</h3>
          <p>15.1. We have the right to limit or cancel any bets.</p>
          <p>&nbsp;</p>
          <p>
            15.2. If a player has used funds credited to their account as a result of an error to
            place bets and participate in a game, we may void such bets and any winnings derived
            from them. If such bets were paid out, then these amounts should be considered
            transferred to the player in trust, the player is obliged to return them at our first
            request.
          </p>
          <p>&nbsp;</p>
          <p>
            15.3. The company and service providers are not responsible for damages, including loss
            of winnings, resulting from errors on the part of the player or on our part.
          </p>
          <p>&nbsp;</p>
          <h3>16. Limitation of our liability</h3>
          <p>
            16.1. The player independently decides to use {siteName} services or not, and any
            actions and their consequences are the result of the player’s personal choice, made at
            his discretion at his own peril and risk.
          </p>
          <p>&nbsp;</p>
          <p>
            16.2. The operation of the {siteName} service is carried out in accordance with the
            clauses of this Agreement.
          </p>
          <p>&nbsp;</p>
          <p>
            16.3. We are not responsible for tort, negligence, loss, damage or loss beyond our
            ability to foresee at this time.
          </p>
          \<p>&nbsp;</p>
          <h3>17. Violation of the terms</h3>
          <p>
            17.1. The Player is obliged to reimburse the Company for any costs, claims and expenses
            (including legal costs) that may arise due to his violation of the terms of the
            Agreement.
          </p>
          <p>&nbsp;</p>
          <p>
            17.2. The Player agrees to fully indemnify, protect and defend the interests of the
            Company, its partners, employees and directors from any claims, liability, costs, losses
            and expenses arising from:
          </p>
          <p>&nbsp;</p>
          <p>17.2.1. violation by the player of the terms of the Agreement;</p>
          <p>&nbsp;</p>
          <p>17.2.2. violation by the player of laws and rights of third parties;</p>
          <p>&nbsp;</p>
          <p>
            17.2.3. obtaining access to {siteName} services by any other person using the player's
            identification data with or without his permission, or;
          </p>
          <p>&nbsp;</p>
          <p>17.2.4. to appropriate the winnings received in this way.</p>
          <p>&nbsp;</p>
          <p>17.3. If the player violates the terms of the Agreement, we have the right:</p>
          <p>&nbsp;</p>
          <p>
            17.3.1. notify the player that by his actions he violates the terms of the Agreement,
            and demand an immediate cessation of prohibited actions;
          </p>
          <p>&nbsp;</p>
          <p>17.3.2. temporarily suspend the player's account;</p>
          <p>&nbsp;</p>
          <p>17.3.3. block the player's account without prior notice;</p>
          <p>&nbsp;</p>
          <p>
            17.3.4. recover from the player's account the amount of payments, winnings or bonuses
            acquired as a result of the violation.
          </p>
          <p>&nbsp;</p>
          <h3>18. Intellectual Property Rights</h3>
          <p>
            18.1. All content of the {siteName} service is subject to copyright and other
            proprietary rights owned by the Company.
          </p>
          <p>&nbsp;</p>
          <p>
            18.2. Using the {siteName} service does not give the player any rights to intellectual
            property owned by the Company or a third party.
          </p>
          <p>&nbsp;</p>
          <p>
            18.3. Any use or reproduction of the trademark, trademarks, logos and other promotional
            materials presented in the {siteName} services is prohibited.
          </p>
          <p>&nbsp;</p>
          <h3>19. Personal Information</h3>
          <p>
            19.1. We are required to comply with data protection requirements in the form in which
            the Company uses personal information collected as a result of the use of the {siteName}
            services by the player. As such, we take our commitment to the use of players' personal
            data with the utmost seriousness. The company uses personal information provided by
            players in full compliance with the privacy policy.
          </p>
          <p>&nbsp;</p>
          <p>
            19.2. By providing personal information, players agree that we have the right to process
            their personal data for the purposes specified in the Agreement or to comply with
            regulatory and legal obligations.
          </p>
          <p>&nbsp;</p>
          <p>
            19.3. We are committed not to disclose the personal information of our players to anyone
            other than employees who will use it solely to provide services.
          </p>
          <p>&nbsp;</p>
          <h3>20. Use of Cookies</h3>
          <p>
            20.1. We use cookies to provide functionality. A "cookie" is a special small text file
            that is stored on the user's computer when visiting the {siteName} service, with its
            help we can recognize specific users again when they visit again.
          </p>
          <p>&nbsp;</p>
          <h3>21. Complaints and notices</h3>
          <p>
            21.1. If you have any complaints and claims regarding the operation of the {siteName}
            service, first of all, you should send your claim to the support service as soon as
            possible.
          </p>
          <p>&nbsp;</p>
          <p>
            21.2. The player agrees that the results of the games are determined by the operation of
            a random number generator. It generates events in random order. If discrepancies arise
            between the results of the game on the player's device and the results on the server,
            then the results on the server will be considered more reliable. If there is a
            discrepancy in the display of the balance, preference is always given to the balance on
            the server. Money from the gaming account may be lost due to human error or technical
            failure.
          </p>
          <p>&nbsp;</p>
          <h3>22. Force majeure</h3>
          <p>
            22.1. The Company is not responsible for delays or failure to fulfill the obligations
            listed in the Agreement, in the event that they are due to force majeure, which should
            be understood as natural disasters, wars, civil unrest, industrial disputes,
            interruptions in public utility networks, DDoS attacks or other Internet attacks that
            can have a negative impact on the operation of the {siteName} service.
          </p>
          <p>&nbsp;</p>
          <p>
            22.2. During the period of force majeure circumstances, the activity of the {siteName}
            service is considered suspended, and during this period there is a delay in the
            performance of obligations. The company undertakes to use all means available to it to
            find solutions that allow it to fully fulfill its obligations to the players, up to the
            end of the force majeure circumstances.
          </p>
          <p>&nbsp;</p>
          <h3>23. Disclaimer</h3>
          <p>
            23.1. If we are unable to enforce any of the player's obligations, or we ourselves are
            unable to pursue any of the remedies to which we are entitled, then this should not be
            construed as a waiver of those remedies or as a reason, relieving the player of
            obligations.
          </p>
          <p>&nbsp;</p>
          <h3>24. Severability</h3>
          <p>
            24.1. If any of the clauses of the Agreement suddenly becomes invalid, illegitimate or
            unenforceable, then this provision will be separated from the rest of the Agreement,
            which will fully retain its legal force. In such cases, the part that is considered
            invalid will be changed according to the updated rules.
          </p>
          <p>&nbsp;</p>
          <h3>25. Links</h3>
          <p>
            25.1. {siteName} services may contain links to other resources that are beyond our
            control. We are not responsible for the content of other sites, the actions or omissions
            of their owners. Hyperlinks to other websites are provided for informational purposes
            only. Players use them at their own risk.
          </p>
          <p>&nbsp;</p>
          <h3>26. Bonuses</h3>
          <p>
            26.1. For registration and actions in the {siteName} service, the player can receive
            rewards in the form of bonuses. By accepting this Agreement, the player confirms that he
            understands the terms of each bonus that can be received by him in the {siteName}
            service, and accepts them.
          </p>
          <p>&nbsp;</p>
          <p>
            26.2. The game balance of each player is divided into two parts: the main balance and
            the bonus balance. The main balance displays the player's money, the bonus one - the
            amount of bonuses received. All bonus funds are automatically credited to the bonus
            balance, unless otherwise specified in the conditions of the bonus promotion.
          </p>
          <p>&nbsp;</p>
          <p>
            26.3. Bonus wagering is the amount of bets that a player needs to make in order for the
            bonus funds to be transferred to the main balance. When wagering bonuses in slots, 100%
            of the bets are taken into account. Bets in the risk game are not taken into account.
          </p>
          <p>&nbsp;</p>
          <p>
            26.4. When making bets, the player first wins back the amount of the received bonus. The
            wagering of the deposit (the condition under which the player needs to make bets for an
            amount twice the size of the deposit) occurs only when the bonus balance is 0.
          </p>
          <p>&nbsp;</p>
          <p>
            26.5. If the player has funds on both the main and the bonus balance, then all bets are
            primarily made from the funds on the bonus balance. Bonus funds are used for betting
            until the bonus balance becomes 0, or until the full wagering of each bonus separately
            and its transfer to the main balance. Funds from the main balance will be used for
            betting only if the bonus balance is 0.
          </p>
          <p>&nbsp;</p>
          <p>
            26.6. All winnings in games received by the player in the process of wagering the bonus
            go to the bonus balance and cannot be withdrawn until the bonus is fully wagered.
          </p>
          <p>&nbsp;</p>
          <p>
            26.7. If the amount of the bet exceeds the amount of funds on the bonus balance, then
            the missing funds will be taken from the real balance. In case of winning, the prize
            will be credited to the bonus balance. In case of loss, the bonus balance will become
            equal to 0, and the remaining difference will be debited from the real balance.
          </p>
          <p>&nbsp;</p>
          <p>
            26.8. In the process of wagering bonuses, the player can participate in other promotions
            and receive new bonuses. In this case, the amount of the new bonus will be added to the
            funds on the bonus balance. The conditions for wagering a new bonus will be individual
            in accordance with the terms of the promotion.
          </p>
          <p>
            If the bonus was received on a deposit, the deposit amount will be added to the funds on
            the main balance.
          </p>
          <p>&nbsp;</p>
          <p>
            26.9. Bonuses are awarded to the player automatically and do not require additional
            actions from him. The bonus is credited only if the player meets the requirements for
            receiving the bonus.
          </p>
          <p>&nbsp;</p>
          <p>
            26.10. You can withdraw funds only from the main balance, provided that there are no
            funds on the bonus account. If the payment for some reason did not take place, the funds
            can be returned to the main balance.
          </p>
        </div>
      </div>
    </div>
  );
}
