import React from "react";
import "./banner-item.scss";
import { useDispatch, useSelector } from "react-redux";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";
import { setBannerPostion } from "../../../../store/siteSlice";

const ENUM_GAME_TYPE = {
  INTEGRATED: 1,
  BONUS: 2
};

export default function BannerItem({
  id,
  gameId,
  gameType,
  fileUrl,
  mobileFileUrl,
  externalUrl,
  relativeUrl,
  postion
}) {
  const navigate = useNavigateWithSearch();
  const dispath = useDispatch();
  const isMobileSize = useSelector((state) => state.deviceSize.isMobileSize);

  const onClick = () => {
    const getUrl = () => {
      if (gameId && gameType) {
        if (gameType == ENUM_GAME_TYPE.INTEGRATED) return "/game/" + gameId;
        if (gameType == ENUM_GAME_TYPE.BONUS) return "/bonus_game/" + gameId;
      }
    };

    const url = getUrl();
    if (url) return navigate(url);

    if (relativeUrl) {
      dispath(setBannerPostion(postion));
      return navigate("/banner/" + relativeUrl);
    }
    if (externalUrl) return window.open(externalUrl, "_blank");
  };

  return (
    <div className="banner-item" id={id} onClick={onClick}>
      <div className="banner-item__content">
        <img className="banner-item__img" src={isMobileSize ? mobileFileUrl || fileUrl : fileUrl} />
        <div className="banner-item__text"></div>
      </div>
    </div>
  );
}
