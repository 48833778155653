import React from "react";
import DefaultModal from "../../DefaultModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./withdraw-result.scss";
import WithdrawInfoP from "../../../mui/withdraw_info_p/WithdrawInfoP";

export default function WithdrawSuccess({ isOpen, closeModal, info }) {
  const { t } = useTranslation();
  const supportChat = useSelector((state) => state.common.supportChat);
  // [x] - real
  const currency = info.currency;
  const requestNumber = info.invoiceNumber;
  const amount = info.amount;
  const commission = info.commission;
  const received = info.toReceive;
  const withdraw1 = "1 минуты";
  const withdraw2 = "24 часов";

  return (
    <DefaultModal closeModal={closeModal} isOpen={isOpen} classModal="withdraw-success">
      <h2>
        {t("Создана заявка")} <span className="accent">{requestNumber}</span>
        <br /> на вывод{" "}
        <span className="accent">
          {amount} {currency}
        </span>
      </h2>
      <div className="withdraw-success__items">
        <WithdrawInfoP
          condition={commission}
          text={"Withdrawal fee: "}
          accent={`${commission} ${currency}`}
        />
        <WithdrawInfoP
          condition={received}
          text={"To be received: "}
          accent={`${received} ${currency}`}
        />
      </div>
      <div className="withdraw-success__info">
        <p>
          {t("Withdrawal is carried out within the period from")} <span>{withdraw1}</span>{" "}
          {t("before")} <span>{withdraw2}</span>
        </p>
        <p>
          {t("In case of waiting above")} <span>{withdraw2}</span> - {t("please contact support")}{" "}
          <span className="withdraw-success__support">@{supportChat}</span>
        </p>
      </div>
    </DefaultModal>
  );
}
