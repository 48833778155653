import React from "react";
import { useTranslation } from "react-i18next";

const TEXT_EMPTY = "Fill in this field";
const NOT_VALID_EMAIL = "Enter a valid email address";
const TEXT_MIN_LENGTH = "Minimum field length ";
const MIN_LENGTH = "Minimum value ";
const MAX_LENGTH = "Maximum value ";

const STATUS = {
  isSubmit: true
};

const dispatchCuEvent = (message, target) => {
  const event = new CustomEvent("inputError", { detail: { message, target } });
  window.dispatchEvent(event);
  STATUS.isSubmit = false;
};

const checkMin = (input, t) => {
  const min = input.getAttribute("data-min");
  if (!min || !+input.value) return;
  if (+input.value < min) {
    dispatchCuEvent(t(MIN_LENGTH) + min, input);
  }
};

const checkMax = (input, t) => {
  const max = input.getAttribute("data-max");
  if (!max || !+input.value) return;
  if (+input.value > max) {
    dispatchCuEvent(t(MAX_LENGTH) + max, input);
  }
};

export default function CusForm({ children, className, onSubmit }) {
  const { t } = useTranslation();

  const onFormSubmit = (e) => {
    e.preventDefault();
    const inputFields = {};
    STATUS.isSubmit = true;

    const getInputs = (selector) => e.target.querySelectorAll(selector);

    // FIELD input
    getInputs("input").forEach((input) => {
      const name = input.getAttribute("name");
      inputFields[name] = input.value;
    });

    getInputs("input[data-type]").forEach((input) => {
      // CHECK_VALID_EMAIL
      if (input.getAttribute("data-type") === "email") {
        const value = input.value;
        if ((value.indexOf("@") < 0 || value.indexOf(".") < 0) && value !== "") {
          dispatchCuEvent(t(NOT_VALID_EMAIL), input);
        }
      } else if (input.getAttribute("data-type") === "number") {
        checkMin(input, t);
        checkMax(input, t);
      }
    });

    // CHECK_MIN_LENGTH
    getInputs("input[data-minlength]").forEach((input) => {
      const minLength = input.getAttribute("data-minlength");
      if (input.value.length < minLength && input.value !== "") {
        dispatchCuEvent(t(TEXT_MIN_LENGTH) + minLength, input);
      }
    });

    // CHECK_TEXT_EMPTY
    getInputs("input[data-required]").forEach((input) => {
      if (input.value === "") dispatchCuEvent(t(TEXT_EMPTY), input);
    });

    if (STATUS.isSubmit) onSubmit(e, inputFields);
  };

  return (
    <form className={className} onSubmit={onFormSubmit}>
      {children}
    </form>
  );
}
