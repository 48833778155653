import { useTimeout } from "usehooks-ts";
import useNavigateWithSearch from "./useNavigateWitchSearch";

export default function useCheckEmptyPage({ isEmpry }) {
  const navigate = useNavigateWithSearch();

  useTimeout(() => {
    if (!isEmpry) navigate("/");
  }, 400);

  return {};
}
