import { useEffect } from "react";

export default function useFixCaruselDesk({ sliderRef, children }) {
  useEffect(() => {
    const handleClick = (event) => {
      const clonedItem = event.target.closest(".owl-item.cloned .carousel-item");
      if (clonedItem) {
        let tempTarget = event.target;
        while (tempTarget) {
          const clickid = tempTarget.dataset.clickid;
          if (clickid) {
            const event = new CustomEvent(clickid, { detail: { elem: tempTarget } });

            return window.dispatchEvent(event);
          }
          tempTarget = tempTarget.parentElement;
        }

        const originalIndex = clonedItem.getAttribute("data-index");
        if (originalIndex !== null) {
          const originalItem = document.querySelector(
            `.owl-item:not(.cloned) .carousel-item[data-index="${originalIndex}"]`
          );
          if (originalItem) {
            console.log(`Clicking original item with index: ${originalIndex}`);
            originalItem.childNodes[0].click();
          } else {
            console.log(`Original item with index ${originalIndex} not found`);
          }
        } else {
          console.log("No data-index attribute found on cloned item");
        }
      } else {
        console.log("Clicked item is not a cloned item");
      }
    };

    const owlContainer = sliderRef.current?.container;
    if (owlContainer) {
      owlContainer.addEventListener("click", handleClick);

      return () => {
        owlContainer.removeEventListener("click", handleClick);
      };
    }
  }, [children]);
}
