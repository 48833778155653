import React, { memo } from "react";

const IconPresent = memo(function IconPresent() {
  return (
    <svg
      width="29.000000"
      height="29.000000"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip128_59">
          <rect
            id="free-icon-shopping-2363368 1"
            width="29.000000"
            height="29.000000"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <rect
        id="free-icon-shopping-2363368 1"
        width="29.000000"
        height="29.000000"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <g clipPath="url(#clip128_59)">
        <path
          id="Vector"
          d="M2.26 28.09L2.26 10.42L12.61 10.42L16.38 10.42L26.73 10.42L26.73 28.09L2.26 28.09Z"
          fill="#4E1F6F"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M14.5 28.09L14.5 10.42L19.67 10.42L21.55 10.42L26.73 10.42L26.73 28.09L14.5 28.09Z"
          fill="#602F84"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M16.76 12.23L16.76 10.42L20.98 10.42L22.51 10.42L26.73 10.42L26.73 12.23L16.76 12.23Z"
          fill="#282132"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M2.26 12.23L2.26 10.42L6.48 10.42L8.01 10.42L12.23 10.42L12.23 12.23L2.26 12.23Z"
          fill="#602F84"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M0.9 5.89L28.09 5.89L28.09 10.42L0.9 10.42L0.9 5.89Z"
          fill="#4E1F6F"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M14.5 5.89L28.09 5.89L28.09 10.42L14.5 10.42L14.5 5.89Z"
          fill="#602F84"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M11.32 5.89L17.67 5.89L17.67 10.42L11.32 10.42L11.32 5.89Z"
          fill="#FFBD38"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M14.5 5.89L17.67 5.89L17.67 10.42L14.5 10.42L14.5 5.89Z"
          fill="#FFDC97"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M13.53 3.17L15.46 3.17C15.69 3.17 15.9 3.26 16.06 3.41C16.22 3.57 16.31 3.79 16.31 4.01L16.31 5.89L12.68 5.89L12.68 4.01C12.68 3.79 12.77 3.57 12.93 3.41C13.09 3.26 13.3 3.17 13.53 3.17Z"
          fill="#4E1F6F"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M16.31 4.01L16.31 5.89L14.5 5.89L14.5 3.17L15.46 3.17C15.58 3.17 15.69 3.19 15.79 3.23C15.89 3.27 15.98 3.33 16.06 3.41C16.14 3.49 16.2 3.58 16.24 3.69C16.29 3.79 16.31 3.9 16.31 4.01Z"
          fill="#602F84"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M3.62 5.89L3.62 2.84C3.62 2.51 3.71 2.18 3.88 1.89C4.05 1.6 4.29 1.36 4.58 1.19C4.87 1.03 5.2 0.94 5.54 0.95C5.87 0.95 6.2 1.05 6.49 1.22L12.68 4.98L12.68 5.89L3.62 5.89Z"
          fill="#FFBD38"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M12.23 10.42L16.76 10.42L16.76 28.09L12.23 28.09L12.23 10.42Z"
          fill="#FFBD38"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M14.5 10.42L16.76 10.42L16.76 28.09L14.5 28.09L14.5 10.42Z"
          fill="#FFDC97"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M12.23 10.42L16.76 10.42L16.76 12.23L12.23 12.23L12.23 10.42Z"
          fill="#FFBD38"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M14.5 10.42L16.76 10.42L16.76 12.23L14.5 12.23L14.5 10.42Z"
          fill="#D6B97E"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="Vector"
          d="M16.31 5.89L16.31 4.98L22.5 1.22C22.79 1.05 23.12 0.95 23.45 0.95C23.79 0.94 24.12 1.03 24.41 1.19C24.7 1.36 24.94 1.6 25.11 1.89C25.28 2.18 25.37 2.51 25.37 2.84L25.37 5.89L16.31 5.89Z"
          fill="#FFBD38"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
});

export default IconPresent;
