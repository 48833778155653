import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useEventListener } from "usehooks-ts";
import IconEye from "../JSXIcons/IconEye";
import IconEyeHide from "../JSXIcons/IconEyeHide";
import IconCopy from "../JSXIcons/IconCopy";
import { successToast } from "../Toaster";
import "./custom-input.scss";
import ShowContainer from "../ShowContainer";

export default function CusInput({
  label,
  placeholder,
  type,
  icon,
  grayWarning,
  minLength,
  dataType,
  required,
  name,
  defaultValue,
  iconJSX,
  iconJSXstart,
  onChange,
  errorWarning,
  readOnly,
  isCopy,
  plugText,
  onKeyDown,
  onKeyUp,
  min,
  max
}) {
  const { t } = useTranslation();
  const [showPas, setShowPas] = useState(true);
  const [error, setError] = useState("");
  const typeInput = type === "password" && showPas ? "text" : type;
  const inputRef = useRef();

  useEventListener("inputError", (e) => {
    const detail = e.detail;
    if (detail.target === inputRef.current) setError(detail.message);
  });

  useEffect(() => {
    setError(errorWarning);
  }, [errorWarning]);

  const isCopyClass = isCopy ? "is-copy" : "";

  return (
    <div
      className={`custom-input  ${error ? "error-container" : ""} ${iconJSXstart ? "icon-JSX-start" : ""}`}>
      <ShowContainer condition={label}>
        <span className="custom-input__title">
          {t(label)}
          {required && <span className="required"> *</span>}
        </span>
      </ShowContainer>
      <div
        className={`custom-input__container ${error ? "error" : ""} ${
          plugText ? "plug-text-" + plugText.length : ""
        } `}>
        {!!icon && <img src={icon} alt="" />}
        <div className="icon-JSX-start__div">{!!iconJSXstart && iconJSXstart}</div>
        <span className="plug-text">{plugText}</span>
        <input
          defaultValue={defaultValue}
          ref={inputRef}
          readOnly={readOnly}
          onChange={(e) => {
            setError("");
            onChange?.(e);
          }}
          data-required={required}
          data-minlength={minLength}
          data-min={min}
          data-max={max}
          data-type={dataType}
          type={typeInput}
          name={name}
          placeholder={t(placeholder || label)}
          className={`custom-input__input ${isCopyClass} ${type || ""} ${iconJSX ? "icon" : ""}`}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        <ShowContainer condition={iconJSX}>
          <button type="button" className="password__hide__btn">
            {iconJSX}
          </button>
        </ShowContainer>

        <ShowContainer condition={type === "password"}>
          <button
            type="button"
            className="password__hide__btn"
            onClick={(e) => {
              e.preventDefault();
              setShowPas((prev) => !prev);
            }}>
            {showPas ? <IconEye /> : <IconEyeHide />}
          </button>
        </ShowContainer>

        <ShowContainer condition={isCopy}>
          <button
            type="button"
            className="password__hide__btn"
            onClick={() => {
              navigator.clipboard.writeText(defaultValue);
              successToast(t("successfully copied to clipboard"));
            }}>
            <IconCopy />
          </button>
        </ShowContainer>
      </div>
      {!!error && <span className="text-error">{t(error)}</span>}
      {grayWarning && <span className="custom-input__gray-warning">{t(grayWarning)}</span>}
    </div>
  );
}
