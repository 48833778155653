import React from "react";
import DefaultModal from "../../DefaultModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function WithdrawError({ isOpen, closeModal, message }) {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.user.currency);

  return (
    <DefaultModal closeModal={closeModal} isOpen={isOpen} classModal="withdraw-error">
      <h2>{t("Withdraw currency from balance", { currency })}</h2>
      <p className="withdraw-error__p">{t(message)}</p>
    </DefaultModal>
  );
}
