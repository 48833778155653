import { createSlice } from "@reduxjs/toolkit";
import { getParamFromUrl } from "../utils/utils";
import { checkUrlBotTg, defaultUser } from "./enums";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const userId = getParamFromUrl("userId") || localStorage.getItem("userId");

// - основная информация стора о сайте
const commonSlice = createSlice({
  name: "user",
  initialState: {
    userId: userId,
    isAuth: userId || checkUrlBotTg(),
    currency: "",
    info: userInfo || defaultUser,
    currencies: {},
    currencyInfo: {},
    balance: {
      main: 0,
      payout: 0,
      bonus: 0,
      depositWager: 0,
      bonusWager: 0
    }
  },
  reducers: {
    setInfo(state, newAction) {
      const info = { ...state.info, ...newAction.payload };
      localStorage.setItem("userInfo", JSON.stringify(info));
      state.info = info;
    },
    setBalance(state, newAction) {
      state.balance = newAction.payload;
    },
    setUserId(state, newAction) {
      const userId = newAction.payload;
      userId ? localStorage.setItem("userId", userId) : localStorage.removeItem("userId");
      state.userId = userId;
      state.isAuth = !!userId;
    },
    setCurrency(state, newAction) {
      const currency = newAction.payload;
      state.currency = currency;
      if (state.currencies && typeof state.currencies === "object") {
        console.log("currencyInfo change", state.currencies[currency]);
        state.currencyInfo = state.currencies[currency];
        if (state.currencyInfo) state.currencyInfo.code = currency;
      }
    },
    setCurrencies(state, newAction) {
      state.currencies = newAction.payload;
    }
  }
});

export default commonSlice.reducer;

export const { setInfo, setSessionId, setUserId, setCurrency, setBalance, setCurrencies } =
  commonSlice.actions;
