import React, { useState } from "react";
import CusInput from "../../../mui/custom_input/CusInput";

export default function CardFields() {
  const [cartNumber, setCartNumber] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  return (
    <>
      <CusInput
        label={"Card number"}
        name={"cardNumber"}
        defaultValue={cartNumber}
        type={"number"}
        onChange={(e) => setCartNumber(e.target.value)}
        required
      />

      <CusInput
        label={"Receiver name"}
        name={"firstName"}
        defaultValue={name}
        onChange={(e) => setName(e.target.name)}
        required
      />

      <CusInput
        label={"Receiver last name"}
        name={"lastName"}
        defaultValue={lastName}
        onChange={(e) => setLastName(e.target.name)}
        required
      />
    </>
  );
}
